import {  Navigate, Params, useLoaderData } from 'react-router-dom';
import { useLoginStore } from '../../context/LoginContext';
import { PatientView } from 'va_shared/src/schema/patient';
import PatientHomepage from './PatientHomepage';
import { isValidId } from 'va_shared/src/utilities/Validation';
import { auth_error } from '../../shared/api';
import { PatientFlagView } from 'va_shared/src/schema/patientFlag';
import { SchemeView } from 'va_shared/src/schema/scheme';
import { PatientStatusView } from 'va_shared/src/schema/patientStatus';
import { format } from 'date-fns/format';

export function PatientHomepageParent () {
  const context = useLoaderData() as {flags: PatientFlagView[], schemes: SchemeView[], statuses: PatientStatusView[], patient: PatientView | "new", error?: string, status: number};

  if ("error" in context) {
    console.error(context);
    return (<Navigate to="/patient/search"/>)
  }

  if (context.patient === "new") {
      return (
        <PatientHomepage patient={{
          _id: "",
          patient_id: "0",
          forename: "",
          faux_forename: "",
          surname: "",
          faux_surname: "",
          dob: Date.now(),
          status: context.statuses[0]?._id || "",
          schemes: [context.schemes[0]?._id || ""].filter((s) => s),
          sex: "other",
          gender: "",
          known_as: "",
          socials: [],
          addresses: [{ streetOne: "", streetTwo: "", city: "", postcode: "", is_primary: false, type: "Patient" }],
          emails: [{ email: "", is_active: true }],
          telephones: [{ number: "", is_active: true, is_landline: false }],
          flags: [],
          title: "",
          dob_string: "",
          formErrors: [],
          errors: [],
        }} editable={true} schemes={context.schemes} flags={context.flags} statuses={context.statuses} />
    );
  }  

  return (
    <PatientHomepage 
      key={context.patient._id} 
      patient={{
        formErrors:[],
        errors: [],
        ...context.patient,
        status: context.patient.status?._id || "",
        dob_string: format(new Date(context.patient.dob), "yyyy-MM-dd"),
        telephones: context.patient.telephones || [],
        schemes: context.patient.schemes.map(scheme => scheme._id),
        flags: context.patient.flags.map(flag => flag._id),
      }}
      editable={false}
      flags={context.flags}
      schemes={context.schemes}
      statuses={context.statuses}
    />
  );
}

export const PatientHomepageParentLoader = async ({params}: {params: Params<string>}) => {
  const async_authorised_request = useLoginStore.getState().async_authorised_request;
  if (!async_authorised_request) {
    return auth_error
  }

  return Promise.all([
      async_authorised_request("GET", "patient/flag"),
      async_authorised_request("GET", "scheme"),
      async_authorised_request("GET", "patient/status"),
      isValidId(params.id) ? async_authorised_request("GET", "patient/homepage/" + params.id) : undefined
  ]).then(([{flags}, {schemes}, {statuses}, p_res]) => 
      {return {
        status:200,
        flags,
        schemes,
        statuses,
        patient: p_res?.patient ? p_res.patient : "new"
      }}
  )
}