export interface IDropDown<A,B>{
    value: B;
    key: A;
}

export interface IDropDownProps<A,B> {
    options: IDropDown<A,B>[];
    setState: (newState: A) => void;
    getState: A;
    editable?: boolean;    
    className?: string;
}

export default function DropDown<A extends string | number,B extends string | number> ({options, setState, getState, editable, className}: IDropDownProps<A,B>) {
    getState = getState? getState : "" as A;
    return (
        <select disabled={!editable} className={"rounded-md text-black "+(!editable&&"font-bold") + " " + className} value={getState} onChange={e=> {e.preventDefault(); setState(e.target.value as A)} }>
                <option key={-1} value="" disabled> {editable?"Select":"(N/A)"} </option>
                {options.map((option) => {
                        return <option key={option.value.toString()} value={option.value.toString()}> {option.key} </option>
                })}
        </select>
    );
}
