
import { format } from 'date-fns';
import noDefault from '../../shared/noDefault';
import { TransactionAny, transactionTypesMap } from 'va_shared/src/schema/transaction';
import { PrettyPence } from 'va_shared/src/utilities/Currency';
import { useState } from 'react';

export interface IViewTransactionProps {
    transaction: TransactionAny,
    parent?: TransactionAny,
    delete_call?: () => void
}

export default function ViewTransaction ({transaction, parent, delete_call}: IViewTransactionProps) {
  const [confirm, setConfirm] = useState(false);

  return (
    <div onClick={noDefault(() => {})} className='relative border-2 border-stone-500 bg-stone-200 p-3 rounded-md max-w-md grid grid-flow-row space-y-1.5 shadow'>
        <h1 className='text-xl'> View {transactionTypesMap[transaction.type]} </h1>
        
        {!transaction.deleted_at && delete_call &&
            <div className='absolute top-1 right-4'>
                <button className='text-red-500 font-bold' onClick={noDefault(() => {
                  if (!delete_call) return;
                    if(confirm) {
                      delete_call();
                      return;
                    }
                    setConfirm(true);
                })}> {confirm?"Confirm Delete":"Delete"} </button>
            </div>
        }

        <div>
            <label className='font-semibold text-stone-500' htmlFor='id'>ID</label>
            <input readOnly className='block w-full bg-stone-50 rounded-sm px-1 py-0.5 font-semibold' value={transaction._id} />
        </div>

        <div>
            <label className='font-semibold text-stone-500' htmlFor='patient'>Date</label>
            <input readOnly className='block w-full bg-stone-50 rounded-sm px-1 py-0.5 font-semibold' value={format(transaction.timestamp, "dd/MM/yy HH:mm:ss.SSS")} />
        </div>

        <div>
            <label className='font-semibold text-stone-500' htmlFor='type'>Type</label>

            <div>
                <input readOnly className='mr-0.5 inline bg-stone-50 rounded-sm px-1 py-0.5 font-semibold' value={transactionTypesMap[transaction.type]} />
                {transaction.type === "Payment" && transaction.method && <input readOnly className='ml-0.5 inline bg-stone-50 rounded-sm px-1 py-0.5 font-semibold' value={transaction .method} />}
            </div>
        </div>
        
        <div>
            <label className='font-semibold text-stone-500' htmlFor='date'>Created</label>
            <div>
                <input readOnly className='mr-0.5 inline bg-stone-50 rounded-sm px-1 py-0.5 font-semibold' value={`${format(transaction.created_at, "dd/MM/yy HH:mm:ss")}`} />
                <input readOnly className='ml-0.5 inline bg-stone-50 rounded-sm px-1 py-0.5 font-semibold' value={transaction.created_by?`${transaction.created_by?.forename} ${transaction.created_by?.surname}`:"SYSTEM"} />
            </div>
        </div>

        {transaction.deleted_at && <div>
            <label className='font-semibold text-stone-500' htmlFor='deleted'>Deleted</label>
            <div>
                <input readOnly className='mr-0.5 inline bg-stone-50 rounded-sm px-1 py-0.5 font-semibold' value={`${format(transaction.deleted_at, "dd/MM/yy HH:mm:ss")}`} />
                <input readOnly className='ml-0.5 inline bg-stone-50 rounded-sm px-1 py-0.5 font-semibold' value={transaction.deleted_by?`${transaction.deleted_by?.forename} ${transaction.deleted_by?.surname}`:"SYSTEM"} />
            </div>
        </div>}
        
        <div>
            <label className='font-semibold text-stone-500' htmlFor='description'>Description</label>
            <textarea readOnly className='w-full bg-stone-50 rounded-sm px-1 py-0.5 font-semibold' value={transaction.description} />
        </div>

        <div>
        <label className='font-semibold text-stone-500' htmlFor='amount'>Amount</label>
            <input readOnly className='block w-full bg-stone-50 rounded-sm px-1 py-0.5 font-semibold' value={PrettyPence(transaction.amount)} />
        </div>

        {parent? <div>
            <label className='font-semibold text-stone-500'>{transactionTypesMap[parent.type]} History</label>
            <div className='bg-stone-50 rounded-sm p-0.5 relative'>
              <table className='w-full text-center table-fixed' title={parent.description}>
                <tbody className=''>
                  <tr className='border-b border-stone-400 font-bold'>
                    <td> {transactionTypesMap[parent.type]} {parent.method?`(${parent.method})`:""} </td>
                    <td> {format(parent.created_at, "dd/MM/yy HH:mm")} </td>
                    <td> {PrettyPence(parent.amount)} </td>
                  </tr>
                  {parent.modifiers && parent.modifiers.map(tran => <tr className={tran._id === transaction._id?"font-semibold bg-stone-200":""} title={tran.description}>
                    <td> {transactionTypesMap[tran.type]} {parent.method?`(${parent.method})`:""} </td>
                    <td> {format(tran.created_at, "dd/MM/yy HH:mm")} </td>
                    <td> {PrettyPence(tran.amount)} </td>
                  </tr>)}
                  <tr className='border-t border-stone-200 font-semibold'>
                    <td> Total: </td>
                    <td> </td>
                    <td> {PrettyPence(Math.abs(parent.modifiers.reduce((t, action) => t + action.amount*action.sign, parent.amount*parent.sign)))} </td>
                  </tr>
                </tbody>
              </table>
              <span title='Hover for more Information' className='absolute -top-0.5 right-1'>*</span>
            </div>
          </div>:
            <div>
                <label className='font-semibold text-stone-500'>{transaction.type} History</label>
                <div className='bg-stone-50 rounded-sm p-0.5 relative'>
                <table className='w-full text-center table-fixed' title={transaction.description}>
                    <tbody className=''>
                    <tr className='border-b border-stone-400 font-bold'>
                        <td> {transaction.type} {transaction.method?`(${transaction.method})`:""} </td>
                        <td> {format(transaction.created_at, "dd/MM/yy HH:mm")} </td>
                        <td> {PrettyPence(transaction.amount)} </td>
                    </tr>
                    {transaction.modifiers && transaction.modifiers.map(tran => <tr className={tran._id === transaction._id?"font-semibold bg-stone-200":""} title={tran.description}>
                        <td> {tran.type} {transaction.method?`(${transaction.method})`:""} </td>
                        <td> {format(tran.created_at, "dd/MM/yy HH:mm")} </td>
                        <td> {PrettyPence(tran.amount)} </td>
                    </tr>)}
                    <tr className='border-t border-stone-200 font-semibold'>
                        <td> Total: </td>
                        <td> </td>
                        <td> {PrettyPence(transaction.modifiers?Math.abs(transaction.modifiers.reduce((t, action) => t + action.amount*action.sign, transaction.amount*transaction.sign)):transaction.amount)} </td>
                    </tr>
                    </tbody>
                </table>
                <span title='Hover for more Information' className='absolute -top-0.5 right-1'>*</span>
                </div>
            </div>
        }


    </div>
  );
}
