import { addMinutes } from "date-fns/addMinutes";
import { format } from "date-fns/format";
import { useState } from "react";
import noDefault from "../shared/noDefault";
import { TimeInput } from "../shared/Date";
import { subMinutes } from "date-fns/subMinutes";
import { EventSessionDay, EventSessionDayView } from "va_shared/src/schema/eventSessionDay";
import { EventBookView } from "va_shared/src/schema/eventBook";
import { EventSessionView } from "va_shared/src/schema/eventSession";
import { EventSessionTypeView } from "va_shared/src/schema/eventSessionType";

export interface IDayEditProps {
    date: Date;
    onSave: (session_day:Partial<EventSessionDayView> | null, repeat:{cycle:number, override:boolean, skip:number} | null) => void;
    book: EventBookView;
    session_day: Partial<EventSessionDayView>
    sessionTypes: EventSessionTypeView[]
    errors? : string[]
}

export function DayEdit({book, sessionTypes, session_day, date, onSave, errors}: IDayEditProps) {
    const upper = new Date(date);
    upper.setHours(23,0,0,0);
    const lower = new Date(upper);
    lower.setHours(1,0,0,0);
    const [sessions, setSessions] = useState<EventSessionView[]>(session_day.sessions || []);
    const [form, setForm] = useState({
        errors: errors || [] as string[],
        index: -1,
        start_at: addMinutes(lower,7*60),
        end_at: addMinutes(lower, 15*30),
        type: sessionTypes.length === 1 ? sessionTypes[0] : null as EventSessionDay | null,
    });
    const [repeat, setRepeat] = useState<{cycle:number, override:boolean, skip:number} | null>(null);

    const CreateSession = () => {
        const errors: string[] = [];

        if (!form.start_at) {
            errors.push("Start time is required");
        }

        if (!form.end_at) {
            errors.push("End time is required");
        }

        if (!form.type || !form.type._id) {
            errors.push("Session Type is required");
        }

        if (form.start_at >= form.end_at) {
            errors.push("End time must be after start time");
        }

        if (errors.length > 0) {
            setForm({
                ...form,
                errors
            });
            return
        }

        const newSession: EventSessionView = {
            end_at: form.end_at.getTime(),
            start_at: form.start_at.getTime(),
            type: form.type as unknown as EventSessionTypeView
        }

        setForm({
            errors: [],
            index: -1,
            start_at: form.end_at,
            end_at: addMinutes(form.end_at,30),
            type: null,
        })

        setSessions([...sessions.filter((session, i) => 
            (session.end_at <= newSession.start_at || session.start_at >= newSession.end_at) &&
            (i !== form.index)     
        ), newSession]);
    }

    return (
        <div className="bg-stone-300 rounded-sm p-2 w-72 border-2 border-stone-500" onClick={noDefault(()=>{})}>

            <h1 className="text-2xl w-full text-center m-1">{book.name} - {format(date, "dd/MM/yy")}</h1>

            <div className="space-y-2">
                <form className="space-y-1 flex flex-col center w-full">

                    {form.errors.length > 0 && <div className="p-1">
                        {form.errors.map((error, i) => {
                            return <p key={error.length * i * 19} className="text-lg text-center font-semibold text-red-600">{error}</p>
                        })}
                    </div>}

                    <div className="flex flex-row">
                        <select className="bg-stone-100 text-lg p-1 rounded-sm font-semibold" value={(form.type?form.type._id:"0").toString()} onChange={(e) => {setForm({...form, type:sessionTypes.find((type) => type._id.toString() === e.target.value) || null as any})}}>
                            <option hidden={true} value={"0"}>Session Type</option>
                            {
                                sessionTypes.map((type) => {
                                    return (
                                        <option key={type._id.toString()} value={type._id.toString()}>{type.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="flex flex-row justify-items-stretch space-x-8">
                        <div className="flex flex-col">
                            <label className="text-lg">Start Time</label>
                            <TimeInput value={form.start_at} min={lower} max={upper}  onChange={(newTime) => {
                                setForm({
                                    ...form, 
                                    start_at:newTime,
                                    end_at: newTime>=form.end_at?addMinutes(newTime,1):form.end_at
                                })
                                }} className="mx-auto bg-stone-100 text-xl font-semibold p-1 rounded-sm" />
                        </div>
                        <div className="flex flex-col">
                            <label className="text-lg">End Time</label>
                            <TimeInput value={form.end_at} min={lower} max={upper} onChange={(newTime) => setForm({
                                ...form, 
                                end_at:newTime,
                                start_at: newTime<=form.start_at?subMinutes(newTime,1):form.start_at
                            })} className="mx-auto bg-stone-100 text-xl font-semibold p-1 rounded-sm" />
                        </div>
                    </div>
                </form>

                <div className="w-full flex pt-1">
                    {
                        form.index === -1 ? 
                            <button className=" btn-accent w-3/5 text-xl py-1 mx-auto rounded-sm" onClick={noDefault(CreateSession)}>Create</button>
                        :
                            <div className=" w-full flex space-x-1">
                                <button className="btn-accent w-full text-xl py-1 rounded-sm" onClick={noDefault(CreateSession)}>Save</button>
                                <button className="btn-danger w-full text-xl py-1 rounded-sm" onClick={noDefault(() => {
                                    setSessions(sessions.filter((_, i) => i !== form.index))
                                    setForm({
                                        errors: [],
                                        index: -1,
                                        start_at: form.start_at,
                                        end_at: form.end_at,
                                        type: null,
                                    })
                                })}>Delete</button>
                            </div>
                    }
                </div>

                <div className="relative">
                    <div className="flex flex-col min-h-56 max-h-96 overflow-y-scroll w-full relative space-y-0.5">
                        {
                            sessions.sort((a,b)=> a.start_at-b.start_at).map((session, index) => {
                                return (
                                    <div key={index * session.start_at * session.end_at} onClick={noDefault(() => {
                                        if (index === form.index) {
                                            setForm({
                                                errors: [],
                                                index: -1,
                                                start_at: new Date(),
                                                end_at: addMinutes(new Date(),30),
                                                type: null,
                                            })
                                            return
                                        }
                                        setForm({
                                            errors: [],
                                            index,
                                            start_at: new Date(session.start_at),
                                            end_at: new Date(session.end_at),
                                            type: session.type as any,
                                        })
                                    })} style={{backgroundColor:`#${session.type.colour}`}} className={"flex flex-col rounded border mr-1 p-1 cursor-pointer" + (index === form.index?" border-stone-100 border-4":" border-stone-500 border-2")}>
                                        <p className="text-lg font-semibold ">
                                            {format(new Date(session.start_at), "HH:mm")} - {format(new Date(session.end_at), "HH:mm")}
                                        </p>
                                        <p className="italic">
                                            {session.type?.name}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="bg-stone-200 p-1 rounded-lg">
                    <div className="flex flex-row space-x-3">
                        <label className="text-lg font-semibold">Repeat: </label>
                        <input key={(!!repeat).toString()} type="checkbox"  className="scale-125" checked={repeat !== null} onChange={e => {
                            setRepeat(e.target.checked?{cycle:1, override:false, skip:0}:null)
                        }}></input>
                    </div>
                    {!!repeat && <div className="grid grid-cols-2 gap-0.5">
                        <label className="text-lg">Skip: </label>
                        <input className="rounded py-0.5 px-1" type="number" value={repeat.skip || 0} onChange={e => setRepeat({...repeat, skip:parseInt(e.target.value)})}></input>
                        <label className="text-lg">Cycle: </label>
                        <input className="rounded py-0.5 px-1" type="number" value={repeat.cycle || 0} onChange={e => setRepeat({...repeat, cycle:parseInt(e.target.value)})}></input>
                        <label className="text-lg">Override: </label>
                        <input key={repeat.override.toString()} type="checkbox" checked={repeat.override} onChange={e => setRepeat({...repeat, override:e.target.checked})}></input>
                    </div>}
                </div>

                <div className="flex flex-row space-x-1">
                    <button className="btn-accent w-full text-xl py-1 rounded-sm" onClick={noDefault(() => onSave({ ...session_day, sessions, book:book._id }, repeat))}>Save</button>
                    <button className="btn-primary w-full text-xl py-1 rounded-sm" onClick={noDefault(() => onSave(null,null))}>Cancel</button>
                </div>

            </div>
        </div>
    );
}