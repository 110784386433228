import { z } from "zod";
import { docSchema, docViewSchema, zOID, zViewOID, zViewOIDGenerator } from "./document/document";
import { stockLocationGroupViewSchema } from "./stockLocationGroup";

export const stockLocationSchema = z.object({
    name: z.string().trim().min(1, "Name is Required").max(64, "Name is too long"),
    description: z.string().trim().max(256, "Description is too long"),
    group: zOID,
    is_active: z.boolean().default(true)
}).merge(docSchema)
export type StockLocation = z.infer<typeof stockLocationSchema>;

export const stockLocationViewSchema = stockLocationSchema.merge(z.object({
    group: stockLocationGroupViewSchema,
})).merge(docViewSchema)
export type StockLocationView = z.infer<typeof stockLocationViewSchema>;

export const stockLocationFormSchema = stockLocationSchema
.merge(z.object({
    _id: zViewOID.optional(),
    group: zViewOIDGenerator("Group is Required"),
}))
export type StockLocationForm = z.infer<typeof stockLocationFormSchema>;
