import mongoose from "mongoose";
import { Doc, deletable, creatable, creatableViewPopulate, deletableViewPopulate } from "./shared/document";
import { Transaction, TransactionPayment, TransactionRefund, TransactionWriteOff, TransactionCredit, TransactionCharge, TransactionDiscount, transactionTypes, TransactionContacts, TransactionSpectacles, TransactionStock, transactionMethods } from "../schema/transaction";

export type TransactionDoc = Doc<Transaction>;
export type TransactionPaymentDoc = Doc<TransactionPayment>;
export type TransactionRefundDoc = Doc<TransactionRefund>;
export type TransactionWriteOffDoc = Doc<TransactionWriteOff>;
export type TransactionCreditDoc = Doc<TransactionCredit>;
export type TransactionChargeDoc = Doc<TransactionCharge>;
export type TransactionDiscountDoc = Doc<TransactionDiscount>;
export type TransactionStockDoc = Doc<TransactionStock>;
export type TransactionSpectaclesDoc = Doc<TransactionSpectacles>;
export type TransactionContactsDoc = Doc<TransactionContacts>;

const transactionSchema = new mongoose.Schema<Transaction>({
    patient: { type: mongoose.Schema.Types.ObjectId, ref:"Patient", required: true, index: true },
    type: { type: String, required: true, enum: transactionTypes },
    amount: { type: Number, required: true },
    description: { type: String },
    timestamp: { type: Number, required: true  },

    ...creatable,
    ...deletable
},{ discriminatorKey: "type", toJSON: { virtuals: true }},
);

transactionSchema.discriminator("Payment", new mongoose.Schema<TransactionPayment>({
    method: { type: String, required: true, enum: transactionMethods},
}));

transactionSchema.discriminator("Refund", new mongoose.Schema<TransactionRefund>({
    method: { type: String, required: true, enum: transactionMethods},
    transaction: { type: mongoose.Schema.Types.ObjectId, ref:"Transaction", required: true },
}));

transactionSchema.discriminator("Write-Off", new mongoose.Schema<TransactionWriteOff>({
    transaction: { type: mongoose.Schema.Types.ObjectId, ref:"Transaction", required: true },
}));

transactionSchema.discriminator("Credit", new mongoose.Schema<TransactionCredit>({
}));

transactionSchema.discriminator("Charge", new mongoose.Schema<TransactionCharge>({
}));

transactionSchema.discriminator("Discount", new mongoose.Schema<TransactionDiscount>({
    transaction: { type: mongoose.Schema.Types.ObjectId, ref:"Transaction", required: true },
}));

transactionSchema.discriminator("TransactionStock", new mongoose.Schema<TransactionStock>({
    stockPrice: {type:mongoose.Schema.Types.ObjectId, ref:"StockPrice", required: true},
    stock: { type: mongoose.Schema.Types.ObjectId, ref:"Stock", required: true },
    quantity: { type: Number, required: true },
}));

transactionSchema.discriminator("TransactionSpectacles", new mongoose.Schema<TransactionSpectacles>({
    spectacles: { type: mongoose.Schema.Types.ObjectId, ref:"Spectacle", required: true },
}));

transactionSchema.discriminator("TransactionContacts", new mongoose.Schema<TransactionContacts>({
    stockPrice: {type:mongoose.Schema.Types.ObjectId, ref:"StockPrice", required: true},
    contacts: { type: mongoose.Schema.Types.ObjectId, ref:"Contacts", required: true },
    left_power: { type: Number },
    right_power: { type: Number },
    
    left_base_curve: { type: Number },
    right_base_curve: { type: Number },

    left_diameter: { type: Number },
    right_diameter: { type: Number },

    quantity: { type: Number, required: true },
}));

transactionSchema.virtual("modifiers", {
    ref: "Transaction",
    localField: "_id",
    foreignField: "transaction",
    match: { deleted_at: null },
})

export const ChargeTypes = ["Refund", "Charge", "TransactionSpectacles", "TransactionContacts", "TransactionStock"]

export const GetTypeSign = (type: "Refund" | "Charge" | "Payment" | "Write-Off" | "Credit" | "Discount" | "TransactionSpectacles" | "TransactionContacts" | "TransactionStock") => {
    return ChargeTypes.includes(type) ? -1 : 1;
}

export const GetTransactionSign = (transaction: Transaction) => {
    return GetTypeSign(transaction.type);
}

transactionSchema.virtual("sign").get(function(this: Transaction) {
    return GetTransactionSign(this)
});

export const TransactionModel = mongoose.model<Transaction>("Transaction", transactionSchema, "Transaction");
export const TransactionViewPopulate = ["patient", "modifiers", ...creatableViewPopulate, ...deletableViewPopulate];