import { useLoaderData } from "react-router-dom";
import { useLoginStore } from "../../../context/LoginContext";
import { auth_error } from "../../../shared/api";
import { ContactsPeriods, StockForm, stockFormSchema, StockView } from "va_shared/src/schema/stock";
import { StockTagView } from "va_shared/src/schema/stockTag";
import { StockFrameManufacturerView } from "va_shared/src/schema/stockFrameManufacturer";
import { StockLocationView } from "va_shared/src/schema/stockLocation";
import { useState } from "react";
import { SchemaIssue } from "va_shared/src/schema/error";
import { LoadingSpinner } from "../../../shared/Loading";
import { boolStr } from "../../../shared/className";
import { hasError } from "../../../shared/Validation";
import { StockContactsBrandView } from "va_shared/src/schema/stockContactsBrand";

const DEFAULT_ITEM: Partial<StockForm> & {errors:string[], formErrors:SchemaIssue[]} = {sku:null, _id:null, tags:[], name:"", description:"", location:null, type:"Stock", errors:[], formErrors:[], manufacturer:null, size:null, colour:"", is_nhs:false, brand:null, is_trial:false, is_toric:false, is_multifocal:false, is_coloured:false, period:null}

export function AdminStockStock(){
    const async_authorised_request = useLoginStore(state => state.async_authorised_request)
    const context = useLoaderData() as {items: StockView[], tags: StockTagView[], manufacturers: StockFrameManufacturerView[], locations: StockLocationView[], groups: StockLocationView[], brands: StockContactsBrandView[]}

    const [items, setItems] = useState(context.items)
    const [itemForm, setItemForm] = useState(DEFAULT_ITEM)
    const [request, setRequest] = useState(false)

    const formSubmit = (e:React.FormEvent) => {
        e.preventDefault()
        
        itemForm._id = itemForm._id || null
        itemForm.sku = (itemForm.sku === "") ? null : itemForm.sku
        const {error, data} = stockFormSchema.safeParse(itemForm)

        if (error){
            console.log(error)
            setItemForm({...itemForm, formErrors:error.errors})
            return
        }

        setRequest(true)
        if (itemForm._id){
            async_authorised_request("PUT", "stock/", data).then((res) => {
                setItems(items.map((item) => item._id === res.stock._id ? res.stock : item))
                setItemForm({_id:null, tags:[], name:"", description:"", location:null, type:"Stock", errors:[], formErrors:[]})
            }).catch((e) => {
                console.error(e)
                setItemForm({...itemForm, errors:[e.error], formErrors:[]})
            }).finally(() => setRequest(false))
        }else{
            async_authorised_request("POST", "stock/", data).then((res) => {
                setItems([...items, res.stock])
                setItemForm({_id:null, tags:[], name:"", description:"", location:null, type:"Stock", errors:[], formErrors:[]})
            }).catch((e) => {
                console.error(e)
                setItemForm({...itemForm, errors:[e.error], formErrors:[]})
            }).finally(() => setRequest(false))
        }
    }

    if (request) return <LoadingSpinner/>

    const formClass = (field:string) => {
        return "px-1 w-1/2 " + boolStr("bg-white", "bg-red-300", hasError(field, itemForm.formErrors))
    }

    return (
        <div className="m-4">
            <h1 className="text-3xl">Stock Items</h1>
            <div className="bg-stone-200 rounded-lg p-4 space-y-4 relative">
                <form className="p-2 mx-auto w-full md:w-1/2 rounded-md space-y-1 border-4 border-stone-400 shadow-md text-lg" onSubmit={formSubmit}>
                    <h2 className="text-2xl text-center">{ itemForm._id ? "Edit" : "Create"} Item</h2>
                    <div>
                        {itemForm.errors.map((error) => (
                            <div key={error} className="bg-red-300 text-red-800 p-1 text-center rounded-sm">{error}</div>
                        ))}
                        {itemForm.formErrors.map((error) => (
                            <div key={(error.code+error.path.join("."))} className="bg-red-300 text-red-800 p-1 text-center rounded-sm">{error.message}</div>
                        ))}
                    </div>

                    <div className="flex flex-row justify-between border border-stone-400 m-1 p-2">
                        <select disabled={!!itemForm._id} className="px-1 w-full text-xl bg-white" id="type" value={itemForm.type} onChange={(e) => setItemForm({...itemForm, type:e.target.value as any})}>
                            <option value="Stock">Item</option>
                            <option value="StockFrame">Frame</option>
                            <option value="StockContacts">Contacts</option>
                        </select>
                    </div>
                    
                    <hr className="pt-1"/>

                    <div className="flex flex-row justify-between">
                        <label className="block" htmlFor="sku">SKU</label>
                        <input className={formClass("sku")} type="text" id="sku" value={itemForm.sku || ""} onChange={(e) => setItemForm({...itemForm, sku:e.target.value})}/>
                    </div>
                    <div className="flex flex-row justify-between">
                        <label className="block" htmlFor="name">Name</label>
                        <input className={formClass("name")} type="text" id="name" value={itemForm.name} onChange={(e) => setItemForm({...itemForm, name:e.target.value})}/>
                    </div>
                    <div className="flex flex-row justify-between">
                        <label className="block" htmlFor="description">Description</label>
                        <textarea className={formClass("description")} id="description" value={itemForm.description} onChange={(e) => setItemForm({...itemForm, description:e.target.value})}/>
                    </div> 

                    <div className="flex flex-row justify-between">
                        <label htmlFor="tags">Tags</label>
                        <select className={formClass("tags")} id="tags" multiple value={itemForm.tags} onChange={(e) => setItemForm({...itemForm, tags:[...e.target.selectedOptions as any].map(o => o.value).filter(t => !!t)})}>
                            {context.tags.map((tag) => (
                                <option key={tag._id} value={tag._id}>{tag.name}</option>
                            ))}
                        </select>
                    </div>

                    <div className="flex flex-row justify-between">
                        <label className="block" htmlFor="location">Location</label>
                        <select className={formClass("location")} id="location" value={itemForm.location || ""} onChange={(e) => setItemForm({...itemForm, location:e.target.value})}>
                            <option value="">N/A</option>
                            {context.locations.filter(l => l.is_active).map((location) => (
                                <option key={location._id} value={location._id}>{location.name}</option>
                            ))}
                        </select>
                    </div>
                    
                    {itemForm.type === "StockFrame" && (
                        <>
                            <div>
                                <div className="flex flex-row justify-between">
                                    <label className="block" htmlFor="manufacturer">Manufacturer</label>
                                    <select className={formClass("manufacturer")} id="manufacturer" value={itemForm.manufacturer || ""} onChange={(e) => setItemForm({...itemForm, manufacturer:e.target.value})}>
                                        <option value="" hidden>Select a Manufacturer</option>
                                        {context.manufacturers.map((manufacturer) => (
                                            <option key={manufacturer._id} value={manufacturer._id}>{manufacturer.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="flex flex-row justify-between">
                                <label className="block" htmlFor="size">Size</label>
                                <input className={formClass("size")} type="number" id="size" value={itemForm.size || ""} onChange={(e) => setItemForm({...itemForm, size:parseInt(e.target.value)})}/>
                            </div>
                            <div className="flex flex-row justify-between">
                                <label className="block" htmlFor="colour">Colour</label>
                                <input className={formClass("colour")} type="text" id="colour" value={itemForm.colour || ""} onChange={(e) => setItemForm({...itemForm, colour:e.target.value})}/>
                            </div>
                            <div className="flex flex-row justify-between">
                                <label className="block" htmlFor="is_nhs">NHS</label>
                                <input className="px-1 w-1/2 accent-teal-800" type="checkbox" id="is_nhs" checked={!!itemForm.is_nhs} onChange={(e) => setItemForm({...itemForm, is_nhs:e.target.checked})}/>
                            </div>
                        </>
                    )}

                    {itemForm.type === "StockContacts" && (
                        <>
                            <div>
                                <div className="flex flex-row justify-between">
                                    <label className="block" htmlFor="brand">Brand</label>
                                    <select className={formClass("brand")} id="brand" value={itemForm.brand || ""} onChange={(e) => setItemForm({...itemForm, brand:e.target.value})}>
                                        <option value="" hidden>Select a Brand</option>
                                        {context.brands.map((brand) => (
                                            <option key={brand._id} value={brand._id}>{brand.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="flex flex-row justify-between">
                                <label className="block" htmlFor="period">Period</label>
                                <select className={formClass("period")} id="period" value={itemForm.period || ""} onChange={(e) => setItemForm({...itemForm, period:ContactsPeriods[+e.target.value]})}>
                                    <option value="" hidden>Select a Period</option>
                                    {ContactsPeriods.map((period,i) => (
                                        <option key={period} value={i}>{period}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex flex-row justify-between">
                                <label className="block" htmlFor="is_trial">Trial</label>
                                <input className="px-1 w-1/2 accent-teal-800" type="checkbox" id="is_trial" checked={!!itemForm.is_trial} onChange={(e) => setItemForm({...itemForm, is_trial:e.target.checked})}/>
                            </div>
                            <div className="flex flex-row justify-between">
                                <label className="block" htmlFor="is_toric">Toric</label>
                                <input className="px-1 w-1/2 accent-teal-800" type="checkbox" id="is_toric" checked={!!itemForm.is_toric} onChange={(e) => setItemForm({...itemForm, is_toric:e.target.checked})}/>
                            </div>
                            <div className="flex flex-row justify-between">
                                <label className="block" htmlFor="is_multifocal">Multifocal</label>
                                <input className="px-1 w-1/2 accent-teal-800" type="checkbox" id="is_multifocal" checked={!!itemForm.is_multifocal} onChange={(e) => setItemForm({...itemForm, is_multifocal:e.target.checked})}/>
                            </div>
                            <div className="flex flex-row justify-between">
                                <label className="block" htmlFor="is_coloured">Coloured</label>
                                <input className="px-1 w-1/2 accent-teal-800" type="checkbox" id="is_coloured" checked={!!itemForm.is_coloured}
                                onChange={(e) => setItemForm({...itemForm, is_coloured:e.target.checked})}/>
                            </div>
                        </>)
                    }

                    <button type="submit" className="btn-accent w-full text-xl py-0.5 rounded-sm mt-2">{itemForm._id ? "Save" : "Create"} Item</button>

                    {!!itemForm._id && (
                        <button className="btn-dark w-full text-xl py-0.5 rounded-sm" onClick={() => {setItemForm(DEFAULT_ITEM)}}>Cancel</button>
                    )}

                </form>
            
            <div>
                <table className="w-full">
                    <thead className="bg-stone-500 text-lg">
                        <tr>
                            <th>SKU</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Location</th>
                            <th>Type</th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {items.sort((a,b)=>a.name>b.name?1:-1).map((item) => (
                            <tr key={item._id} className="bg-stone-300 hover:bg-stone-400 p-2 text-center hover:cursor-pointer" onClick={() => {
                                setItemForm({
                                    ...item,
                                    tags: item.tags && item.tags.map(tag => tag._id) || [],
                                    location: item.location ? item.location._id : null,
                                    brand: item.brand ? item.brand.toString() : null,
                                    manufacturer: item.manufacturer ? item.manufacturer.toString() : null,
                                    errors: [],
                                    formErrors: [],
                                })
                            }}>
                                <td>{item.sku}</td>
                                <td>{item.name}</td>
                                <td>{item.description ? item.description : <i className="text-stone-400 font-semibold">No Description</i>}</td>
                                <td>{item.location ? item.location.name : <i className="text-stone-400 font-semibold">No Location</i>}</td>
                                <td>{{Stock:"Item", StockFrame:"Frame", StockContacts:"Contacts"}[item.type]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    )
}

export function AdminStockStockLoader(){
    const async_authorised_request = useLoginStore.getState().async_authorised_request
    if (!async_authorised_request) return auth_error

    return Promise.all([
        async_authorised_request("GET", "stock/"),
        async_authorised_request("GET", "stock/tag"),
        async_authorised_request("GET", "stock/frameManufacturer"),
        async_authorised_request("GET", "stock/location"),
        async_authorised_request("GET", "stock/contactsBrand")
    ]).then((res) => {
        return {
            items: res[0].stocks,
            tags: res[1].tags,
            manufacturers: res[2].manufacturers,
            locations: res[3].locations,
            groups: res[3].groups,
            brands: res[4].brands
        }
    })

}