import { Link, useNavigate } from 'react-router-dom';
import SocialField from './SocialField';
import DropDown from './DropDown';
import CheckBox from './CheckBox';
import TelephoneField from './Telephone';
import { differenceInMonths, differenceInYears, format } from 'date-fns';
import { NavbarLink, useNavbarStore } from '../../context/NavbarContext';
import { useEffect, useState } from 'react';
import noDefault from '../../shared/noDefault';
import { GetPatientNav } from './patientNav';
import { EmailField } from './EmailField';
import { SchemeView } from 'va_shared/src/schema/scheme';
import { PatientFlagView } from 'va_shared/src/schema/patientFlag';
import { PatientStatusView } from 'va_shared/src/schema/patientStatus';
import { PrettyPence } from 'va_shared/src/utilities/Currency';
import { PatientForm, patientFormSchema } from 'va_shared/src/schema/patient';
import { SchemaIssue } from 'va_shared/src/schema/error';
import { LoadingSpinner } from '../../shared/Loading';
import { useLoginStore } from '../../context/LoginContext';
import { hasError } from '../../shared/Validation';
import { boolStr } from '../../shared/className';

export interface PatientHomePageProps {
    patient: PatientForm & {dob_string: string, formErrors:SchemaIssue[], errors: string[]},
    editable: boolean,
    
    schemes: SchemeView[],
    flags: PatientFlagView[],
    statuses: PatientStatusView[]
}

function getAge(timestamp: number){
  if (isNaN(timestamp) || timestamp > Date.now() || timestamp < -2180217600000) return "N/A";
  return differenceInYears(new Date(), timestamp) +" ("+ (differenceInMonths(new Date(), timestamp)-(differenceInYears(new Date(), timestamp))*12)+ " months)"
}

export default function PatientHomepage (props: PatientHomePageProps) {
  const async_authorised_request = useLoginStore(state => state.async_authorised_request);
  const [getPatient, setPatient] = useState(props.patient);
  const [editable, setEditable] = useState<boolean>(props.editable);
  const {setContent, add_patient_history} = useNavbarStore.getState();
  const [request, setRequest] = useState(false);

  const saveAble = getPatient.forename.trim().length > 1 && getPatient.surname.trim().length > 1 && getPatient.addresses && getPatient.addresses[0].city.trim().length > 1 && getPatient.addresses[0]?.postcode.trim().length > 1 && getPatient.dob && getPatient.status

  const navigate = useNavigate()

  useEffect(() => {
    if (getPatient && getPatient._id) {
    
      add_patient_history({id:getPatient._id, name: getPatient.forename + " " + getPatient.surname})

      const nav = GetPatientNav(getPatient._id, "Home")
      nav.push(NavbarLink({name: editable?"Cancel":"Edit", action: noDefault(() =>{ 
        setEditable(!editable);
        if (editable){
          setPatient({...getPatient, errors:[...getPatient.errors.filter(e => e!=="Changes will not be saved until you click save"), "Changes will not be saved until you click save"]} );
        }
      })}))

      // if (editable && saveAble){
      //   nav.push(NavbarLink({name: "Save", action: noDefault(()=>onSubmit({preventDefault: ()=>{}, stopPropagation: ()=>{}}as any))}))
      // }
      setContent(nav);
    }

    return () => {
      setContent([]);
    }
  }, [saveAble,editable, getPatient._id]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    getPatient.last_appointment = undefined
    getPatient.next_appointment = undefined   
    getPatient.formErrors = [];
    getPatient.errors = [];

    getPatient._id = getPatient._id || undefined;
    getPatient.known_as = getPatient.known_as || "";

    getPatient.emails = getPatient.emails.filter(e => e!==undefined && (e.email.trim().length> 0))
    getPatient.telephones = getPatient.telephones.filter(t => t!==undefined && t.number.trim().length)
    getPatient.socials = getPatient.socials.filter(s=> s!=undefined && s.platform.trim().length > 0 && s.social.trim().length)
    // getPatient.flags = getPatient.flags.filter(f => !!f).map(f => typeof f === "string" ? f : f._id)

    const {error, data} = patientFormSchema.safeParse(getPatient);
    if (error) {
      console.log(error)
      setPatient({...getPatient, formErrors:error.errors})
      return
    }

    setRequest(true);
    if (getPatient._id){
      async_authorised_request("PUT", "patient", data).then((res) => {
        const patient = res.patient as any;
        console.log(res)
        setPatient({
          ...getPatient,
          ...patient,
          flags: patient.flags.map((f: any) => f._id),
          schemes: patient.schemes.map((s: any) => s._id),
          status: patient.status._id,
          dob_string: format(new Date(patient.dob), "yyyy-MM-dd"),
          errors:[],
          formErrors:[],
        });
        setEditable(false);
      }).catch((e) => {
        console.log(e)
        setPatient({...getPatient, errors: [e.error || "An error occurred"]});
      }).finally(() => {
        setRequest(false);
      })
      return
    }

    async_authorised_request("POST", "patient", data).then((res) => {
      setPatient({...getPatient,...res.patient, dob_string: format(new Date(res.patient.dob), "yyyy-MM-dd"), errors:[]});
      setEditable(false);
      navigate(`/patient/${res.patient._id}`)
    }).catch((e) => {
      console.log(e)
      setPatient({...getPatient, errors: [e.error || "An error occurred"]});
    }).finally(() => {
      setRequest(false);
    })
  }

  if (request){
    return <LoadingSpinner/>
  }

  const className = (path: string): string => boolStr( "bg-white", "!bg-red-300 !text-black", hasError(path, getPatient.formErrors))
  
  return (
    <div className='white-card m-4 p-0.5 select-none '>
      <form onSubmit={onSubmit} className='patient-form p-2 grid sm:grid-cols-2  mx-auto lg:max-w-full lg:grid-cols-4'>
      
      
        <label >
          <h1 className='text-2xl'> {(editable && !getPatient._id)  ? "New Patient":"Patient Homepage"}</h1>            
          {
            getPatient.errors.length > 0 &&
            <div className='pb-4 w-full'>
              {getPatient.errors.map((e, i) => <p key={i} className='text-red-600 text-lg font-semibold text mx-auto text-center p-0 translate-y-2'>{e}</p>)}
            </div>
          }

          {
            getPatient.formErrors.length > 0 &&
            <div className='pb-4 w-full'>
              {getPatient.formErrors.map((e, i) => <p key={i} className='text-red-600 text-lg font-semibold text mx-auto text-center p-0 translate-y-2'>{e.message}</p>)}
            </div>
          }

        </label>
       
        <label htmlFor='ref'> ID  <br/>
          <input disabled={!editable} value={getPatient.patient_id} type='text' id='ref' onChange={(e) => setPatient({...getPatient, patient_id: e.target.value})}
          />
        </label>


        <label htmlFor='balance'> Balance <br/>
          <input disabled={true} type='text' id='balance' value={PrettyPence(getPatient.balance||0, true)} className={!!getPatient.balance ? (getPatient.balance < 0?"!bg-red-300": "!bg-emerald-300") : ""}/>
        </label>
        
        <label htmlFor='status'   >Status <br/>
          <DropDown className={className("status")} editable={editable} options={props.statuses.map(s=> {return {key:s.name, value:s._id}})} getState={getPatient.status} setState={(s) => setPatient({...getPatient, status:s})}/>
        </label>

        <label >
          <label htmlFor='forename'>Forename <br/>
          <input className={className("forename")}  disabled={!editable} type='text' id='forename' value={getPatient.forename} onChange={(e) => setPatient({...getPatient, forename: e.target.value})} />
          </label>
          <br/>
          <label htmlFor='surname'>Surname <br/>
            <input className={className("surname")}  disabled={!editable} type='text' id='surname' value={getPatient.surname} onChange={(e) => setPatient({...getPatient, surname: e.target.value})} />
          </label>
        </label>

        <label>
          <label htmlFor='sex'> Sex <br/>
            <DropDown className={className("sex")}  editable={editable} options={[{key:"Male", value:"male"}, {key:"Female", value:"female"}]} getState={getPatient.sex !== "other" ? getPatient.sex : ""} setState={
              s => setPatient({...getPatient, sex:s.toLowerCase() as "male" | "female", 
                gender: getPatient.gender === "" ? (s==="male"?"Male":"Female") : getPatient.gender,
                title: getPatient.title === "" ? (s==="male"?"Mr":"Mrs") : getPatient.title
              })}/>
          </label>
            <br/>
          <label htmlFor='gender'> Gender <br/>
            <input className={className("gender")}  disabled={!editable} type='text' id='gender' value={getPatient.gender} onChange={(e) => setPatient({...getPatient, gender:e.target.value})} /> 
          </label>
        </label>

        <label>
          <label htmlFor='dob'>Date of Birth <br/>
            <input className={className("dob")}  disabled={!editable} type='date' id='dob' value={getPatient.dob_string} onChange={(e) => {setPatient({...getPatient, dob_string: e.target.value, dob: new Date(e.target.value).getTime() || Date.now()})}} />
          </label>
          <br/>
          <label htmlFor='age'> Age <br/>
            <input disabled={true} type='text' id='age' value={getAge(getPatient.dob)} />
          </label>
        </label>
        <label>
          <label htmlFor='title'> Title <br/>
            <input className={className("title")} disabled={!editable} type='text' id='title' value={getPatient.title || ""} onChange={(e) => setPatient({...getPatient, title: e.target.value})} />
          </label>
          <br/>
          <label htmlFor='knownAs'>Known As <br/>
            <input className={className("known_as")} disabled={!editable} type='text' id='knownAs' value={getPatient.known_as || ""} onChange={(e) => setPatient({...getPatient, known_as: e.target.value})} />
          </label>
        </label>
        
        <label htmlFor='flag' className={className("flags")}> Patient Flags <br/>
          <CheckBox editable={editable} getState={getPatient.flags} setState={(newFlags) => setPatient({ ...getPatient, flags: newFlags })} options={props.flags.map(f=> {return {key:f.name, value:f._id}})}/>
        </label>
        
        <label htmlFor='scheme' className={className("scheme")}> Scheme <br/>
          <CheckBox editable={editable} getState={getPatient.schemes} setState={(newScheme) => setPatient({...getPatient, schemes:newScheme})} options={props.schemes.map(s=> {return {key:s.name, value:s._id}})}/>
        </label>

        <label>
          <TelephoneField getState={getPatient.telephones} setState={(newTelephone) => {setPatient({...getPatient, telephones:newTelephone.filter(t => t!==undefined && t.number.trim().length + Number(editable) > 0)})}} editable={editable}/>
        </label>   

        <label>
          <EmailField emails={getPatient.emails} setEmails={editable ? (newEmails) => {setPatient({...getPatient, emails:newEmails.filter(e => e!==undefined && (e.email.trim().length> 0 || editable))})}:undefined}/>
        </label>

        <label>
          <SocialField getState={getPatient.socials} setState={(newSocial) => {setPatient({...getPatient, socials:newSocial.filter(s=> s!=undefined && s.platform.trim().length > 0 && s.social.trim().length + Number(editable) > 0)})}} editable={editable} /> 
        </label>
        
        <label> Address <br/>
            <label htmlFor='street1' > Street One: </label> <br/>
              <input  className={className("streetOne")} disabled={!editable} type='text' id='street1' value={getPatient.addresses[0].streetOne} onChange={(e) => setPatient({...getPatient, addresses:[{...getPatient.addresses[0], streetOne:e.target.value}]})} /><br/>
            <label htmlFor='street2'> Street Two: </label> <br/>
              <input  disabled={!editable} type='text' id='street2' value={getPatient.addresses[0].streetTwo} onChange={(e) => setPatient({...getPatient, addresses:[{...getPatient.addresses[0], streetTwo:e.target.value}]})} /><br/>
            <label htmlFor='city'> City: </label> <br/>
              <input className={className("city")} disabled={!editable} type='text' id='city' value={getPatient.addresses[0].city} onChange={(e) => setPatient({...getPatient, addresses:[{...getPatient.addresses[0], city:e.target.value}]})} /><br/>
            <label htmlFor='postcode'> Postcode: </label> <br/>
              <input className={className("postcode")} disabled={!editable} type='text' id='postcode' value={getPatient.addresses[0].postcode} onChange={(e) => setPatient({...getPatient, addresses:[{...getPatient.addresses[0], postcode:e.target.value}]})} /><br/>
        </label>
        
        {getPatient._id &&
        <label> Attendance <br/>
            <label htmlFor='lastSeen'> Last Appointment: </label> <br/>
              {getPatient.last_appointment?<Link to={`/diary?date=${new Date(getPatient.last_appointment.start).getTime()}`} >
                <input 
                  className='w-full font-bold cursor-pointer'
                  type='text' 
                  readOnly
                  id='lastSeen' 
                  value={format(new Date(getPatient.last_appointment.start), "dd/MM/yy HH:mm - ") + getPatient.last_appointment.title} 
                />
              </Link>:<input className='w-full cursor-default' type='text' id='lastSeen' value='' disabled/>}
              <br/>
            <label htmlFor='nextSeen'> Next Appointment: </label> <br/>
              {getPatient.next_appointment?<Link to={`/diary?date=${new Date(getPatient.next_appointment.start).getTime()}`}>
                <input 
                  className='w-full font-bold cursor-pointer'
                  type='text' 
                  readOnly
                  id='nextSeen' 
                  value={format(new Date(getPatient.next_appointment.start), "dd/MM/yy HH:mm - ") + getPatient.next_appointment.title} 
                />
              </Link>:<input className='w-full cursor-default' type='text' id='nextSeen' value='' disabled/>              
            }

              <br/>

              <br/>
        </label>}

        {editable && <button className='mx-auto md:col-span-3 sm:col-span-2 lg:col-span-4'  disabled={(!!getPatient.deleted_at || getPatient.forename.trim() === "" || getPatient.surname.trim() === "" || !getPatient.sex || getPatient.sex.trim() === "") } type='submit' > {getPatient._id ? "Save": "Create"} </button>}
      </form>

    </div>
  );
}
