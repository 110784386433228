import { z } from "zod";
import { docSchema, docViewSchema, zViewOID } from "./document/document";

export const stockContactsBrandSchema = z.object({
    name: z.string().trim().min(1, "Name is required").max(64, "Name is too long"),
    description: z.string().trim().max(256, "Description is too long"),
    is_active: z.boolean().default(true),
}).merge(docSchema);

export type StockContactsBrand = z.infer<typeof stockContactsBrandSchema>;

export const stockContactsBrandViewSchema = stockContactsBrandSchema.merge(docViewSchema)
export type StockContactsBrandView = z.infer<typeof stockContactsBrandViewSchema>;

export const stockContactsBrandFormSchema = stockContactsBrandSchema.merge(z.object({
    _id: zViewOID.optional()
}))
export type StockContactsBrandForm = z.infer<typeof stockContactsBrandFormSchema>;