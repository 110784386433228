import { Chart } from "va_shared/src/schema/examination";

export interface IPrescriptionTableProps {
    prescriptions: Chart[];
    setPrescriptions?: (prescriptions: Chart[]) => void;
}

function doubleTr(placeholder:string, value_left:string, value_right:string, update_left?: (n:string) => void, update_right?: (n:string) => void) {
    return (
        <td className="border-x px-0.5 text-right">
            <span className="flex flex-col font-semibold items-center">
                <input 
                    className="w-32"
                    type="number" 
                    placeholder={`Right ${placeholder}`}
                    value={value_right} 
                    onChange={e => update_right ? update_right(e.target.value):undefined}
                    readOnly = {!update_right}
                ></input>
                <hr className="flex-grow border-1.5 w-1/2 border-gray-300 border-grey" />
                <input 
                    className="w-32"
                    type="number" 
                    placeholder={`Left ${placeholder}`}
                    value={value_left} 
                    onChange={e => update_left ? update_left(e.target.value):undefined}
                    readOnly = {!update_left}
                ></input>
            </span>
        </td>
    )
}

export function PrescriptionTable({prescriptions, setPrescriptions}: IPrescriptionTableProps) {
    return (
        <table className="table-fixed divide-y divide-gray-200 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <thead className="w-full divide-y shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <tr className="">
                    <th className="py-3 w-1/12 text-center border-x-2 text-sm whitespace-nowrap font-medium text-gray-600 uppercase tracking-wider">
                        Distance
                    </th>
                    <th className="py-3 w-1/12 text-center border-x-2 text-sm whitespace-nowrap font-medium text-gray-600 uppercase tracking-wider">
                        Eye
                    </th>
                    <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                        Sphere
                    </th>
                    <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                        Cylinder
                    </th>
                    <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                        Axis
                    </th>
                    <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                        Prism
                    </th>
                    <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                        Base
                    </th>
                    <th className="py-3 w-1/12 text-center text-sm whitespace-nowrap font-medium text-gray-600 border-x-2 uppercase tracking-wider">
                        Add
                    </th>
                </tr>
            </thead>
            <tbody>
                {prescriptions.map((p, i) => (
                    <tr key={i}>
                        <td className="border-x p-1"> {p.distance} </td>
                        <td className="border-x p-1 text-right">
                            <span className="flex flex-col px-2 font-semibold items-center">
                                <p className="p-0.5"> Right </p>
                                <hr className="flex-grow border-1.5 w-full border-gray-300 border-grey" />
                                <p className="p-0.5">Left</p>
                            </span>
                        </td>

                        {doubleTr("Sphere", p.left_sphere, p.right_sphere, setPrescriptions?n => setPrescriptions(prescriptions.flatMap((p, j) => i === j ? {...p, left_sphere: n} : p)):undefined, setPrescriptions?n => setPrescriptions(prescriptions.flatMap((p, j) => i === j ? {...p, right_sphere: n} : p)):undefined)}
                        {doubleTr("Cylinder", p.left_cylinder, p.right_cylinder, setPrescriptions?n => setPrescriptions(prescriptions.flatMap((p, j) => i === j ? {...p, left_cylinder: n} : p)):undefined, setPrescriptions?n => setPrescriptions(prescriptions.flatMap((p, j) => i === j ? {...p, right_cylinder: n} : p)):undefined)}
                        {doubleTr("Axis", p.left_axis, p.right_axis, setPrescriptions?n => setPrescriptions(prescriptions.flatMap((p, j) => i === j ? {...p, left_axis: n} : p)):undefined, setPrescriptions?n => setPrescriptions(prescriptions.flatMap((p, j) => i === j ? {...p, right_axis: n} : p)):undefined)}
                        {doubleTr("Prism", p.left_prism, p.right_prism, setPrescriptions?n => setPrescriptions(prescriptions.flatMap((p, j) => i === j ? {...p, left_prism: n} : p)):undefined, setPrescriptions?n => setPrescriptions(prescriptions.flatMap((p, j) => i === j ? {...p, right_prism: n} : p)):undefined)}
                        {doubleTr("Base", p.left_base, p.right_base, setPrescriptions?n => setPrescriptions(prescriptions.flatMap((p, j) => i === j ? {...p, left_base: n} : p)):undefined, setPrescriptions?n => setPrescriptions(prescriptions.flatMap((p, j) => i === j ? {...p, right_base: n} : p)):undefined)}
                        {doubleTr("Add", p.left_add, p.right_add, setPrescriptions?n => setPrescriptions(prescriptions.flatMap((p, j) => i === j ? {...p, left_add: n} : p)):undefined, setPrescriptions?n => setPrescriptions(prescriptions.flatMap((p, j) => i === j ? {...p, right_add: n} : p)):undefined)}                   
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

