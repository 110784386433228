import { z } from "zod";
import { docSchema, docViewSchema, zViewOID } from "./document/document";

export const stockTagSchema = z.object({
    name: z.string().trim().min(1, "Name is required").max(64, "Name is too long"),
    description: z.string().trim().max(256, "Description is too long"),
    is_active: z.boolean().default(true),
    is_distinct: z.boolean().default(false),
})
.merge(docSchema)
export type StockTag = z.infer<typeof stockTagSchema>;

export const stockTagViewSchema = stockTagSchema
.merge(docViewSchema)
export type StockTagView = z.infer<typeof stockTagViewSchema>;

export const stockTagFormSchema = stockTagSchema.merge(z.object({
    _id: zViewOID.optional(),
}))
export type StockTagForm = z.infer<typeof stockTagFormSchema>;