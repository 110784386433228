import mongoose from "mongoose";
import { z } from "zod";

export const zOID =  z.instanceof(mongoose.Types.ObjectId) // z.string().refine(mongoose.Types.ObjectId.isValid, "Invalid ID");
export const zViewOIDGenerator = (error: string) => z.string().refine(s => /^[a-f0-9]{24}$/.test(s), error);
export const zViewOID = z.string().length(24, "Invalid ID");

export const docSchema = z.object({
    _id: zOID.default(() => new mongoose.Types.ObjectId()),
})

export const docViewSchema = z.object({
    _id: zViewOID,
})

export const OptionalIdSchema = z.object({
    _id: z.union([zOID, zViewOID]).optional(),
})

export const Timestamp = z.number().int("Invalid timestamp").default(Date.now);

export const TypeSchemaGenerator = (type: string) => z.object({ type: z.literal(type) });