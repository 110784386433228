import { Telephone } from "va_shared/src/schema/patient";

export interface ITelephoneFieldProps {
    setState: (newTelephone: Telephone[]) => void;
    getState: Telephone[];
    editable?: boolean;
}

export default function TelephoneField({setState, getState, editable}: ITelephoneFieldProps){

    const deleteButton = (index: number) => {
        return (e: { preventDefault: () => void; }) => {
            e.preventDefault();
            delete getState[index];
            setState(getState);
        }
    }

    return (
            <div className="w-64"> <label className="">Telephone {editable && 
                <button className="text-2xl font-bold p-0 -translate-y-1.5 text-teal-700 scale-125" onClick={e => {e.preventDefault(); setState([...getState, {
                number: "",
                is_landline: false,
                is_active: true,
            }])}}> + </button>} </label> 
            
            {getState.map((telephone, index) => {
                return (
                <div className="flex border-b-2 border-0 w-content p-0 relative" key={index}> 
                    <input className="w-8 !accent-teal-800 -translate-x-1" title="Active" readOnly key={index+"-2"+telephone.is_active} type="checkbox"  checked={telephone.is_active} onClick={
                        e => {e.preventDefault(); if (!editable) return; setState(getState.map((f,i) => {return i===index?{...f, is_active:!f.is_active}:f} ))}
                    }/> 
                    <input className="w-6 -translate-x-1" title="Landline" readOnly key={index+"-1"+telephone.is_landline} type="checkbox" checked={telephone.is_landline}
                        onClick={
                            e => {e.preventDefault(); if (!editable) return; setState(getState.map((f,i) => {return i===index?{...f, is_landline:!f.is_landline}:f} ))}
                        }
                    /> 
                    <input disabled={!editable} value={telephone.number} className="px-1 inline w-full flex-grow" onChange={e=>{e.preventDefault(); setState(getState.map((f,i) => {return i===index?{...f, number:e.target.value}:f} ))}}/>
                {editable && <button type="button" className="text-red-600 float-right font-bold scale-150 absolute top-1.5 right-3" onClick={deleteButton(index)}> X </button>}
                </div>)
            })}
        </div>);  
}
