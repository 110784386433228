import { format } from "date-fns";
import { useEffect, useState } from "react";
import { TransactionView } from "va_shared/src/schema/transaction"
import { useLoginStore } from "../../context/LoginContext";
import { PrettyPence } from "va_shared/src/utilities/Currency";

export default function ReportTransactions() {
    const async_authorised_request = useLoginStore(state => state.async_authorised_request);

    const [form, setForm] = useState({
        start: format(new Date(), "yyyy-MM-dd"),
        end: format(new Date(), "yyyy-MM-dd")
    });

    const [transactions, setTransactions] = useState<TransactionView[]>([]);

    const startTime = new Date(form.start).getTime();
    const endTime = new Date(form.end).getTime();

    useEffect(() => {
        if (isNaN(startTime) || isNaN(endTime)) {
            return;
        }

        async_authorised_request("POST", "report/transaction", {start: startTime, end: endTime})
            .then(res => setTransactions(res.transactions)).catch(console.error);
    
    }, [startTime, endTime]);

    return (
        <div className="p-2">
            <h1 className="text-3xl">Report Transactions</h1>
            <div className="m-2">
                <form className="text-lg flex flex-row space-x-4">
                    <label>Start</label>
                    <input type="date" name="start" id="start" value={form.start} onChange={e => setForm({...form, start: e.target.value})} />
                    <label>End</label>
                    <input type="date" name="end" id="end" value={form.end} onChange={e => setForm({...form, end: e.target.value})} />
                </form>
            </div>
            <div className="m-5 bg-stone-200 p-5 rounded-sm">
                <h2 className="text-2xl">All Charges Transactions</h2>
                {generateTable(transactions.filter(t => t.sign === -1), true)}
            </div>
            <div className="m-5 bg-stone-200 p-5 rounded-sm">
                <h2 className="text-2xl">Payments: Cash </h2>
                {generateTable(transactions.filter((t) => t.type==="Payment" && "method" in t && t.method === "Cash"))}
            </div>
            <div className="m-5 bg-stone-200 p-5 rounded-sm">
                <h2 className="text-2xl">Payments: Credit Card </h2>
                {generateTable(transactions.filter((t) => t.type==="Payment" && "method" in t && t.method === "Card (Credit)"))}
            </div>
            <div className="m-5 bg-stone-200 p-5 rounded-sm">
                <h2 className="text-2xl">Payments: Debit Card </h2>
                {generateTable(transactions.filter((t) => t.type==="Payment" && "method" in t && t.method === "Card (Debit)"))}
            </div>
            <div className="m-5 bg-stone-200 p-5 rounded-sm">
                <h2 className="text-2xl">Payments: Cheque</h2>
                {generateTable(transactions.filter((t) => t.type==="Payment" && "method" in t && t.method === "Cheque"))}
            </div>
            <div className="m-5 bg-stone-200 p-5 rounded-sm">
                <h2 className="text-2xl">Payments: Bank Transfer</h2>
                {generateTable(transactions.filter((t) => t.type==="Payment" && "method" in t && t.method === "Bank Transfer"))}
            </div>
            <div className="m-5 bg-stone-200 p-5 rounded-sm">
                <h2 className="text-2xl">Other Transactions</h2>
                {generateTable(transactions.filter((t) => t.type!=="Payment" && t.type!=="Charge"))}
            </div>
        </div>
    );
}

function generateTable(transactions: TransactionView[], isNet = false) {
    return (
        <table className="table-fixed w-full mx-auto">
            <thead className="border-b border-stone-600 font-medium">
                <tr className="text-left">
                    <th>Patient ID</th>
                    <th>Amount (£)</th>
                    <th>Type</th>
                    <th>Timestamp</th>
                </tr>
            </thead>
            <tbody>
                {transactions.map(transaction => {
                    return (
                        <tr key={transaction._id}>
                            <td>{transaction.patient.patient_id? transaction.patient.patient_id: "(Patient not found)"}</td>
                            <td>{PrettyPence(transaction.amount)}</td>
                            <td>{transaction.type}</td>
                            <td>{format(new Date(transaction.timestamp), "yyyy-MM-dd HH:mm:ss")}</td>
                        </tr>
                    )
                })}
            </tbody>
            <tfoot className="font-bold text-stone-500 text-lg border-t border-stone-400">
                <tr>
                    <td colSpan={1}>{isNet?"Gross":"Total"}:</td>
                    <td colSpan={3}>{PrettyPence(transactions.reduce((total, t) => total + t.amount, 0))}</td>
                </tr>
            </tfoot>

        </table>
    );
}