import { useState } from 'react';
import noDefault from '../../shared/noDefault';
import { format } from 'date-fns';
import { APIMethod } from '../../shared/api';
import Spinner from "../../shared/SpinnerWedges.svg"
import { PrettyPence } from 'va_shared/src/utilities/Currency';
import { TransactionAny} from 'va_shared/src/schema/transaction';
import { PatientView } from 'va_shared/src/schema/patient';
import { ScaleIcon } from '@heroicons/react/24/solid';

export interface ICreateTransactionProps {
  patient: PatientView,
  createTransaction: (transaction: TransactionAny) => void,
  async_authorised_request: ( method: APIMethod, url: string, data?: any) => Promise<any>;
  type: string, 
  currentBalance: number
  selectedTransaction?: TransactionAny,
}

//TODO: hide payments which can't be refunded

export default function CreateTransaction ({patient, type, async_authorised_request ,createTransaction, selectedTransaction, currentBalance}: ICreateTransactionProps) {
  const reference = crypto.randomUUID();
  const [pound, setPound] = useState<number>(0);
  const [pence, setPence] = useState<number>(0);
  const [description, setDescription] = useState<string>('');
  const [method, setMethod] = useState<"Cash" | "Card (Credit)" | "Cheque" | "Bank Transfer" | "Card (Debit)" | "Practice">((type.toString()==="Payment" || type.toString()==="Refund") ? "Cash":"Practice");

  const [loading, setLoading] = useState<boolean>(false)

  const [errors, setErrors] = useState<string[]>([]);

  const amountAsPence = ((pound*100)+pence)
  const modifier = GetTypeSign(type);
  const futureBalance = currentBalance+(amountAsPence*modifier)
  const selectedTransactionBalance = selectedTransaction ? selectedTransaction.amount + (selectedTransaction.modifiers?Math.abs(selectedTransaction.modifiers.reduce((total, action) => total + action.amount, 0)):0) : amountAsPence 

  if (loading) {
    return (
        <div className="opacity-100 flex justify-center items-center h-screen">
          <img className='h-72 w-72' src={Spinner} alt="loading"/>
        </div> 
      )
  }

  return (
    <div onClick={noDefault(() => {})} className='border-2 border-stone-500 bg-stone-200 p-3 rounded-md max-w-md grid grid-flow-row space-y-2 shadow-md'>
        <h1 className='text-xl'> Create New {type} </h1>
        <div>
          {errors.map((e) => <p key={e} className="text-red-500">{e}</p>)}
        </div>

        <div>
          <label className='block' htmlFor='pound'>Amount:</label>
          <button className='inline mx-2' onClick={noDefault(() => {setPound(Math.floor(Math.abs(currentBalance/100)));setPence(Math.abs(currentBalance%100))})}> <ScaleIcon className="h-5"/> </button>
          <b>£</b><input onClick={noDefault(() => setPound(0))} className={'w-1/5 appearance-none text-right px-1 rounded-sm py-0.5 m-0.5'+(selectedTransactionBalance <0?" bg-red-200":'')} type='text' id='pound' value={pound.toString()} onChange={e=>{
            const value = parseInt("0"+e.target.value)
            if (value >= 0 && value < 9999) setPound(value)
          }}/><b>.</b><input onClick={noDefault(() => setPence(0))} className={'w-1/5 appearance-none text-right px-1 rounded-sm py-0.5 m-0.5'+(selectedTransactionBalance <0?" bg-red-200":'')} type='text' id='pence' value={pence.toString().padStart(2,"0")} onChange={e=>{
            const value = parseInt(e.target.value)
            if (value >= 0 && value < 100) setPence(value)
          }}/>
          <select className='px-1 py-0.5 text-center bg-stone-50 font-semibold mx-1 rounded-sm relative disabled:appearance-none' disabled={type !== "Payment" && type !== "Refund"} value={method} onChange={e=>{if (["Cash", "Card (Credit)", "Cheque", "Bank Transfer", "Card (Debit)", "Practice"].includes(e.target.value)) setMethod(e.target.value as any)}}>
            <option value='Cash'>Cash</option>
            <option value='Card (Credit)'>Card (Credit)</option>
            <option value='Card (Debit)'>Card (Debit)</option>
            <option value='Cheque'>Cheque</option>
            <option value='Bank Transfer'>Bank Transfer</option>
            <option value='Practice'>Practice</option>
          </select>
        </div>

        <div>
            <label className='block' htmlFor='description'>Description:</label>       
            <textarea className='w-full rounded-sm' value={description} onChange={e=>setDescription(e.target.value)}></textarea>
        </div>
     

        {
          selectedTransaction && <div>
            <h2>Selected {selectedTransaction.type}</h2>
            <div className='bg-stone-50 rounded-sm p-0.5 relative'>
              <table className='w-full text-center table-fixed' title={selectedTransaction.description}>
                <tbody className=''>
                  <tr className='border-b border-stone-400 font-bold'>
                    <td> {selectedTransaction.type} {selectedTransaction.method?`(${selectedTransaction.method})`:""} </td>
                    <td> {format(selectedTransaction.created_at, "dd/MM/yy HH:mm")} </td>
                    <td> {PrettyPence(selectedTransaction.amount)} </td>
                  </tr>
                  {selectedTransaction.modifiers && selectedTransaction.modifiers.map(tran => <tr key={tran._id} title={tran.description}>
                    <td> {tran.type} {selectedTransaction.method?`(${selectedTransaction.method})`:""} </td>
                    <td> {format(tran.created_at, "dd/MM/yy HH:mm")} </td>
                    <td> {PrettyPence(tran.amount)} </td>
                  </tr>)}
                  <tr className='border-t border-stone-200 font-semibold'>
                    <td> Net: </td>
                    <td> </td>
                    <td> {PrettyPence(selectedTransactionBalance)} </td>
                  </tr>
                </tbody>
              </table>
              <span title='Hover for more Information' className='absolute top-0 right-1'>*</span>
            </div>
          </div>
        }

      

        <hr className='my-2 border border-stone-400'/>

        <div className='flex flex-row justify-between'>
          <div>
            <label className='block' htmlFor='balance'>Current Balance:</label>
            <input readOnly className={"px-1 py-0.5 rounded-sm font-semibold"+((currentBalance<0)?" bg-red-200":"")} value={PrettyPence(currentBalance, true)}></input>
          </div>
    
          <div>
            <label className='block' htmlFor='balance'>Future Balance:</label>
            <input readOnly className={"px-1 py-0.5 rounded-sm font-semibold"+((futureBalance<0)?" bg-red-300":"")} value={PrettyPence(futureBalance, true)}></input>
          </div>
        </div>


        <button onClick={noDefault(()=>{
          if (loading) return
          setLoading(true)
          async_authorised_request("POST", "transaction/", {patient:patient._id, type, method, transaction:selectedTransaction?._id,  amount:amountAsPence, description, reference}).then((res) => {
            createTransaction(res.transaction)
          }).catch((res) => {
            setLoading(false)
            setErrors([res.error])
          })
        })} className=' bg-stone-500 hover:bg-stone-700 text-white font-semi-bold py-1 px-2 mx-auto mt-2 mb-1 w-1/2 rounded-sm'>Create</button>
    </div>
  );
}
function GetTypeSign(type: string) {
  return type === "Refund" || type === "Charge" ? -1 : 1;
}

