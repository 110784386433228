import { useState } from 'react';
import noDefault from '../../shared/noDefault';
import { PrettyPence } from 'va_shared/src/utilities/Currency';
import { StockTagView } from 'va_shared/src/schema/stockTag';
import { StockPriceQuantity } from 'va_shared/src/schema/stockPrice';
import useSessionStorageState from 'use-session-storage-state';

export interface ICreateSaleProps {
  createTransaction: (price:number, quantity:number, description:string, stock:string, stockPrice:string) => void,
  currentBalance: number,
  saleItems: StockPriceQuantity[]
  errors: string[]
  tags: StockTagView[]
}

export default function CreateSale ({createTransaction, currentBalance, saleItems, errors, tags}: ICreateSaleProps) {
  const [price, setPrice] = useSessionStorageState<number>("CreateSalePrice", {defaultValue: 0});
  const [description, setDescription] = useSessionStorageState<string>("CreateSaleDescription", {defaultValue: ""});
  const [quantity, setQuantity] = useSessionStorageState<number>("CreateSaleQuantity", {defaultValue: 1});
  const [selectedItem, setSelectedItem] = useSessionStorageState<StockPriceQuantity | null>("CreateSaleSelectedItem", {defaultValue: null});
  const [itemSearch, setItemSearch] = useState<{name:string, description:string, tag:string}>({
    name: "",
    tag: "",
    description: "",
  });

  const futureBalance = currentBalance-price

  return (
    <div onClick={noDefault(() => {})} className='border-2 border-stone-500 bg-stone-200 p-3 rounded-md w-full max-w-lg grid grid-flow-row space-y-2 shadow-md'>

        <h1 className='text-3xl text-center'> New Sale </h1>

        {errors.length > 0 &&
          <div>
            {errors.map((e) => <p key={e} className="text-red-600 text-lg bg-red-300 px-2 text-center font-semibold">{e}</p>)}
          </div>
        }
        
        <label onClick={noDefault(() => setSelectedItem(null))} className='block text-2xl hover:cursor-pointer' htmlFor='Item'>Item: {selectedItem?.stock.name || <p className='inline pb-2 italic text-stone-400'>Select an item</p>}</label>

        {!selectedItem && <div className='bg-stone-300 rounded-md p-2 space-y-2'>
          <div>
            <div>
              <label className='block' htmlFor='Item'>Name:</label>
              <input className='w-full rounded-sm' type='text' id='Item' value={itemSearch.name} onChange={e=>{
                setItemSearch({...itemSearch, name: e.target.value})
              }}/>
            </div>
            
            <div>
              <label className='block' htmlFor='Item'>Description:</label>
              <input className='w-full rounded-sm' type='text' id='Item' value={itemSearch.description} onChange={e=>setItemSearch({...itemSearch, description: e.target.value})}/>
            </div>

            <div>
              <label className='block' htmlFor='Item'>Tag:</label>
              <select className='w-full rounded-sm p-1 bg-stone-50' value={itemSearch.tag} onChange={e=>setItemSearch({...itemSearch, tag: e.target.value})}>
                <option value="">Select a tag</option>
                {tags.filter(t=>t.is_active).map(tag => <option key={tag._id} value={tag._id}>{tag.name}</option>)}
              </select>
            </div>
          </div>
     

          <div className='max-h-64 overflow-hidden overflow-y-scroll py-1'>
              {
                saleItems.filter(i => ( (!itemSearch.description && !itemSearch.name && !itemSearch.tag) || i.stock.description.toLowerCase().includes(itemSearch.description.toLowerCase()) && i.stock.name.toLowerCase().includes(itemSearch.name.toLowerCase()) && (!itemSearch.tag || i.stock.tags.map(t=>t._id).includes(itemSearch.tag) ))).map((item) =>
                  <div title={item.stock.description} key={item._id} className='flex flex-row justify-between hover:font-bold px-2 hover:bg-stone-200 hover:cursor-pointer'
                    onClick={noDefault(()=>{
                      setSelectedItem(item)
                      setPrice(item.price)
                    })}
                  >
                    <span>{item.stock.name}</span>
                    <span>£{PrettyPence(item.price)}</span>
                  </div>
                )
              }
            </div>
        </div>}

        {selectedItem && 
        <div>
          <div>
            <label className='block' htmlFor='Item'>Quantity:</label>
            <div className='flex flex-row justify-between'>
              <input className='w-3/4 rounded-sm px-1' type='number' id='Item' value={quantity} onChange={e=>setQuantity(parseInt(e.target.value))}/>
              <label className='block mx-2' htmlFor='Item'>/</label>
              <input readOnly className='w-1/4 rounded-sm px-1 font-semibold' value={(selectedItem?.quantities?.reduce((t, q) => t + (q.available || 0), 0) || 0) + ((selectedItem?.quantities?.findIndex(q=>q.available === null) || -1) === -1? "": "*") }></input>
            </div>
          </div>
          
          <div>
            <label className='block' htmlFor='cost'>Cost:</label>
            <div className='flex flex-row justify-between'>
              <span className="w-3/4 flex flex-row"> 
                <label className="px-1" htmlFor="cost">£</label>
                <input className='px-1 w-1/2 ' type="number" min={0} value={Math.floor(price / 100).toString()} step={0} onChange={(e) => setPrice((parseInt(e.target.value,10) * 100) + (price % 100))}/>
                <label className="px-1" htmlFor="cost">.</label>
                <input className='px-1 w-1/2' type="number" min={0} max={99}  value={price ? (price % 100).toString() : 0} step={0} onChange={(e) => setPrice(Math.floor(price / 100) * 100 + parseInt(e.target.value,10))} />
              </span>      
              <input readOnly className="px-1 py-0.5 ml-4 rounded-sm font-semibold" value={"£"+PrettyPence(price*quantity)}></input>
            </div>
          </div>

          <div>
              <label className='block' htmlFor='description'>Description:</label>       
              <textarea className='w-full rounded-sm' value={description} onChange={e=>setDescription(e.target.value)}></textarea>
          </div>     

          <hr className='my-2 border border-stone-400'/>

          <div className='flex flex-row  justify-between'>
            <div>
              <label className='block' htmlFor='balance'>Current Balance:</label>
              <input readOnly className={"px-1 py-0.5 rounded-sm font-semibold"+((currentBalance<0)?" bg-red-200":"")} value={PrettyPence(currentBalance, true)}></input>
            </div>
      
            <div>
              <label className='block' htmlFor='balance'>Future Balance:</label>
              <input readOnly className={"px-1 py-0.5 rounded-sm font-semibold"+((futureBalance<0)?" bg-red-300":"")} value={PrettyPence(futureBalance, true)}></input>
            </div>
          </div>

          <button disabled={!selectedItem} onClick={noDefault(()=>{
          if (!selectedItem) return
          createTransaction(price, quantity, description, selectedItem.stock._id, selectedItem._id)
        })} className='w-full text-lg disabled:bg-stone-400 bg-stone-500 hover:bg-stone-700 text-white font-semi-bold py-1 px-2 mx-auto mt-4 mb-1 rounded-sm'>Create</button>
        
        </div>}
</div>
  );
}
