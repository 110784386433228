import { z } from "zod";
import { docSchema, docViewSchema, zOID, zViewOIDGenerator } from "./document/document";
import { creatableSchema, creatableViewSchema } from "./document/logging/creatable";
import { schemeViewSchema } from "./scheme";
import { stockViewSchema } from "./stock";
import { isGreaterThanToday } from "../utilities/Validation";
import { deletableSchema, deletableViewSchema } from "./document/logging/deletable";
import { StockQuantityView } from "./stockQuantity";

const baseStockPriceSchema = z.object({
    stock: zOID,
    scheme: zOID,
    price: z.number().int("Price must be an integer").min(0, "Price must be positive"),
    start_at: z.number().int("Invalid Start Format").refine(isGreaterThanToday, {message: "Start must be today or later"}),
    end_at: z.number().int("Invalid End Format").refine(isGreaterThanToday, {message: "End must be today or later"}).nullable(), // means indefinite
});

export const stockPriceSchema = baseStockPriceSchema
.merge(docSchema)
.merge(creatableSchema)
.merge(deletableSchema);
export type StockPrice = z.infer<typeof stockPriceSchema>;

export const stockPriceViewSchema = baseStockPriceSchema
.merge(z.object({
    stock: stockViewSchema,
    scheme: schemeViewSchema,
}))
.merge(docViewSchema)
.merge(creatableViewSchema)
.merge(deletableViewSchema)
.refine(data => !data.end_at || data.start_at < data.end_at, {message: "End Time must be after Start Time"});

export type StockPriceView = z.infer<typeof stockPriceViewSchema>;

export const stockPriceFormSchema = baseStockPriceSchema
.merge(z.object({
    stock: zViewOIDGenerator("Invalid Stock"),
    scheme: zViewOIDGenerator("Invalid Scheme"),
})).refine(data => !data.end_at || data.start_at < data.end_at, {message: "End Time must be after Start Time"});

export type StockPriceForm = z.infer<typeof stockPriceFormSchema>;

export type StockPriceQuantity = StockPriceView & {quantities: StockQuantityView[]};