import { z } from "zod";
import { docSchema, docViewSchema, Timestamp, zOID } from "./document/document";
import { patientViewSchema } from "./patient";
import { spectacleLensSchema } from "./SpectaclesLens";
import { creatableSchema, creatableViewSchema } from "./document/logging/creatable";
import { stockFrameViewSchema } from "./stock";

export const SpectacleTypes = ["Spectacles", "SpectaclesSunglasses", "SpectaclesCutEdge", "SpectaclesLensOnly", "SpectaclesPrescription"] as const;

// Base
export const spectaclesSchema = z.object({
    patient: zOID,
    description: z.string().trim().max(256, "Description is too long"),
    ordered_at: Timestamp,
    due_at: Timestamp,
    received_at: Timestamp,
    delivered_at: Timestamp,
    type: z.enum(SpectacleTypes),
})
.merge(docSchema)
.merge(creatableSchema);
export type Spectacles = z.infer<typeof spectaclesSchema>;

// Base View
export const spectaclesViewSchema = spectaclesSchema.merge(z.object({
    patient: patientViewSchema.partial(),
    lens: spectacleLensSchema.partial().merge(docViewSchema),
}))
.merge(docViewSchema)
.merge(creatableViewSchema);
export type SpectaclesView = z.infer<typeof spectaclesViewSchema>;

// TODO make a stockframe type for sunglass, rayban.etc non prescription glasses
// // Sunglasses
// export const spectaclesSunglassesSchema = spectaclesSchema.merge(z.object({
//     frame: zOID,
// }));
// export type SpectaclesSunglasses = z.infer<typeof spectaclesSunglassesSchema>;

// export const spectaclesSunglassesViewSchema = spectaclesSunglassesSchema.merge(z.object({
//     frame: stockFrameViewSchema.partial(),
// }));
// export type SpectaclesSunglassesView = z.infer<typeof spectaclesSunglassesViewSchema>;

// Cut Edge
export const spectaclesCutEdgeSchema = spectaclesSchema.merge(z.object({
    frame: zOID,
}));
export type SpectaclesCutEdge = z.infer<typeof spectaclesCutEdgeSchema>;

export const spectaclesCutEdgeViewSchema = spectaclesCutEdgeSchema.merge(z.object({
    frame: stockFrameViewSchema.partial(),
}))
.merge(docViewSchema);
export type SpectaclesCutEdgeView = z.infer<typeof spectaclesCutEdgeViewSchema>;

// Lens Only
export const spectaclesLensOnlySchema = spectaclesSchema.merge(z.object({
    lens: zOID,
}));
export type SpectaclesLensOnly = z.infer<typeof spectaclesLensOnlySchema>;

export const spectaclesLensOnlyViewSchema = spectaclesLensOnlySchema.merge(z.object({
    lens: spectacleLensSchema.partial()
})).merge(docViewSchema);
export type SpectaclesLensOnlyView = z.infer<typeof spectaclesLensOnlyViewSchema>;

// Prescription
export const spectaclesPrescriptionSchema = spectaclesSchema.merge(z.object({
    frame: zOID,
    lens: zOID,
})).merge(docSchema);
export type SpectaclesPrescription = z.infer<typeof spectaclesPrescriptionSchema>;

export const spectaclesPrescriptionViewSchema = spectaclesPrescriptionSchema.merge(z.object({
    frame: stockFrameViewSchema.partial(),
    lens: spectacleLensSchema.partial()
}))
.merge(docViewSchema);
export type SpectaclesPrescriptionView = z.infer<typeof spectaclesPrescriptionViewSchema>;