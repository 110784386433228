import { zOID, zViewOID } from "../document";
import { z } from "zod";
import { logAccountSchemaView } from "./logAccount";

export const deletableSchema = z.object({
    deleted_by: zOID.nullable().default(null),
    deleted_at: z.number().int("Invalid timestamp").nullable().default(null),
})

export const deletableViewSchema = z.object({
    deleted_by: logAccountSchemaView.partial().nullable().default(null),
    deleted_at: z.number().int("Invalid timestamp").nullable().default(null),
})

export const deletableSimpleSchema = z.object({
    deleted_by: zViewOID.nullable().default(null),
    deleted_at: z.number().int("Invalid timestamp").nullable().default(null),
})
