import {z} from "zod";
import {docSchema, docViewSchema, TypeSchemaGenerator, zOID, zViewOID, zViewOIDGenerator} from "./document/document";
import { stockLocationViewSchema } from "./stockLocation";
import { stockFrameManufacturerViewSchema } from "./stockFrameManufacturer";
import { stockTagViewSchema } from "./stockTag";
import { stockContactsBrandViewSchema } from "./stockContactsBrand";

export const StockTypes = ["Stock", "StockFrame", "StockContacts"] as const;
export const ContactsPeriods = ["Day", "Week", "Month", "2 Month", "3 Month", "Durable"] as const;
export const StockTypesMap = { Stock: "Product", StockFrame: "Frame", StockContacts: "Contacts" } as const;

export const stockSchema = z.object({
    sku: z.string().trim().min(4, "SKU is required").refine(value => value.length < 4 || /^[a-zA-Z0-9-]+$/.test(value), { message: "SKU must be alphanumeric" }),
    tags: z.array(zOID).default([]),
    name: z.string().trim().min(1, "Name is required").max(64, "Name is too long"),
    description: z.string().trim().max(256, "Description is too long"),
    location: zOID.nullable(),
    type: z.enum(StockTypes)
})
.merge(docSchema)
export type Stock = z.infer<typeof stockSchema>;

const baseStockFrameSchema = z.object({
    manufacturer: zOID,
    size: z.number().min(1, "Size is required"),
    colour: z.string().trim().min(1, "Color is required"),
    is_nhs: z.boolean().default(false),
})

const baseStockContactsSchema = z.object({
    brand: zOID,
    is_trial: z.boolean().default(false),
    is_toric: z.boolean().default(false),
    is_multifocal: z.boolean().default(false),
    is_coloured: z.boolean().default(false),
    period: z.enum(ContactsPeriods),
})

export const stockFrameSchema = stockSchema.merge(baseStockFrameSchema).merge(TypeSchemaGenerator("StockFrame"));
export type StockFrame = z.infer<typeof stockFrameSchema>;

export const stockFrameViewSchema = stockSchema.merge(docViewSchema).merge(baseStockFrameSchema).merge(z.object({
    manufacturer: stockFrameManufacturerViewSchema,
}))
export type StockFrameView = z.infer<typeof stockFrameViewSchema>;

export const stockContactsSchema = stockSchema.merge(baseStockContactsSchema).merge(TypeSchemaGenerator("StockContacts"));
export type StockContacts = z.infer<typeof stockContactsSchema>;

export const stockContactsViewSchema = stockSchema.merge(docViewSchema).merge(baseStockContactsSchema).merge(z.object({
    brand: stockContactsBrandViewSchema,
}))
export type StockContactsView = z.infer<typeof stockContactsViewSchema>;

export const stockViewSchema = stockSchema
.merge(docViewSchema)
.merge(baseStockContactsSchema.partial())
.merge(baseStockFrameSchema.partial())
.merge(z.object({
    tags: z.array(stockTagViewSchema).default([]),
    location: stockLocationViewSchema.nullable(),
    type: z.enum(StockTypes),
    brand: stockContactsBrandViewSchema.optional().nullable(),
    manufacturer: stockFrameManufacturerViewSchema.optional().nullable(),
}))
export type StockView = z.infer<typeof stockViewSchema>;

export const stockFormSchema = stockSchema.merge(z.object({
    sku: z.string().trim().min(4, "SKU is required").refine(value => value.length < 4 || /^[a-zA-Z0-9-]+$/.test(value), { message: "SKU must be alphanumeric" }).optional().nullable(),
    _id: zViewOID.optional().nullable(),
    location: zViewOIDGenerator("Invalid Location").optional().nullable(),
    tags: z.array(zViewOIDGenerator("Invalid Tag")).default([]),

    brand: zViewOIDGenerator("Invalid Brand").optional().nullable(),
    is_trial: z.boolean().default(false),
    is_toric: z.boolean().default(false),
    is_multifocal: z.boolean().default(false),
    is_coloured: z.boolean().default(false),
    period: z.enum(ContactsPeriods).optional().nullable(),

    manufacturer: zViewOIDGenerator("Invalid Manufacturer").optional().nullable(),
    size: z.number({message:"Incorrect Size Format"}).optional().nullable(),
    colour: z.string().optional().nullable(),
    is_nhs: z.boolean().default(false),  

}))
.refine(s => s.type !== "StockFrame" || !!s.manufacturer, { message: "Frame requires Manufacturer", path: ["manufacturer"] })
.refine(s => s.type !== "StockFrame" || !!s.size, { message: "Frame requires Size", path: ["size"] })
.refine(s => s.type !== "StockFrame" || !!s.colour, { message: "Frame requires Colour", path: ["colour"] })
.refine(s => s.type !== "StockContacts" || !!s.brand, { message: "Contacts requires Brand", path: ["brand"] })
.refine(s => s.type !== "StockContacts" || !!s.period, { message: "Contacts requires Period", path: ["period"] })
export type StockForm = z.infer<typeof stockFormSchema>;