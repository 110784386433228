import { NavbarLink } from "../../context/NavbarContext";

export function GetPatientNav(patient_id: string, currentName:string){
    return [
        NavbarLink({name: "Home", path: "/patient/"+patient_id, className:currentName==="Home"?"px-5 py-0.5 mx-0.5 text bg-teal-700 hover:bg-teal-800":undefined}),
        NavbarLink({name: "Notes", path: `/patient/${patient_id}/notes`, className:currentName==="Notes"?"px-5 py-0.5 mx-0.5 text bg-teal-700 hover:bg-teal-800":undefined}),
        NavbarLink({name: "Images", path: `/patient/${patient_id}/images`, className:currentName==="Images"?"px-5 py-0.5 mx-0.5 text bg-teal-700 hover:bg-teal-800":undefined}),
        // NavbarLink({name: "Reminders", path:`/patient/${patient_id}/reminders`, className:currentName==="Reminders"?"px-5 py-0.5 mx-0.5 text bg-teal-700 hover:bg-teal-800":undefined}),
        NavbarLink({name: "Balance", path:`/patient/${patient_id}/balance`, className:currentName==="Balance"?"px-5 py-0.5 mx-0.5 text bg-teal-700 hover:bg-teal-800":undefined}),
        // NavbarLink({name: "Medical", path:`/patient/${patient_id}/medical`, className:currentName==="Medical"?"px-5 py-0.5 mx-0.5 text bg-teal-700 hover:bg-teal-800":undefined}),
        NavbarLink({name: "Examination", path:`/patient/${patient_id}/examination`, className:currentName==="Examination"?"px-5 py-0.5 mx-0.5 text bg-teal-700 hover:bg-teal-800":undefined}),
        // NavbarLink({name: "Prescriptions", path:`/patient/${patient_id}/prescriptions`, className:currentName==="Prescriptions"?"px-5 py-0.5 mx-0.5 text bg-teal-700 hover:bg-teal-800":undefined}),    
        NavbarLink({name: "SMS", path:`/patient/${patient_id}/sms`, className:currentName==="SMS"?"px-5 py-0.5 mx-0.5 text bg-teal-700 hover:bg-teal-800":undefined}),
    ]
}