const URL_HOST = window.location.origin;
const URL_ORIGIN = window.location.origin;
const URL_PATH = "/api/v1/";

export type APIMethod = "GET"| "POST"| "PUT"| "PATCH"| "DELETE";

export async function api_request(method:APIMethod, req: string, userKey:string = "", data: any = undefined, base_path:string = URL_PATH) { 
  return fetch(URL_HOST+base_path+req, {method, credentials:"include", mode:"cors", headers: {
    "Access-Control-Allow-Origin": URL_ORIGIN,
    "Content-Type": "application/json",
    "Accept": "application/json",
    "Access-Control-Allow-Credentials": "true",
    "Authorization":userKey? "Bearer "+userKey: "",
    "cache-control": "no-cache"
  }, body: data ? JSON.stringify(data): undefined})
}

export const auth_error = { error: "Not logged in", status: 401 } 