import { z } from "zod";
import { docSchema, docViewSchema } from "./document/document";
import { creatableSchema, creatableViewSchema } from "./document/logging/creatable";

export const SpectacleLensKinds = ["Single Vision", "Bifocal", "Progressive"] as const;

export const spectacleLensSchema = z.object({
    kind: z.enum(SpectacleLensKinds),
    details: z.string().trim().max(256, "Details is too long"),

    left_sphere: z.number().nullable(),
    left_cylinder: z.number().nullable(),
    left_axis: z.number().nullable(),
    left_add: z.number().nullable(),
    left_prism: z.number().nullable(),
    left_base: z.enum(["Up", "Down", "In", "Out"]).nullable(),

    left_distance_ocular_center: z.number().nullable(),
    left_near_ocular_center: z.number().nullable(),
    left_pupil_height: z.number().nullable(),
    left_notes: z.string().trim().nullable(),

    right_sphere: z.number().nullable(),
    right_cylinder: z.number().nullable(),
    right_axis: z.number().nullable(),
    right_add: z.number().nullable(),
    right_prism: z.number().nullable(),
    right_base: z.enum(["Up", "Down", "In", "Out"]).nullable(),

    right_distance_ocular_center: z.number().nullable(),
    right_near_ocular_center: z.number().nullable(),
    right_pupil_height: z.number().nullable(),
    right_notes: z.string().trim().nullable(),

    tint: z.string().trim().nullable(),
    coating: z.string().trim().nullable(),
    treatment: z.string().trim().nullable(),

    notes: z.string().trim().nullable(),
})
.merge(docSchema)
.merge(creatableSchema);
export type SpectacleLens = z.infer<typeof spectacleLensSchema>;

export const spectacleLensViewSchema = spectacleLensSchema
.merge(docViewSchema)
.merge(creatableViewSchema);
export type SpectacleLensView = z.infer<typeof spectacleLensViewSchema>;


