import { z } from "zod";
import { docSchema, docViewSchema, Timestamp, zOID, zViewOID } from "./document/document";
import { creatableSchema, creatableViewSchema } from "./document/logging/creatable";
import { deletableSchema, deletableViewSchema } from "./document/logging/deletable";
import { patientViewSchema } from "./patient";
import { stockContactsViewSchema, stockViewSchema } from "./stock";
import { spectaclesViewSchema } from "./Spectacles";
import { stockPriceViewSchema } from "./stockPrice";

export const transactionTypes = ["Payment", "Refund", "Write-Off", "Credit", "Charge", "Discount", "TransactionStock" , "TransactionSpectacles", "TransactionContacts"] as const;
export const transactionMethods = ["Cash", "Card (Credit)", "Cheque", "Bank Transfer", "Card (Debit)"] as const;
export const transactionTypesMap = { Payment: "Payment", Refund: "Refund", "Write-Off": "Write-Off", Credit: "Credit", Charge: "Charge", Discount: "Discount", TransactionStock: "Stock", TransactionSpectacles: "Spectacles", TransactionContacts: "Contacts" } as const;

const baseTransactionSchema = z.object({
    patient: zOID,
    type: z.enum(transactionTypes),
    amount: z.number().min(0, "Amount is required").int("Amount in pence"),
    description: z.string().trim().max(256, "Description is too long"),
    timestamp: Timestamp.default(Date.now),
    modifiers: z.array(zOID).default([]),
})

export const transactionViewSchema = baseTransactionSchema
.merge(z.object({
    patient: patientViewSchema,
    sign: z.number().int(),
    modifiers: z.lazy(() => z.array(z.object({
        _id: zViewOID,
        type: z.enum(transactionTypes),
        amount: z.number().int(),
        description: z.string().trim().max(256),
        timestamp: Timestamp,
    })))
}))
.merge(docViewSchema)
.merge(creatableViewSchema)
.merge(deletableViewSchema)
export type TransactionView = z.infer<typeof transactionViewSchema>;


export const transactionSchema = baseTransactionSchema
.merge(docSchema)
.merge(creatableSchema)
.merge(deletableSchema)
export type Transaction = z.infer<typeof transactionSchema>;

export const transactionPaymentSchema = transactionSchema.merge(z.object({
    type: z.literal("Payment"),
    method: z.enum(transactionMethods),
}))
export type TransactionPayment = z.infer<typeof transactionPaymentSchema>;

export const transactionPaymentViewSchema = transactionPaymentSchema
.merge(transactionViewSchema);
export type TransactionPaymentView = z.infer<typeof transactionPaymentViewSchema>;

export const transactionRefundSchema = transactionSchema.merge(z.object({
    type: z.literal("Refund"),
    method: z.enum(transactionMethods),
    transaction: zOID,
}))
export type TransactionRefund = z.infer<typeof transactionRefundSchema>;

export const transactionRefundViewSchema = transactionRefundSchema.merge(z.object({transaction:transactionViewSchema})).merge(transactionViewSchema);
export type TransactionRefundView = z.infer<typeof transactionRefundViewSchema>;

export const transactionWriteOffSchema = transactionSchema.merge(z.object({
    type: z.literal("Write-Off"),
    transaction: zOID,
}))
export type TransactionWriteOff = z.infer<typeof transactionWriteOffSchema>;

export const transactionWriteOffViewSchema = transactionWriteOffSchema.merge(z.object({transaction:transactionViewSchema})).merge(transactionViewSchema);
export type TransactionWriteOffView = z.infer<typeof transactionWriteOffViewSchema>;

export const transactionCreditSchema = transactionSchema.merge(z.object({
    type: z.literal("Credit"),
}))
export type TransactionCredit = z.infer<typeof transactionCreditSchema>;

export const transactionCreditViewSchema = transactionCreditSchema.merge(transactionViewSchema);
export type TransactionCreditView = z.infer<typeof transactionCreditViewSchema>;

export const transactionChargeSchema = transactionSchema.merge(z.object({
    type: z.literal("Charge"),
}))
export type TransactionCharge = z.infer<typeof transactionChargeSchema>;

export const transactionChargeViewSchema = transactionChargeSchema.merge(transactionViewSchema);
export type TransactionChargeView = z.infer<typeof transactionChargeViewSchema>;

export const transactionDiscountSchema = transactionSchema.merge(z.object({
    type: z.literal("Discount"),
    transaction: zOID,
}))
export type TransactionDiscount = z.infer<typeof transactionDiscountSchema>;

export const transactionDiscountViewSchema = transactionDiscountSchema.merge(z.object({transaction:transactionViewSchema})).merge(transactionViewSchema);
export type TransactionDiscountView = z.infer<typeof transactionDiscountViewSchema>;

export const transactionStockSchema = transactionSchema.merge(z.object({
    type: z.literal("TransactionStock"),
    stock: zOID,
    quantity: z.number().min(1, "Quantity is required"),
    stockPrice: zOID
}))
export type TransactionStock = z.infer<typeof transactionStockSchema>;

export const transactionStockViewSchema = transactionStockSchema.merge(z.object({stock:stockViewSchema, stockPrice:stockPriceViewSchema})).merge(transactionViewSchema);
export type TransactionStockView = z.infer<typeof transactionStockViewSchema>;

export const transactionSpectaclesSchema = transactionSchema.merge(z.object({
    type: z.literal("TransactionSpectacles"),
    spectacles: zOID,
}))
export type TransactionSpectacles = z.infer<typeof transactionSpectaclesSchema>;

export const transactionSpectaclesViewSchema = transactionSpectaclesSchema.merge(z.object({spectacles:spectaclesViewSchema})).merge(transactionViewSchema);
export type TransactionSpectaclesView = z.infer<typeof transactionSpectaclesViewSchema>;

export const transactionContactsSchema = transactionSchema.merge(z.object({
    type: z.literal("TransactionContacts"),
    contacts: zOID,
    stockPrice: zOID,
    left_power: z.number().nullable(),
    right_power: z.number().nullable(),

    left_base_curve: z.number().nullable(),
    right_base_curve: z.number().nullable(),

    left_diameter: z.number().nullable(),
    right_diameter: z.number().nullable(),

    quantity: z.number().min(1, "Quantity is required"),
}))
export type TransactionContacts = z.infer<typeof transactionContactsSchema>;

export const transactionContactsViewSchema = transactionContactsSchema.merge(z.object({contacts:stockContactsViewSchema, stockPrice:stockPriceViewSchema})).merge(transactionViewSchema);
export type TransactionContactsView = z.infer<typeof transactionContactsViewSchema>;

export type TransactionLike = TransactionPayment | TransactionRefund | TransactionWriteOff | TransactionCredit | TransactionCharge | TransactionDiscount | TransactionStock | TransactionSpectacles | TransactionContacts;
export type TransactionViewLike = TransactionPaymentView | TransactionRefundView | TransactionWriteOffView | TransactionCreditView | TransactionChargeView | TransactionDiscountView | TransactionStockView | TransactionSpectaclesView | TransactionContactsView;


export interface TransactionAny {
    _id: string,
    type: "Payment" | "Refund" | "Write-Off" | "Credit" | "Charge" | "Discount" | "TransactionStock" | "TransactionSpectacles" | "TransactionContacts",
    amount: number,
    description: string,
    timestamp: number,
    created_at: number,
    created_by?: {
        forename: string,
        surname: string,
    },
    deleted_at?: number,
    deleted_by?: {
        forename: string,
        surname: string,
    },
    patient: {
        _id: string,
        forename: string,
        surname: string,
    },
    modifiers: TransactionAny[],
    sign: number,
    method?: "Cash" | "Card (Credit)" | "Cheque" | "Bank Transfer" | "Card (Debit)",
    transaction?: string,
    stock?: string,
    quantity?: number,
    spectacles?: string,
    contacts?: string,
    left_power?: number,
    right_power?: number,
    left_base_curve?: number,
    right_base_curve?: number,
    left_diameter?: number,
    right_diameter?: number,
    notes?: string,
}