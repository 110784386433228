import { useLoaderData } from "react-router-dom"
import { useLoginStore } from "../../../context/LoginContext"
import { auth_error } from "../../../shared/api"
import { StockQuantityForm, stockQuantityFormSchema, StockQuantityView } from "va_shared/src/schema/stockQuantity"
import { useState } from "react"
import { FormErrors } from "../../../shared/Forms"
import { LoadingSpinner } from "../../../shared/Loading"
import { boolStr } from "../../../shared/className"
import { hasError } from "../../../shared/Validation"
import { StockView } from "va_shared/src/schema/stock"
import { format } from "date-fns/format"
import { PrettyPence } from "va_shared/src/utilities/Currency"

const DEFAULT_QUANTITY: StockQuantityForm & FormErrors & {
    purchase_at_input: string
} = {
    stock: "",
    quantity: 1,
    available: 1,
    cost: 0,
    description: "",
    purchased_at: Date.now(),
    errors: [],
    formErrors: [],
    purchase_at_input: format(new Date(), "yyyy-MM-dd")
}

export function AdminStockQuantity(){
    const async_authorised_request = useLoginStore(state=> state.async_authorised_request)
    const context = useLoaderData() as { stocks: StockView[] }
    
    const [quantities, setQuantities] = useState<StockQuantityView[] | "loading">([])
    const [quantityForm, setQuantityForm] = useState(DEFAULT_QUANTITY)
    const [request, setRequest] = useState(false)
    const [ selectedStock, setSelectedStock ] = useState<StockView | null>(null)

    if (request) return <LoadingSpinner/>

    const formSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (quantityForm._id){
            return // Create only
        }

        quantityForm.stock = selectedStock?._id || ""
        quantityForm.purchased_at = new Date(quantityForm.purchase_at_input).getTime() 

        if (isNaN(quantityForm.purchased_at)) {
            quantityForm.purchased_at = Date.now()
        }

        const {error, data} = stockQuantityFormSchema.safeParse(quantityForm)

        if (error) {
            console.log(error)
            setQuantityForm({...quantityForm, formErrors: error.errors})
            return
        }

        setRequest(true)
        async_authorised_request("POST", "stock/quantity", {quantity: data}).then(({quantity}) => {
            if (quantities === "loading") return
            setQuantities([...quantities.map(q => quantity.available === null && q.stock._id === quantity.stock._id && q.available === null ? {...q, available:0} : q), quantity])
            setQuantityForm(DEFAULT_QUANTITY)
        }).catch((error) => {
            console.log(error)
            setQuantityForm({...quantityForm, errors: [error.message], formErrors: []})
        }).finally(() => {
            setRequest(false)
        })
    }

    const formClass = (field:string) => {
        return "px-1 w-1/2 " + boolStr("bg-white", "bg-red-300", hasError(field, quantityForm.formErrors))
    }

    const quantitySum = QuantitiesTotal(quantities === "loading" ? [] : quantities)

    return (
        <div className="m-4">
            <h1 className="text-3xl">Stock Quantities</h1>
            <div className="bg-stone-200 rounded-lg p-4 space-y-4 relative">
                <div>
                    <table className="w-full">
                        <thead className="bg-stone-500 text-lg">
                            <tr>
                                <th>SKU</th>
                                <th>Name</th>
                                <th>Location</th>
                                <th>Size</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody className="h-max-96 overflow-y-scroll">
                        {context.stocks.sort((a,b)=>a.name>b.name?1:-1).map((item) => (
                            <tr key={item._id} className={"hover:bg-stone-400 p-2 text-center hover:cursor-pointer  "+(item._id === selectedStock?._id ? "bg-stone-100":"bg-stone-300")} onClick={
                                () => {
                                    setSelectedStock(item)
                                    setQuantities("loading")
                                    async_authorised_request("GET", "stock/quantity/byStock/"+item._id).then(({quantities}) => {
                                        setQuantities(quantities)
                                    })
                                }}>
                                <td>{item.sku}</td>
                                <td>{item.name}</td>
                                <td>{item.description ? item.description : <i className="text-stone-400 font-semibold">No Description</i>}</td>
                                <td>{item.location ? item.location.name : <i className="text-stone-400 font-semibold">No Location</i>}</td>
                                <td>{{Stock:"Item", StockFrame:"Frame", StockContacts:"Contacts"}[item.type]}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                {
                    selectedStock &&
                    <div>
                        <h1 className="text-2xl text-center">Stock Details</h1>
                        <div className="grid grid-cols-8 gap-x-2">
                        <div className="col-span-5">
                                <form onSubmit={formSubmit} className="p-2 w-full rounded-md space-y-1 border-4 border-stone-400 shadow-md text-lg">
                                    <h1 className="text-2xl text-center">Create Quantity </h1>
                                    <div>
                                        {quantityForm.errors.map((error) => (
                                            <div key={error} className="bg-red-300 text-red-800 p-1 text-center rounded-sm">{error}</div>
                                        ))}
                                        {quantityForm.formErrors.map((error) => (
                                            <div key={(error.code+error.path.join("."))} className="bg-red-300 text-red-800 p-1 text-center rounded-sm">{error.message}</div>
                                        ))}
                                    </div>

                                    <div className="flex flex-row justify-between ">
                                        <label className="text-xl" htmlFor="stock">Stock</label>
                                        <input id="stock" name="stock" value={selectedStock.sku} readOnly className="px-1 w-1/2 bg-white"/>
                                    </div>

                                    <div className="flex flex-row justify-between">
                                        <label className="w-1/2" htmlFor="quantity">Quantity</label>
                                        <div className="flex flex-row w-1/2">
                                            {quantityForm.quantity === null && <input id="quantity" name="quantity" type="text" value="Unlimited" readOnly className="px-1 flex-grow"/>}
                                            {quantityForm.quantity !== null && <input id="quantity" name="quantity" min={1} type="number" value={quantityForm.quantity || 1} onChange={(e) => setQuantityForm({...quantityForm, quantity: parseInt(e.target.value,10) || 1})} className={"px-1 flex-grow inline " + boolStr("bg-white", "bg-red-300", hasError("quantity", quantityForm.formErrors))}/>}
                                            <input className="flex-grow" id="infinite" name="infinite" type="checkbox" checked={quantityForm.quantity === null} onChange={(e) => setQuantityForm({...quantityForm, quantity: e.target.checked ? null : 1})}/>
                                        </div>
                                    </div>

                                    <div className="flex flex-row justify-between">
                                        <label className="w-1/2" htmlFor="cost">Expense (Each)</label>
                                        <span className="w-1/2 flex flex-row"> 
                                            <label className="px-1" htmlFor="cost">£</label>
                                            <input type="number" min={0} value={Math.floor((quantityForm.cost || 0) / 100).toString()} step={0} onChange={(e) => setQuantityForm({...quantityForm, cost: (parseInt(e.target.value,10)*100)+((quantityForm.cost || 0)%100)})} className={ "w-1/2 flex-grow px-1 " + boolStr("bg-white", "bg-red-300", hasError("cost", quantityForm.formErrors))}/>
                                            <label className="px-1" htmlFor="cost">.</label>
                                            <input type="number" min={0} max={99}  value={quantityForm.cost ? (quantityForm.cost % 100).toString() : 0} onChange={(e) => setQuantityForm({...quantityForm, cost: quantityForm.cost ? (quantityForm.cost - (quantityForm.cost % 100)) + parseInt(e.target.value,10) : 0})} className={ "w-1/2 px-1 " + boolStr("bg-white", "bg-red-300", hasError("cost", quantityForm.formErrors))}/>
                                        </span>
                                    </div>
                                    <div className="flex flex-row justify-between">
                                        <label className="w-1/2" htmlFor="description">Description</label>
                                        <textarea id="description" name="description" value={quantityForm.description} onChange={(e) => setQuantityForm({...quantityForm, description: e.target.value})} className={formClass("description")}/>
                                    </div>
                                    <div className="flex flex-row justify-between">
                                        <label className="w-1/2" htmlFor="purchased_at">Purchased At</label>
                                        <input id="purchased_at" name="purchased_at" type="date" value={quantityForm.purchase_at_input} onChange={(e) => setQuantityForm({...quantityForm, purchase_at_input: e.target.value, purchased_at: new Date(e.target.value).getTime() || Date.now()})} className={formClass("purchased_at")}/>
                                    </div>
                                    <button type="submit" className="btn-accent w-full text-xl py-0.5 !mt-4 rounded-sm">Create Quantity</button>
                                </form>
                            </div>
                            <div className="col-span-3">
                                <div className="p-2 mx-auto w-full rounded-md space-y-1 border-4 border-stone-400 shadow-md text-lg">
                                    <h1 className="text-2xl text-center"> Stock Details </h1>

                                        <div className="flex flex-row justify-between">
                                            <label className="block" htmlFor="sku">SKU</label>
                                            <input className="px-1 bg-white w-1/2" type="text" id="sku" value={selectedStock.sku} readOnly/>
                                        </div>
                                        <div className="flex flex-row justify-between">
                                            <label className="block" htmlFor="name">Name</label>
                                            <input className="px-1 bg-white w-1/2" type="text" id="name" value={selectedStock.name} readOnly/>
                                        </div>
                                        <div className="flex flex-row justify-between">
                                            <label className="block" htmlFor="description">Description</label>
                                            <textarea className="px-1 bg-white w-1/2" id="description" value={selectedStock.description} readOnly/>
                                        </div> 

                                        <div className="flex flex-row justify-between">
                                            <label htmlFor="tags">Tags</label>
                                            <select className="bg-white w-1/2" id="tags" multiple value={selectedStock.tags.flatMap(t=>t._id)} >
                                                {
                                                    selectedStock.tags.map((tag) => (
                                                        <option key={tag._id} value={tag._id}>{tag.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>

                                        <div className="flex flex-row justify-between">
                                            <label className="block" htmlFor="location">Location</label>
                                            <input className="px-1 bg-white w-1/2" type="text" id="location" value={selectedStock.location ? selectedStock.location.name : ""} readOnly/>
                                        </div>
                                        
                                        {selectedStock.type === "StockFrame" && (
                                            <>
                                                <div>
                                                    <div className="flex flex-row justify-between">
                                                        <label className="block" htmlFor="manufacturer">Manufacturer</label>
                                                        <input className="px-1 bg-white w-1/2" type="text" id="manufacturer" value={selectedStock.manufacturer?.name} readOnly/>
                                                    </div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <label className="block" htmlFor="size">Size</label>
                                                    <input className="px-1 bg-white w-1/2" type="text" id="size" value={selectedStock.size} readOnly/>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <label className="block" htmlFor="colour">Colour</label>
                                                    <input className="px-1 bg-white w-1/2 accent-teal-800" type="checkbox" id="colour" checked={!!selectedStock.colour} readOnly/>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <label className="block" htmlFor="is_nhs">NHS</label>
                                                    <input className="px-1 bg-white w-1/2 accent-teal-800" type="checkbox" id="is_nhs" checked={!!selectedStock.is_nhs} readOnly/>
                                                </div>
                                            </>
                                        )}

                                        {selectedStock.type === "StockContacts" && (
                                            <>
                                                <div>
                                                    <div className="flex flex-row justify-between">
                                                        <label className="block" htmlFor="brand">Brand</label>
                                                        <input className="px-1 bg-white w-1/2" type="text" id="brand" value={selectedStock.brand?.name} readOnly/>
                                                    </div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <label className="block" htmlFor="period">Period</label>
                                                    <input className="px-1 bg-white w-1/2" type="text" id="period" value={selectedStock.period} readOnly/>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <label className="block" htmlFor="is_trial">Trial</label>
                                                    <input className="px-1 bg-white w-1/2 accent-teal-800" type="checkbox" id="is_trial" checked={!!selectedStock.is_trial} readOnly/>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <label className="block" htmlFor="is_toric">Toric</label>
                                                    <input className="px-1 bg-white w-1/2 accent-teal-800" type="checkbox" id="is_toric" checked={!!selectedStock.is_toric} readOnly/>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <label className="block" htmlFor="is_multifocal">Multifocal</label>
                                                    <input className="px-1 bg-white w-1/2 accent-teal-800" type="checkbox" id="is_multifocal" checked={!!selectedStock.is_multifocal} readOnly/>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <label className="block" htmlFor="is_coloured">Coloured</label>
                                                    <input className="px-1 bg-white w-1/2 accent-teal-800" type="checkbox" id="is_coloured" checked={!!selectedStock.is_coloured} readOnly/>
                                                </div>
                                            </>)
                                        }
                                </div>
                            </div>
                            <div className="col-span-full">
                                <h1 className="text-2xl text-center">Quantities</h1>
                                <table className="w-full table-fixed ">
                                    <thead className="bg-stone-500 text-lg">
                                        <tr>
                                            <th className="px-1">Quantity</th>
                                            <th className="px-1">Available</th>
                                            <th className="px-1">Cost</th>
                                            <th className="px-1">Description</th>
                                            <th className="px-1">Purchased At</th>
                                        </tr>
                                    </thead>
                                    <tbody className="h-max-96 overflow-y-scroll">
                                    {quantities === "loading" ? 
                                        <tr><td colSpan={5} className="text-center text-stone-400 text-lg">Loading...</td></tr> :
                                        quantities.sort((a,b)=>a.purchased_at>b.purchased_at?1:-1).map((quantity) => (
                                            <tr key={quantity._id} className="bg-stone-300 hover:bg-stone-400 p-2 text-center">
                                                <td>{quantity.quantity === null ? "Unlimited" : quantity.quantity}</td>
                                                <td>{quantity.available === null ? "Unlimited" : quantity.available}</td>
                                                <td>{quantity.cost ? PrettyPence(quantity.cost) : <i className="text-stone-400">N/A</i>}</td>
                                                <td>{quantity.description ? quantity.description : <i className="text-stone-400">No Description</i>}</td>
                                                <td>{format(new Date(quantity.purchased_at), "dd/MM/yyy")}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="grid grid-cols-2 text-xl m-2 font-semibold max-w-72 text-nowrap">
                                    <h2>Total:</h2>
                                    <h3 className="text-right">{quantitySum.total}{quantitySum.is_infinite && "+"}</h3>
                                    <h2 >Available:</h2>
                                    <h3 className="text-right">{quantitySum.available}{quantitySum.is_infinite && "+"}</h3>
                                    <h2>Stock Worth:</h2>
                                    <h3 className="text-right">£{PrettyPence(quantitySum.worth)}</h3>
                                    <i className="text-stone-400 text-sm font-semibold grid-span-full text-nowrap pt-1">+ Indicates unlimited stock</i>
                                </div>
                            </div>
                           
                        </div>
                    </div>

                }
            </div>
        </div>
    )
}

export function AdminStockQuantityLoader(){
    const async_authorised_request = useLoginStore.getState().async_authorised_request

    if (!async_authorised_request) return auth_error

    return Promise.all([
        async_authorised_request("GET", "stock")
    ]).then(([{stocks}]) => {
        return { stocks }
    })
}


export function QuantitiesTotal(quantities: StockQuantityView[]): {is_infinite:boolean, total: number, available: number, worth: number} {
    const total = quantities.reduce((acc, q) => acc + (q.quantity || 0), 0)
    const available = quantities.reduce((acc, q) => acc + (q.available || 0), 0)
    const is_infinite = quantities.some(q => q.available === null)
    const worth = quantities.reduce((acc, q) => acc + (q.cost || 0) * (q.available || 0), 0)
    return {is_infinite, total, available, worth}
}