import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import './index.css'
import Login from './components/login/Login.tsx';
import Error from './components/error/Error.tsx';
import ContextLoader from './components/context/ContextLoader.tsx';
import Diary, { DiaryLoader } from './components/diary/Diary.tsx';
import Reminder, { ReminderLoader } from './components/reminder/Reminder.tsx';
import PatientBalance, { PatientBalanceLoader } from './components/patient/balance/PatientBalance.tsx';
import Index from './components/index/Index.tsx';
import PatientSMS, {SMSLoader as PatientSMSLoader} from './components/patient/SMS/SMS.tsx';
import { ReportIndex } from './components/report/index/ReportIndex.tsx';
import ReportSMS, { ReportSMSLoader } from './components/report/sms/ReportSMS.tsx';
import ReportRecall, { ReportRecallLoader } from './components/report/recall/ReportRecall.tsx';
import { GoogleOAuthProvider } from '@react-oauth/google';
import ReportTransactions from './components/report/transactions/ReportTransactions.tsx';
import { AdminIndex } from './components/admin/index/AdminIndex.tsx';
import { AdminStockStock, AdminStockStockLoader } from './components/admin/stock/stock/AdminStockStock.tsx';
import { AdminStockLocation, AdminStockLocationLoader } from './components/admin/stock/location/AdminStockLocation.tsx';
import { AdminStockManufacturer, AdminStockManufacturerLoader } from './components/admin/stock/manufacturer/AdminStockManufacturer.tsx';
import { AdminStockTag, AdminStockTagLoader } from './components/admin/stock/tag/AdminStockTag.tsx';
import { AdminStockBrand, AdminStockBrandLoader } from './components/admin/stock/brand/AdminStockBrand.tsx';
import { AdminStockQuantity, AdminStockQuantityLoader } from './components/admin/stock/quantity/AdminStockQuantity.tsx';
import { AdminStockPrice, AdminStockPriceLoader } from './components/admin/stock/price/AdminStockPrice.tsx';
import Examination, { ExaminationLoader } from './components/examination/Examination.tsx';
import { PatientHomepageParent, PatientHomepageParentLoader } from './components/patient/homepage/PatientHomepageParent.tsx';
import PatientSearch from './components/patient/search/PatientSearch.tsx';
import PatientImages, { PatientImagesLoader } from './components/patient/images/PatientImages.tsx';
import PatientNotes, { PatientNotesLoader } from './components/patient/note/PatientNotes.tsx';

const router = createBrowserRouter(
  createRoutesFromElements(
    [
      <Route path='/login' element={<Login/>}/>,
      <Route path='' element={<App/>} errorElement={<Error/>} >
        <Route path='/reminders' element={<Reminder/>} loader={ReminderLoader}/>
        
        <Route path='/diary' element={<Diary/>} loader={DiaryLoader}/>
                
        <Route path='/patient/search' element={<PatientSearch/>}/>
        
        <Route path='/patient/:id'>
          <Route path='reminders' element={<Reminder/>} loader={ReminderLoader}/>
          <Route path='notes' element={<PatientNotes/>} loader={PatientNotesLoader} />
          <Route path="balance" element={<PatientBalance/>} loader={PatientBalanceLoader}/>
          <Route path="images" element={<PatientImages/>} loader={PatientImagesLoader}/>
          <Route path="examination"  element={<Examination/>} loader={ExaminationLoader}/>
          <Route path="sms" element={<PatientSMS/>} loader={PatientSMSLoader}/>
          <Route index path='' element={<PatientHomepageParent/>} loader={PatientHomepageParentLoader} />
          <Route path='*' element={<Navigate to='/patient/search'/>} />
        </Route>

        <Route path="/report">
          <Route path='sms' element={<ReportSMS/>} loader={ReportSMSLoader}/>
          <Route path='recall' element={<ReportRecall/>} loader={ReportRecallLoader}/>
          <Route path='transaction' element={<ReportTransactions/>}/>
          <Route index element={<ReportIndex/>} path=''/>
          <Route path='*' element={<Navigate to='/report/'/>}/>
        </Route>

        <Route path="/admin">
          <Route path='stock'>
            <Route path='location' element={<AdminStockLocation/>} loader={AdminStockLocationLoader}/>
            <Route path="tag" element={<AdminStockTag/>} loader={AdminStockTagLoader}/>
            <Route path="manufacturer" element={<AdminStockManufacturer/>} loader={AdminStockManufacturerLoader}/>
            <Route path='item' element={<AdminStockStock/>} loader={AdminStockStockLoader}/>
            <Route path="brand" element={<AdminStockBrand/>} loader={AdminStockBrandLoader}/>
            <Route path="quantity" element={<AdminStockQuantity/>} loader={AdminStockQuantityLoader}/>
            <Route path="price" element={<AdminStockPrice/>} loader={AdminStockPriceLoader}/>
            <Route index path='*' element={<Navigate to='/admin'/>}/>
          </Route>
          <Route index path='' element={<AdminIndex/>}/>
          <Route path='*' element={<Navigate to='/admin/'/>}/>
        </Route>

        <Route index element={<Index/>}/>
        <Route path='*' element={<Navigate to='/'/>} />
      </Route>
    ]
  )
);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ContextLoader/>
      <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
        <RouterProvider router={router}/>
      </GoogleOAuthProvider>
  </React.StrictMode>
)
