import { z } from "zod";
import { docSchema, docViewSchema, zOID, zViewOIDGenerator } from "./document/document";
import { creatableSchema, creatableViewSchema } from "./document/logging/creatable";
import { addressFormSchema, addressViewSchema } from "./address";
import { patientFlagViewSchema } from "./patientFlag";
import { schemeViewSchema } from "./scheme";
import { eventViewSchema } from "./event";
import { deletableSchema, deletableViewSchema } from "./document/logging/deletable";
import { makeFuzzy } from "../utilities/textFuzzy";
import { patientStatusViewSchema } from "./patientStatus";
import {sub} from "date-fns";

export const emailSchema = z.object({
    email: z.string().trim().email("Invalid email address").min(1, "Email is required"),
    is_active: z.boolean().default(true),
})
export type Email = z.infer<typeof emailSchema>;

export const telephoneSchema = z.object({
    number: z.string().trim().min(1, "Number is required"),
    is_landline: z.boolean().default(false),
    label: z.string().trim().optional(),
    is_active: z.boolean().default(true),
})
export type Telephone = z.infer<typeof telephoneSchema>;

export const socialSchema = z.object({
    social: z.string().trim().min(1, "Social is required"),
    platform: z.string().trim().min(1, "Platform is required"),
    is_active: z.boolean().default(true),
})
export type Social = z.infer<typeof socialSchema>;

export const basePatientSchema = z.object({
    patient_id: z.string().trim().min(1, "Patient ID is required"),
    title: z.string().trim().min(1, "Title is required"),
    forename: z.string().trim().min(1, "Forename is required"),
    faux_forename: z.string(),
    surname: z.string().trim().min(1, "Surname is required"),
    faux_surname: z.string(),
    dob: z.number().int("Date of birth is required").refine(d=> d <= sub(Date.now(), {hours:1}).getTime() && d >= sub(Date.now(), {years: 125}).getTime(), "Invalid Date of birth." ),
    status: zOID,
    gender: z.string().trim().min(1, "Gender is required"),
    sex: z.enum(["male","female", "other"]),
    known_as: z.string().trim().nullable(),
    schemes: z.array(zOID).min(1, "Scheme is required"),
    addresses: z.array(zOID).min(1, "Address is required"),
    telephones: z.array(telephoneSchema),
    emails: z.array(emailSchema),
    socials: z.array(socialSchema),
    flags: z.array(zOID),
})

export const patientSchema = basePatientSchema.merge(docSchema)
.merge(creatableSchema)
.merge(deletableSchema)
.transform(p =>{
    return {...p, faux_forename: makeFuzzy(p.forename), faux_surname: makeFuzzy(p.surname)}
})
export type Patient = z.infer<typeof patientSchema>;

export const patientVirtualsSchema = z.object({
    last_appointment: eventViewSchema.nullable(),
    next_appointment: eventViewSchema.nullable(),
    last_recall: eventViewSchema.nullable(),
    next_recall: eventViewSchema.nullable(),
    balance: z.number().optional(),
})
export type PatientVirtuals = z.infer<typeof patientVirtualsSchema>;

export const patientViewSchema = basePatientSchema
.merge(z.object({
    status: patientStatusViewSchema,
    schemes: z.array(schemeViewSchema),
    addresses: z.array(addressViewSchema),
    flags: z.array(patientFlagViewSchema),
}))
.merge(patientVirtualsSchema)
.merge(docViewSchema)
.merge(creatableViewSchema)
.merge(deletableViewSchema)
export type PatientView = z.infer<typeof patientViewSchema>;

export const patientFormSchema = basePatientSchema
.merge(z.object({
    status: zViewOIDGenerator("Invalid Status"),
    schemes: z.array(zViewOIDGenerator("Invalid Scheme")),
    addresses: z.array(addressFormSchema),
    flags: z.array(zViewOIDGenerator("Invalid Flag")),
}))
.merge(docViewSchema.partial())
.merge(creatableViewSchema.partial())
.merge(deletableViewSchema.partial())
.merge(patientVirtualsSchema.partial())

export type PatientForm = z.infer<typeof patientFormSchema>;