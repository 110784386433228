import { z } from "zod";
import { docSchema, docViewSchema } from "./document/document";

export const schemeSchema = z.object({
    name: z.string().trim().min(1, "Name is required").max(64, "Name is too long"),
    description: z.string().trim().nullable(),
}).merge(docSchema);

export type Scheme = z.infer<typeof schemeSchema>;

export const schemeViewSchema = schemeSchema.merge(docViewSchema);
export type SchemeView = z.infer<typeof schemeViewSchema>;