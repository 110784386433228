import { useLoaderData } from "react-router-dom";
import { useLoginStore } from "../../../context/LoginContext";
import { auth_error } from "../../../shared/api";
import { useState } from "react";
import { StockView } from "va_shared/src/schema/stock";
import { StockPriceForm, stockPriceFormSchema, StockPriceView } from "va_shared/src/schema/stockPrice";
import { format } from "date-fns/format";
import { PrettyPence } from "va_shared/src/utilities/Currency";
import { SchemaIssue } from "va_shared/src/schema/error";
import { SchemeView } from "va_shared/src/schema/scheme";
import { boolStr } from "../../../shared/className";
import { hasError } from "../../../shared/Validation";

const DEFAULT_STOCKPRICE: StockPriceForm & {
    formErrors: SchemaIssue[],
    errors: string[],
    end_at_str: string,
    start_at_str: string
} =  {
    stock: "",
    scheme: "",
    price: 0,
    start_at: 0,
    end_at: null,
    formErrors: [],
    errors: [],
    end_at_str: "",
    start_at_str: format(new Date(), "yyyy-MM-dd")
}

export function AdminStockPrice(){
    const async_authorised_request = useLoginStore(state => state.async_authorised_request);
    const context = useLoaderData() as {stocks: StockView[], schemes: SchemeView[]}

    const [form, setForm] = useState(DEFAULT_STOCKPRICE)
    const [request, setRequest] = useState(false)
    const [selectedStock, setSelectedStock] = useState<StockView>()
    const [prices, setPrices] = useState<StockPriceView[] | "loading">("loading")

    const formSubmit = (e: React.FormEvent) => {
        if (request) return
        e.preventDefault()
        
        form.errors = []
        form.formErrors =[]

        if (form.end_at_str){
            const end = new Date(form.end_at_str)
            end.setHours(23,59,59,999)
            form.end_at = end.getTime()
        }else{
            form.end_at = null
        }

        if (form.start_at_str){
            const start = new Date(form.start_at_str)
            start.setHours(0,0,0,0)
            form.start_at = start.getTime()
        }
       
        form.stock = selectedStock?._id || ""

        const {data, error} = stockPriceFormSchema.safeParse(form)
        if (error) {
            console.log(error)
            console.log(form)
            setForm({...form, formErrors: error.errors})
            return
        }

        setRequest(true)
        async_authorised_request("POST", "stock/price", data).then(res => {
            setForm(DEFAULT_STOCKPRICE)
            if (prices === "loading") return setPrices([res.price])
            
            setPrices([...prices, res.price])
        }).catch(err => {
            setForm({...form, errors: [err.message]})
        }).finally(() => setRequest(false))
    }

    const formError = (path: string) => "px-1 w-1/2 " + boolStr( "bg-white", "bg-red-300", hasError(path, form.formErrors))

    return (
        <div className="m-4">
            <h1 className="text-3xl">Stock Price</h1>
            <div className="bg-stone-200 rounded-lg p-4 space-y-4 relative">
                <div>
                    <table className="w-full">
                        <thead className="bg-stone-500 text-lg">
                            <tr>
                                <th>SKU</th>
                                <th>Name</th>
                                <th>Location</th>
                                <th>Size</th>
                                <th>Type</th>
                            </tr>
                        </thead>
                        <tbody className="h-max-96 overflow-y-scroll">
                        {context.stocks.sort((a,b)=>a.name>b.name?1:-1).map((item) => (
                            <tr key={item._id} className={"hover:bg-stone-400 p-2 text-center hover:cursor-pointer  "+(item._id === selectedStock?._id ? "bg-stone-100":"bg-stone-300")} onClick={() => {
                                setSelectedStock(item)
                                setPrices("loading")
                                async_authorised_request("GET", `stock/price/byStock/${item._id}`).then((res) => {
                                    setPrices(res.prices)
                                })}}>
                                <td>{item.sku}</td>
                                <td>{item.name}</td>
                                <td>{item.description ? item.description : <i className="text-stone-400 font-semibold">No Description</i>}</td>
                                <td>{item.location ? item.location.name : <i className="text-stone-400 font-semibold">No Location</i>}</td>
                                <td>{{Stock:"Item", StockFrame:"Frame", StockContacts:"Contacts"}[item.type]}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div>

                {
                    selectedStock &&
                    <div>
                        <h1 className="text-2xl text-center">Stock Details</h1>
                        <div className="grid grid-cols-8 gap-x-2">
                        <div className="col-span-5">
                                <form onSubmit={formSubmit} className="p-2 w-full rounded-md space-y-1 border-4 border-stone-400 shadow-md text-lg">
                                    <h1 className="text-2xl text-center">Create Quantity </h1>
                                    <div className="m-1 pt-1 pb-2">
                                        {form.errors.map((error) => (
                                            <div key={error} className="bg-red-300 text-red-800 text-center rounded-sm">{error}</div>
                                        ))}
                                        {form.formErrors.map((error) => (
                                            <div key={(error.code+error.path.join("."))} className="bg-red-300 text-red-800 text-center rounded-sm">{error.message}</div>
                                        ))}
                                    </div>

                                    <div className="flex flex-row justify-between ">
                                        <label className="text-xl" htmlFor="stock">Stock</label>
                                        <input id="stock" name="stock" value={selectedStock.sku} readOnly className="px-1 w-1/2 bg-white"/>
                                    </div>

                                    <div className="flex flex-row justify-between">
                                        <label className="text-xl" htmlFor="scheme">Scheme</label>
                                        <select id="scheme" name="scheme" value={form.scheme} onChange={(e) => setForm({...form, scheme: e.target.value})} className={formError("scheme")}>
                                            <option value="" hidden>Select Scheme</option>
                                            {context.schemes.map((scheme) => (
                                                <option key={scheme._id} value={scheme._id}>{scheme.name}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="flex flex-row justify-between">
                                        <label className="text-xl" htmlFor="price">Price</label>
                                        <span className="w-1/2 flex flex-row"> 
                                            <label className="px-1" htmlFor="cost">£</label>
                                            <input type="number" min={0} value={Math.floor((form.price || 0) / 100).toString()} step={0} onChange={(e) => setForm({...form, price: (parseInt(e.target.value,10)*100)+((form.price || 0)%100)})} className={ "w-1/2 flex-grow px-1 " + boolStr("bg-white", "bg-red-300", hasError("cost", form.formErrors))}/>
                                            <label className="px-1" htmlFor="cost">.</label>
                                            <input type="number" min={0} max={99}  value={form.price ? (form.price % 100).toString() : 0} onChange={(e) => setForm({...form, price: form.price ? (form.price - (form.price % 100)) + parseInt(e.target.value,10) : 0})} className={ "w-1/2 px-1 " + boolStr("bg-white", "bg-red-300", hasError("cost", form.formErrors))}/>
                                        </span>
                                    </div>

                                    <div className="flex flex-row justify-between">
                                        <label className="text-xl" htmlFor="start_at">Start</label>
                                        <input id="start_at" name="start_at" type="date" value={form.start_at_str} onChange={(e) => setForm({...form, start_at_str: e.target.value})} className={formError("start_at")}/>
                                    </div>

                                    <div className="flex flex-row justify-between">
                                        <label className="text-xl" htmlFor="end_at">End</label>
                                        <input id="end_at" name="end_at" type="date" value={form.end_at_str} onChange={(e) => setForm({...form, end_at_str: e.target.value})} className={formError("end_at")}/>
                                    </div>

                                 
                                    <button disabled={request} type="submit" className="btn-accent w-full text-xl py-0.5 !mt-4 rounded-sm">Create Quantity</button>
                                </form>
                            </div>

                            
                            <div className="col-span-3">
                                <div className="p-2 mx-auto w-full rounded-md space-y-1 border-4 border-stone-400 shadow-md text-lg">
                                    <h1 className="text-2xl text-center"> Stock Details </h1>

                                        <div className="flex flex-row justify-between">
                                            <label className="block" htmlFor="sku">SKU</label>
                                            <input className="px-1 bg-white w-1/2" type="text" id="sku" value={selectedStock.sku} readOnly/>
                                        </div>
                                        <div className="flex flex-row justify-between">
                                            <label className="block" htmlFor="name">Name</label>
                                            <input className="px-1 bg-white w-1/2" type="text" id="name" value={selectedStock.name} readOnly/>
                                        </div>
                                        <div className="flex flex-row justify-between">
                                            <label className="block" htmlFor="description">Description</label>
                                            <textarea className="px-1 bg-white w-1/2" id="description" value={selectedStock.description} readOnly/>
                                        </div> 

                                        <div className="flex flex-row justify-between">
                                            <label htmlFor="tags">Tags</label>
                                            <select className="bg-white w-1/2" id="tags" multiple defaultValue={selectedStock.tags.flatMap(t=>t._id)} >
                                                {
                                                    selectedStock.tags.map((tag) => (
                                                        <option key={tag._id} value={tag._id}>{tag.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>

                                        <div className="flex flex-row justify-between">
                                            <label className="block" htmlFor="location">Location</label>
                                            <input className="px-1 bg-white w-1/2" type="text" id="location" value={selectedStock.location ? selectedStock.location.name : ""} readOnly/>
                                        </div>
                                        
                                        {selectedStock.type === "StockFrame" && (
                                            <>
                                                <div>
                                                    <div className="flex flex-row justify-between">
                                                        <label className="block" htmlFor="manufacturer">Manufacturer</label>
                                                        <input className="px-1 bg-white w-1/2" type="text" id="manufacturer" value={selectedStock.manufacturer?.name} readOnly/>
                                                    </div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <label className="block" htmlFor="size">Size</label>
                                                    <input className="px-1 bg-white w-1/2" type="text" id="size" value={selectedStock.size} readOnly/>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <label className="block" htmlFor="colour">Colour</label>
                                                    <input className="px-1 bg-white w-1/2 accent-teal-800" type="checkbox" id="colour" checked={!!selectedStock.colour} readOnly/>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <label className="block" htmlFor="is_nhs">NHS</label>
                                                    <input className="px-1 bg-white w-1/2 accent-teal-800" type="checkbox" id="is_nhs" checked={!!selectedStock.is_nhs} readOnly/>
                                                </div>
                                            </>
                                        )}

                                        {selectedStock.type === "StockContacts" && (
                                            <>
                                                <div>
                                                    <div className="flex flex-row justify-between">
                                                        <label className="block" htmlFor="brand">Brand</label>
                                                        <input className="px-1 bg-white w-1/2" type="text" id="brand" value={selectedStock.brand?.name} readOnly/>
                                                    </div>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <label className="block" htmlFor="period">Period</label>
                                                    <input className="px-1 bg-white w-1/2" type="text" id="period" value={selectedStock.period} readOnly/>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <label className="block" htmlFor="is_trial">Trial</label>
                                                    <input className="px-1 bg-white w-1/2 accent-teal-800" type="checkbox" id="is_trial" checked={!!selectedStock.is_trial} readOnly/>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <label className="block" htmlFor="is_toric">Toric</label>
                                                    <input className="px-1 bg-white w-1/2 accent-teal-800" type="checkbox" id="is_toric" checked={!!selectedStock.is_toric} readOnly/>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <label className="block" htmlFor="is_multifocal">Multifocal</label>
                                                    <input className="px-1 bg-white w-1/2 accent-teal-800" type="checkbox" id="is_multifocal" checked={!!selectedStock.is_multifocal} readOnly/>
                                                </div>
                                                <div className="flex flex-row justify-between">
                                                    <label className="block" htmlFor="is_coloured">Coloured</label>
                                                    <input className="px-1 bg-white w-1/2 accent-teal-800" type="checkbox" id="is_coloured" checked={!!selectedStock.is_coloured} readOnly/>
                                                </div>
                                            </>)
                                        }
                                </div>
                            </div>
                            <div className="col-span-full">
                                <h1 className="text-2xl text-center">Quantities</h1>
                                <table className="w-full table-fixed mx-auto ">
                                    <thead className="bg-stone-500 text-lg">
                                        <tr>
                                            <th> Start </th>
                                            <th> End </th>
                                            <th> Scheme </th>
                                            <th> Price </th>
                                        </tr>
                                    </thead>
                                    <tbody className="h-max-96 overflow-y-scroll">
                                    {prices === "loading" ? 
                                        <tr key="LOADING"><td/><td className="text-center text-stone-400 text-lg">Loading...</td></tr> :
                                        prices.filter(p => !p.deleted_at).sort((a,b)=>a.start_at-b.start_at).map((price) => (
                                            <tr key={price._id} className="bg-stone-300 hover:bg-stone-400 p-2 text-center">
                                                <td>{format(new Date(price.start_at), "dd/MM/yyyy")}</td>
                                                <td>{price.end_at?format(new Date(price.end_at), "dd/MM/yyyy"): <i className="text-stone-400 font-semibold">Indefinite</i>}</td>
                                                <td>{price.scheme.name}</td>
                                                <td>£{PrettyPence(price.price)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                           
                        </div>
                    </div>

                }
                    
                </div>
            </div>
        </div>
    )
    

}

export function AdminStockPriceLoader(){
    const async_authorised_request = useLoginStore.getState().async_authorised_request;

    if (!async_authorised_request) return auth_error

    return Promise.all([
        async_authorised_request("GET", "stock"),
        async_authorised_request("GET", "scheme")
    ]).then(([{stocks}, {schemes}]) => ({stocks, schemes}))
}