import { useState } from "react"
import useSessionStorageState from "use-session-storage-state"
import { StockPriceQuantity } from "va_shared/src/schema/stockPrice"
import { StockTagView } from "va_shared/src/schema/stockTag"
import noDefault from "../../shared/noDefault"
import { PrettyPence } from "va_shared/src/utilities/Currency"

export interface ICreateContactsProps {
    createTransaction: (price:number, quantity:number, description:string, stock:string, stockPrice:string, left_power: number, right_power: number, left_base_curve: number, right_base_curve: number, left_diameter: number, right_diameter: number) => void,
    currentBalance: number,
    contacts: StockPriceQuantity[]
    errors: string[]
    tags: StockTagView[]
  }
  
const DEFAULT_CONTACT = {
    selectedContact: null,
    description: "",
    quantity: 1,
    price: 0,
    left_power: 0,
    right_power: 0,
    left_base_curve: 0,
    right_base_curve: 0,
    left_diameter: 0,
    right_diameter: 0,
}

export default function CreateContacts ({createTransaction, currentBalance, contacts, errors, tags}: ICreateContactsProps) {
    const [search, setSearch] = useState<{name:string, description:string, tag:string}>({
        name: "",
        description: "",
        tag: ""
    })

    const [contact, setContact] = useSessionStorageState<{
        selectedContact: StockPriceQuantity | null,
        description: string,
        quantity: number,
        price: number,
        left_power: number,
        right_power: number,
        left_base_curve: number,
        right_base_curve: number,
        left_diameter: number,
        right_diameter: number,
    }> ("CreateContact", {
        defaultValue: DEFAULT_CONTACT
    })

    return ( <div onClick={noDefault(() => {})} className='border-2 border-stone-500 bg-stone-200 p-3 rounded-md w-full max-w-lg grid grid-flow-row space-y-2 shadow-md'>

<h1 className='text-3xl text-center'> New Sale </h1>

{errors.length > 0 &&
  <div>
    {errors.map((e) => <p key={e} className="text-red-600 text-lg bg-red-300 px-2 text-center font-semibold">{e}</p>)}
  </div>
}
<label onClick={noDefault(()=>{setContact({...contact, selectedContact:null})})} className='block text-2xl hover:cursor-pointer' htmlFor='Item'>Item: {contact.selectedContact?.stock.name || <p className='inline pb-2 italic text-stone-400'>Select an item</p>}</label>


  {!contact.selectedContact &&  
  <div className='bg-stone-300 rounded-md p-2 space-y-2'>

  <div>
    <div>
      <label className='block' htmlFor='Item'>Name:</label>
      <input className='w-full rounded-sm' type='text' id='Item' value={search.name} onChange={e=>{
        setSearch({...search, name: e.target.value})
      }}/>
    </div>
    
    <div>
      <label className='block' htmlFor='Item'>Description:</label>
      <input className='w-full rounded-sm' type='text' id='Item' value={search.description} onChange={e=>setSearch({...search, description: e.target.value})}/>
    </div>

    <div>
      <label className='block' htmlFor='Item'>Tag:</label>
      <select className='w-full rounded-sm p-1 bg-stone-50' value={search.tag} onChange={e=>setSearch({...search, tag: e.target.value})}>
        <option value="">Select a tag</option>
        {tags.filter(t=>t.is_active).map(tag => <option key={tag._id} value={tag._id}>{tag.name}</option>)}
      </select>
    </div>
  </div>


  <div className='max-h-64 overflow-hidden overflow-y-scroll py-1'>
      {
        contacts.filter(i => ( (!search.description && !search.name && !search.tag) || i.stock.description.toLowerCase().includes(search.description.toLowerCase()) && i.stock.name.toLowerCase().includes(search.name.toLowerCase()) && (!search.tag || i.stock.tags.map(t=>t._id).includes(search.tag) ))).map((item) =>
          <div title={item.stock.description} key={item._id} className='flex flex-row justify-between hover:font-bold px-2 hover:bg-stone-200 hover:cursor-pointer'
            onClick={noDefault(()=>{
              setContact({...contact, price: item.price, selectedContact: item})
            })}
          >
            <span>{item.stock.name}</span>
            <span>£{PrettyPence(item.price)}</span>
          </div>
        )
      }
    </div>
</div>}

    {contact.selectedContact && <div>
      <div>
        <label className='block' htmlFor='Item'>Quantity:</label>
        <div className='flex flex-row justify-between'>
          <input className='w-3/4 rounded-sm px-1' type='number' id='Item' value={contact.quantity} onChange={e=>setContact({...contact, quantity: parseInt(e.target.value)})}/>
          <label className='block mx-2' htmlFor='Item'>/</label>
          <input readOnly className='w-1/4 rounded-sm px-1 font-semibold' value={(contact.selectedContact?.quantities?.reduce((t, q) => t + (q.available || 0), 0) || 0) + ((contact.selectedContact?.quantities?.findIndex(q=>q.available === null) || -1) === -1? "": "*") }></input>
        </div>
      </div>

      <div>
        <label className='block' htmlFor='cost'>Cost:</label>
        <div className='flex flex-row justify-between'>
            <span className="w-3/4 flex flex-row"> 
                <label className="px-1" htmlFor="cost">£</label>
                <input className='px-1 w-1/2 ' type="number" min={0} value={Math.floor(contact.price / 100).toString()} step={0} onChange={(e) => setContact({...contact, price: (parseInt(e.target.value,10) * 100) + (contact.price % 100)})}/>
                <label className="px-1" htmlFor="cost">.</label>
                <input className='px-1 w-1/2' type="number" min={0} max={99}  value={contact.price ? (contact.price % 100).toString() : 0} step={0} onChange={(e) => setContact({...contact, price: Math.floor(contact.price / 100) * 100 + parseInt(e.target.value,10)})} />
            </span>      
            <input readOnly className="px-1 py-0.5 ml-4 rounded-sm font-semibold" value={"£"+PrettyPence(contact.price*contact.quantity)}></input> 
        </div>  
      </div>

        <div>
            <label className='block' htmlFor='description'>Description:</label>       
            <textarea className='w-full rounded-sm px-1' value={contact.description} onChange={e=>setContact({...contact, description: e.target.value})}></textarea>
        </div>

       <div className="grid grid-cols-2 pt-2" >
            <div className="flex flex-col bg-stone-300 rounded-sm p-1">
                <label className='block' htmlFor='power'>Left Power:</label>
                <input className='w-full rounded-sm pr-0.5 pl-1' type='number' value={contact.left_power} onChange={e=>setContact({...contact, left_power: parseFloat(e.target.value)})}/>
                <label className='block' htmlFor='base_curve'>Left Base Curve:</label>
                <input className='w-full rounded-sm pr-0.5 pl-1' type='number' value={contact.left_base_curve} onChange={e=>setContact({...contact, left_base_curve: parseFloat(e.target.value)})}/>
                <label className='block' htmlFor='diameter'>Left Diameter:</label>
                <input className='w-full rounded-sm pr-0.5 pl-1' type='number' value={contact.left_diameter} onChange={e=>setContact({...contact, left_diameter: parseFloat(e.target.value)})}/>
            </div>
            <div className="flex flex-col bg-stone-300 rounded-sm p-1">
                <label className='block' htmlFor='power'>Right Power:</label>
                <input className='w-full rounded-sm pr-0.5 pl-1' type='number' value={contact.right_power} onChange={e=>setContact({...contact, right_power: parseFloat(e.target.value)})}/>
                <label className='block' htmlFor='base_curve'>Right Base Curve:</label>
                <input className='w-full rounded-sm pr-0.5 pl-1' type='number' value={contact.right_base_curve} onChange={e=>setContact({...contact, right_base_curve: parseFloat(e.target.value)})}/>
                <label className='block' htmlFor='diameter'>Right Diameter:</label>
                <input className='w-full rounded-sm pr-0.5 pl-1' type='number' value={contact.right_diameter} onChange={e=>setContact({...contact, right_diameter: parseFloat(e.target.value)})}/>
            </div>
        </div>
      

        <div className='flex flex-row  justify-between'>
          <div>
            <label className='block' htmlFor='balance'>Current Balance:</label>
            <input readOnly className={"px-1 py-0.5 rounded-sm font-semibold"+((currentBalance<0)?" bg-red-200":"")} value={PrettyPence(currentBalance, true)}></input>
          </div>

          <div>
            <label className='block' htmlFor='balance'>Future Balance:</label>
            <input readOnly className={"px-1 py-0.5 rounded-sm font-semibold"+((currentBalance-contact.price*contact.quantity<0)?" bg-red-300":"")} value={PrettyPence(currentBalance-contact.price*contact.quantity, true)}></input>
          </div>
        </div>


        <button disabled={!contact.selectedContact} onClick={noDefault(()=>{
          if (!contact.selectedContact) return
          createTransaction(contact.price, contact.quantity, contact.description, contact.selectedContact.stock._id, contact.selectedContact._id, contact.left_power, contact.right_power, contact.left_base_curve, contact.right_base_curve, contact.left_diameter, contact.right_diameter)
        })} className='disabled:bg-stone-400 bg-stone-500 hover:bg-stone-700 text-white font-semi-bold py-1 px-2 mx-auto mt-4 mb-1 w-full rounded-sm'>Create</button>
</div>}

    </div>)
}