import {z} from "zod";
import { Timestamp, zOID, zViewOID } from "../document";

export const logAccountSchema = z.object({
    _id: zOID,
    username: z.string().optional(),
    forename: z.string().optional(),
    surname: z.string().optional(),
    email: z.string().optional(),
    is_active: z.boolean().optional(),
    created_at: Timestamp,
    created_by: zOID,
    deleted_at: Timestamp.optional(),
    deleted_by: zOID.optional(),
})
export type LogAccount = z.infer<typeof logAccountSchema>;

export const logAccountSchemaView = logAccountSchema.merge(z.object({
    _id: zViewOID,
    created_by: zViewOID.optional(),
    deleted_by: zViewOID.optional(),
}))
export type LogAccountView = z.infer<typeof logAccountSchemaView>;

