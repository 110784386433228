import { useLoaderData } from "react-router-dom";
import CheckBox from "../../patient/homepage/CheckBox";
import { useLoginStore } from "../../context/LoginContext";
import { useEffect, useState } from "react";
import { format, startOfDay } from "date-fns";
import noDefault from "../../shared/noDefault";
import { StretchedAction } from "../../shared/StretchedLink";
import ProcessModal from "./ProcessModal";

const DEFAULT_FILTER = {getting_all:false, process_modal:false, flags:[], last_visit:"", has_appointment:"", SMS_count:5, SMS_since:"", SMS_apply:false, verbal_apply:false, letter_apply:false, verbal_count:5, verbal_since:"", letter_count:5, letter_since:""}
type IPatientData = any&{ last_visit:any[], future_appointment:any[], SMS:any[], selected:boolean}

function ReportRecall() {
  const aarequest = useLoginStore((state) => state.async_authorised_request)

  const data = useLoaderData() as any
  const flags: any[] = data.flags

  const [filter, setFilter] = useState(DEFAULT_FILTER)
  const [patients, setPatients] = useState<IPatientData[]>([])

  useEffect(() => {
    if (!aarequest || filter.getting_all || filter.process_modal) return
    const query = new URLSearchParams();

    const last_visit = new Date(filter.last_visit).getTime();
    if (!isNaN(last_visit)){
      query.append("last_visit", startOfDay(last_visit).getTime().toString())
    }

    if (filter.SMS_apply){
      const SMS_since = new Date (filter.SMS_since).getTime();
      if (!isNaN(SMS_since)){
        query.append("SMS_since", startOfDay(SMS_since).getTime().toString())
      }

      query.append("SMS_count", filter.SMS_count.toString())
    }

    for (const f of filter.flags){
      query.append("flag[]", f)
    }

    if (filter.has_appointment){
      query.append("future_appointment", filter.has_appointment === "true" ? "1" : "0")
    }

    const search = query.toString()
    if (search){
      aarequest("GET", `recall/manual/patients?${search}`, undefined).then((response) => {
        setPatients(response.patients.map((p:any) => {return {...p, selected:true}}))
      })
    }
  }, [filter])

  return (
    <div className="m-2 p-1 bg-stone-300 rounded-sm space-y-1">
        <h1 className="text-3xl m-2 font-semibold text-stone-900">Recalls</h1> 
        <form className="flex flex-row space-x-2">
          <div>
            <label htmlFor="Flag">Flag</label>
            <CheckBox editable={true} setState={(nf:any) => setFilter({...filter, flags:nf})} getState={filter.flags} options={flags.map((f:any) => {return {value:f._id, key:f.name}})}/>
          </div>
          <div className="flex flex-col">
            <label htmlFor="LastVisit">Last Appointment</label>
            <input title="before" type="date" name="LastVisit" id="LastVisit" value={filter.last_visit} onChange={e => {
              if (e.target.valueAsDate && e.target.valueAsDate > new Date()){
                setFilter({...filter, last_visit:format(new Date(), "yyyy-MM-dd")})
              }else{
                setFilter({...filter, last_visit:e.target.value})}
              }
            } max={format(new Date(), "yyyy-MM-dd")} />
          </div>
          <div className="flex flex-col">
            <label htmlFor="HasApp">Future Appointment</label>
            <select name="HasApp" id="HasApp" value={filter.has_appointment} onChange={e => setFilter({...filter, has_appointment:e.target.value})}>
              <option value=""> N/A </option>
              <option value="true"> Yes </option>
              <option value="false"> No </option>
            </select>
          </div>
          <div className="flex flex-col w-36">
            <span><label htmlFor="SMS_apply">SMS</label> <input type="checkbox" name="SMS_apply" id="SMS_apply" checked={filter.SMS_apply} onChange={e => {
                setFilter({...filter, SMS_apply:e.target.checked})
            }} /></span>
            {filter.SMS_apply && <>
            <input type="date" name="SMS" id="SMS" value={filter.SMS_since} onChange={e =>{
               if (e.target.valueAsDate && e.target.valueAsDate > new Date()){
                setFilter({...filter, SMS_since:format(new Date(), "yyyy-MM-dd")})
               }else{
                setFilter({...filter, SMS_since:e.target.value})}
               }
              } max={format(new Date(), "yyyy-MM-dd")} />
            <span className="flex flex-row">
              <input className="w-28" type="range" name="SMS_count" id="SMS_count" value={filter.SMS_count} onChange={e => setFilter({...filter, SMS_count:parseInt(e.target.value)})} min={0} max={10} /><label className="mx-auto">{filter.SMS_count}</label>
            </span></>}
          </div>
          <div className="flex flex-col w-36">
            <span><label htmlFor="Verbal_apply">Verbal</label> <input type="checkbox" name="Verbal_apply" id="Verbal_apply" checked={filter.verbal_apply} onChange={e => setFilter({...filter, verbal_apply:e.target.checked})} /></span>
            { filter.verbal_apply && <>
              <input type="date" name="Verbal" id="Verbal" value={filter.verbal_since} onChange={e => setFilter({...filter, verbal_since:e.target.value})} max={format(new Date(), "yyyy-MM-dd")} />
              <span>
                <input className="w-28" type="range" name="Verbal_count" id="Verbal_count" value={filter.verbal_count} onChange={e => setFilter({...filter, verbal_count:parseInt(e.target.value)})} min={0} max={10} /> <label className="mx-auto">{filter.verbal_count}</label>
              </span></>
            }
          </div>
          <div className="flex flex-col">
            <span><label htmlFor="Letter_apply">Letter</label> <input type="checkbox" name="Letter_apply" id="Letter_apply" checked={filter.letter_apply} onChange={e => setFilter({...filter, letter_apply:e.target.checked})} /></span>
            { filter.letter_apply && <>
              <input type="date" name="Letter" id="Letter" value={filter.letter_since} onChange={e => setFilter({...filter, letter_since:e.target.value})} max={format(new Date(), "yyyy-MM-dd")} />
              <span>
                <input className="w-28" type="range" name="Letter_count" id="Letter_count" value={filter.letter_count} onChange={e => setFilter({...filter, letter_count:parseInt(e.target.value)})} min={0} max={10} /> <label className="mx-auto">{filter.letter_count}</label>
              </span></>
            }
          </div>
        </form>
        <div className="flex flex-row relative">
        <button className="m-1 px-1 py-0.5 rounded-sm bg-stone-400 hover:bg-stone-500 font-semibold" onClick={noDefault(() => {setFilter(DEFAULT_FILTER)})}> Clear </button>
        <button className="m-1 px-1 py-0.5 rounded-sm bg-stone-400 hover:bg-stone-500 font-semibold" onClick={noDefault(() => {
          setFilter({...DEFAULT_FILTER, getting_all:true})
          aarequest("GET", "recall/manual/patients", undefined).then((response) => {
            setPatients(response.patients.map((p:any) => {return {...p, selected:true}}))
          }).finally(
            () => setFilter({...DEFAULT_FILTER, getting_all:false})
          )
        })} disabled={filter.getting_all}> Get All </button>
        <button disabled={patients.every(p=>!p.selected)} onClick={noDefault(() => {setFilter({...filter, process_modal:true})})} className="m-1 px-2 py-1 text-2xl rounded-sm disabled:bg-slate-400 bg-teal-500 hover:bg-teal-600 font-semibold absolute bottom-0 right-0" > Process </button>
        </div>
        <hr className="border-stone-400 border"/>   
        <div>
          <table className="w-full table-fixed text-center">
            <thead>
              <tr>
                <th>Patient ID</th>
                <th>Patient</th>
                <th>Last Visit</th>
                <th>Next Appointment</th>
                <th className="w-14">SMS</th>
                <th className="w-14">Verbal</th>
                <th className="w-14">Letter</th>
                <th className="w-14">Email</th>
                <th className="w-14 text-right px-1.5" onClick={noDefault(() => {
                  if (patients.reduce((c, p) => c + (+p.selected),0) > patients.length/2){
                    setPatients(patients.flatMap((p) => {return {...p, selected:false}}))
                  }else{
                    setPatients(patients.flatMap((p) => {return {...p, selected:true}}))
                  }
                })}>✓</th>
              </tr>
            </thead>
            <tbody>
              {patients.map((p:any) => {
                return <tr key={p._id} className="relative p-0.5 hover:bg-stone-200 hover:rounded-lg" onDoubleClick={noDefault(()=>{
                  console.log(p)
                })}>
                  <td>{p.patient_id}</td>
                  <td>{p.forename} {p.surname}</td>
                  <td>{p.last_visit.length === 1 ? format(new Date(p.last_visit[0].start), "dd/MM/yy hh:mm") : "N/A"}</td>
                  <td>{p.future_appointment.length === 1 ? format(new Date(p.future_appointment[0].start), "dd/MM/yy hh:mm") : "N/A"}</td>
                  <td>{p.SMS.length}</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td> {<StretchedAction onclick={() => {
                    setPatients(patients.flatMap( (pat:IPatientData) => p._id === pat._id ? [{...pat, selected:!pat.selected}] : pat))
                  }} children={<div className="absolute top-0 right-1"><input type="checkbox" className="my-auto h-full"  checked={p.selected} readOnly/></div>}></StretchedAction>}</td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
        {filter.process_modal && <ProcessModal aarequest={aarequest} patients={patients.filter((p) => p.selected).map((p:any) => {return {reference:p.patient_id, id:p._id, forename:p.forename, surname:p.surname}})} callback={() => setFilter({...filter, process_modal:false})}/>}
    </div>
  );
}

export function ReportRecallLoader(){
    const aarequest = useLoginStore.getState().async_authorised_request

    if (!aarequest){
      return {status: 402, error: "Not logged in"}
    }

    return aarequest("GET", "program/flag", undefined).then((response) => {
        return response
    })
}

export default ReportRecall;


