import { z } from "zod";
import { docSchema, docViewSchema, zOID, zViewOID, zViewOIDGenerator } from "./document/document";
import { stockViewSchema } from "./stock";
import { isValidPence } from "../utilities/Currency";

export const stockQuantitySchema = z.object({
    stock: zOID,
    quantity: z.number({message:"Invalid Quantity"}).int("Invalid Quantity").min(1, "Quantity is required").nullable(), // null means unlimited, default is quantity
    available: z.number({message:"Invalid Availability"}).int("Invalid Quantity").min(0, "Available must be positive").nullable(), // null means unlimited, default is quantity
    cost: z.number({message:"Invalid Cost"}).min(0, "Cost is required").refine(isValidPence, "Invalid Cost"),
    description: z.string().trim().max(256, "Description is too long"),
    purchased_at: z.number({message:"Invalid Purchase Date"}).int("Invalid Purchase Date"),
})
.merge(docSchema)
export type StockQuantity = z.infer<typeof stockQuantitySchema>;

export const stockQuantityViewSchema = stockQuantitySchema
.merge(z.object({
    stock: stockViewSchema,
}))
.merge(docViewSchema)
export type StockQuantityView = z.infer<typeof stockQuantityViewSchema>;

export const stockQuantityFormSchema = stockQuantitySchema.merge(z.object({
    stock: zViewOIDGenerator("Invalid Stock"),
    _id: zViewOID.optional(),
}))
export type StockQuantityForm = z.infer<typeof stockQuantityFormSchema>;