import { z } from "zod";
import { docSchema, docViewSchema } from "./document/document";

export const stockLocationGroupSchema = z.object({
    name: z.string().trim().min(1, "Name is required").max(64, "Name is too long"),
    description: z.string().trim().max(256, "Description is too long"),
    is_active: z.boolean().default(true)
}).merge(docSchema)
export type StockLocationGroup = z.infer<typeof stockLocationGroupSchema>;

export const stockLocationGroupViewSchema = stockLocationGroupSchema.merge(docViewSchema)
export type StockLocationGroupView = z.infer<typeof stockLocationGroupViewSchema>;

export const stockLocationGroupFormSchema = stockLocationGroupSchema
.merge(z.object({
    _id: z.string().trim().length(24, "Invalid ID").optional(),
}))
export type StockLocationGroupForm = z.infer<typeof stockLocationGroupFormSchema>;