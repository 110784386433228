import { Params, useLoaderData } from "react-router-dom";
import { useLoginStore } from "../../context/LoginContext";
import { useEffect, useState } from "react";
import { useNavbarStore } from "../../context/NavbarContext";
import { GetPatientNav } from "../../patient/homepage/patientNav";
import CreateTransaction from "./CreateTransaction";
import noDefault from "../../shared/noDefault";
import { format } from "date-fns";
import { StretchedAction } from "../../shared/StretchedLink";
import ViewTransaction from "./ViewTransaction";
import CreateSale from "./CreateSale";
import { FocusModal } from "../../shared/Modals";
import {PatientView} from "va_shared/src/schema/patient";
import {SchemeView} from "va_shared/src/schema/scheme";
import { PrettyPence } from "va_shared/src/utilities/Currency";
import { TransactionAny, transactionTypesMap } from "va_shared/src/schema/transaction";
import { ChargeTypes, GetTypeSign } from "va_shared/src/models/transaction";
import { StockTagView } from "va_shared/src/schema/stockTag";
import { StockPriceQuantity } from "va_shared/src/schema/stockPrice";
import CreateContacts from "./CreateContacts";
import { CreateSpectacles } from "./CreateSpectacles";


export default function PatientBalance () {
    const context = useLoaderData() as {patient: PatientView, transactions: TransactionAny[], tags: StockTagView[]};
    const [tracs, setTracs] = useState<TransactionAny[]>(context.transactions);
    const async_authorised_request = useLoginStore(state => state.async_authorised_request);
    const setContent = useNavbarStore(state => state.setContent);
    const [createTransaction, setCreateTransaction] = useState<Partial<TransactionAny> | null>();
    const [modal, setModal] = useState<"" | "Contact" | "Frame" | "Sale">("");
    const [selectedTransaction, setSelectedTransaction] = useState<string | null>(null);
    const [viewTransaction, setViewTransaction] = useState<TransactionAny | null>(null);

    const [priceList, setPriceList] = useState<SchemeView>(context.patient.schemes[0]);
    const [stocks, setStocks] = useState<StockPriceQuantity[] | "loading">("loading");

    const [request, setRequest] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(()=> {
        if (!priceList._id) return;

        setStocks("loading")
        async_authorised_request("GET", "stock/price/byScheme/"+priceList._id).then((res) => {
            setStocks(res.stocks);
        }).catch((res) => {
            console.log(res)
        })

    }, [priceList])

    useEffect(() => {   
        if (context.patient && context.patient._id){
            setContent(GetPatientNav(context.patient._id, "Balance"));
        }
        return () => {
            setContent([]);
        }
    }, [context.patient]);

    useEffect(() => {        
        setStocks("loading")
        async_authorised_request("GET", `stock/price/byScheme/${priceList._id}`)
        .then((res) => {
            setStocks(res.stocks);
        }).catch((res) => {
            console.log(res)
            setStocks(res.error)
        })
    }, [priceList])
    
    let balance:number[] = []
    let runningTotal:number = 0
    for (let i = tracs.length-1; i >=0 ; i--){
        runningTotal += tracs[i].amount * GetTypeSign(tracs[i].type)
        balance.push(runningTotal)
    }
    balance.reverse()

    const currentBalance = tracs.reduce((total, action) => total + action.amount*GetTypeSign(action.type), 0)

    return (
        <div>            
            <h1 className="text-3xl mb-2">{context.patient.forename} {context.patient.surname} - Balance</h1>

            <div className="flex flex-col w-11/12 mx-auto items-center max-w-screen-lg p-1.5 bg-stone-300 rounded">

                <h2 className="text-2xl mb-4 pt-1 relative flex items-center">Current Balance: <span className={currentBalance !== 0 && (currentBalance > 0 ? "translate-y-0.5 mx-1 rounded-md bg-emerald-400 px-2 py-0.5" : "translate-y-0.5 mx-1 rounded-md bg-red-400 px-2 py-0.5") || "p-2"}>{PrettyPence(currentBalance, true)}</span></h2>
                <div className="mb-1">
                    <div className="float-right gap-x-2 flex flex-row">

                        <select disabled={stocks === "loading"} className="bg-stone-200  font-semi-bold py-1 px-2 rounded-sm" value={priceList._id} onChange={(e) => setPriceList(context.patient.schemes.find(s => s._id === e.target.value) as SchemeView)}>
                            {context.patient.schemes.map(scheme => <option key={scheme._id} value={scheme._id}>{scheme.name}</option>)}
                        </select>

                        <button disabled={stocks.length===0} onClick={noDefault(()=>{setModal("Sale"); setSelectedTransaction(null); setCreateTransaction(null)})} className="bg-teal-600 disabled:bg-teal-500 hover:bg-teal-800 text-white font-semi-bold py-1 px-2 rounded-sm">Sale</button>
                        <button onClick={noDefault(()=>{setModal("Contact"); setSelectedTransaction(null); setCreateTransaction(null)})} className="bg-teal-600 hover:bg-teal-800 text-white font-semi-bold py-1 px-2 rounded-sm">Contacts</button>
                        <button onClick={noDefault(()=>{setModal("Frame"); setSelectedTransaction(null); setCreateTransaction(null)})} className="bg-teal-600 hover:bg-teal-800 text-white font-semi-bold py-1 px-2 rounded-sm">Frame</button>
                          
                        <div className="rounded-full bg-stone-700 p-0.5 my-auto h-min"></div> 
                        
                        <button onClick={noDefault(()=>{setSelectedTransaction(null);setCreateTransaction({type:"Payment"})})} className="bg-stone-500 hover:bg-stone-700 text-white font-semi-bold py-1 px-2 rounded-sm">Payment</button>
                        <button onClick={noDefault(()=>setSelectedTransaction(selectedTransaction?null:"Refund"))} className="bg-stone-500 hover:bg-stone-700 text-white font-semi-bold py-1 px-2 rounded-sm">{selectedTransaction==="Refund"&&"Cancel"} Refund</button>
                        <button onClick={noDefault(()=>{setSelectedTransaction(null);setCreateTransaction({type:"Charge"})})} className="bg-stone-500 hover:bg-stone-700 text-white font-semi-bold py-1 px-2 rounded-sm">Charge</button>
                        <button onClick={noDefault(()=>{setSelectedTransaction(null);setCreateTransaction({type:"Credit"})})} className="bg-stone-500 hover:bg-stone-700 text-white font-semi-bold py-1 px-2 rounded-sm">Credit</button>
                        <button onClick={noDefault(()=>setSelectedTransaction(selectedTransaction?null:"Discount"))} className="bg-stone-500 hover:bg-stone-700 text-white font-semi-bold py-1 px-2 rounded-sm">{selectedTransaction==="Discount"&&"Cancel"} Discount</button>
                        <button onClick={noDefault(()=>setSelectedTransaction(selectedTransaction?null:"Write-Off"))} className="bg-stone-500 hover:bg-stone-700 text-white font-semi-bold py-1 px-2 rounded-sm">{selectedTransaction==="Write-Off"&&"Cancel"} Write Off</button>
                    </div>
                </div>
         
                
                <table className="w-full divide-y divide-gray-200 text-center table-fixed">
                    <thead>
                        <tr className="w-full">
                            <th className="px-6 w-1/6 py-3 font-semibold uppercase tracking-wider">Type</th>
                            <th className="px-6 w-1/6 py-3 font-semibold uppercase tracking-wider">Date</th>
                            <th className="px-6 w-1/6 md:w-3/6 py-3 font-semibold uppercase tracking-wider">Description</th>
                            <th className="px-6 w-1/6 py-3 font-semibold uppercase tracking-wider whitespace-nowrap">Amount (£)</th>
                            <th className="px-6 w-1/6 py-3 font-semibold uppercase tracking-wider whitespace-nowrap">{selectedTransaction !== null ? "Select" : "Balance (£)"}</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {tracs.filter(t => 
                            selectedTransaction === null || 
                            (t.type==="Payment" && selectedTransaction==="Refund") ||
                            (ChargeTypes.includes(t.type) && selectedTransaction==="Discount") ||
                            (ChargeTypes.includes(t.type) && selectedTransaction==="Write-Off")
                        ).map((transaction, index) => {
                            return (
                                <tr key={transaction._id} className="relative hover:cursor-pointer hover:bg-gray-100" onClick={selectedTransaction === null? noDefault(()=>setViewTransaction(transaction)):undefined}>
                                    <td>{transactionTypesMap[transaction.type]}</td>
                                    <td>{format(transaction.created_at, "dd/MM/yy HH:mm")}</td>
                                    <td className="overflow-ellipsis overflow-hidden text-nowrap">{transaction.description||<i className="text-stone-400 font-semibold">No Description</i>}</td>
                                    <td className="pr-4 text-right">{PrettyPence(transaction.amount)}</td>
                                    {selectedTransaction !== null ? <td><StretchedAction onclick={noDefault(()=>{setCreateTransaction({...transaction as any, type:selectedTransaction as any, })})}>Select</StretchedAction></td>:<td className="pr-4 text-right">{PrettyPence(balance[index], true)}</td>}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {createTransaction?.type && <div onClick={noDefault(()=>setCreateTransaction(null))} className="fixed top-0 left-0 bg-white bg-opacity-40 w-full h-screen overflow-clip flex items-center justify-center shadow-inner">
                    <CreateTransaction 
                        async_authorised_request={async_authorised_request}
                        selectedTransaction={createTransaction._id ? createTransaction as any : undefined} 
                        currentBalance={tracs.reduce((total, action) => total + action.amount*GetTypeSign(action.type), 0)} 
                        patient={context.patient} type={createTransaction.type as any} 
                        createTransaction={(newTrac)=>{setTracs([newTrac, ...tracs.map(t => {
                            if (newTrac.transaction){
                                if (t._id === newTrac.transaction as any){
                                    return {...t, modifiers:[...(t.modifiers||[]), newTrac]};
                                }else{
                                    return t;
                                }
                            }else{
                                return t;
                            }
                        })
                    ]); setCreateTransaction(null); setSelectedTransaction(null)}} />
                </div>}
                
                {viewTransaction && 
                    <div 
                        onClick={noDefault(()=>setViewTransaction(null))} 
                        className="fixed top-0 left-0 w-full h-screen overflow-clip flex items-center justify-center bg-white bg-opacity-40 ">
                    <ViewTransaction 
                        transaction={viewTransaction} 
                        // delete_call={() => {
                        //     async_authorised_request("DELETE", `transaction/${viewTransaction._id}`).then(() => {
                        //         setTracs(tracs.filter(t => t._id !== viewTransaction._id))
                        //         setViewTransaction(null)
                        //     }).catch((res) => {
                        //         console.log(res)
                        //     })
                        // }}
                        parent={viewTransaction._id?tracs.find(t => t._id === viewTransaction._id):undefined} 
                    />
                </div>}
                <FocusModal is_loading={stocks === "loading" || request} is_visible={modal === "Sale"} onOutsideClick={()=>{if (!request) {
                    setModal("")
                    setErrors([])
                    }}}>
                    <CreateSale 
                        createTransaction={(amount, quantity, description, stock, stockPrice)=>{
                            setErrors([])
                            setRequest(true)
                            async_authorised_request("POST", "transaction/", {
                                patient: context.patient._id,
                                type: "TransactionStock",
                                amount,
                                description,
                                stock,
                                quantity,
                                stockPrice
                            }).then((res) => {
                                setTracs([res.transaction, ...tracs])
                                setModal("")
                                sessionStorage.removeItem("CreateSaleSelectedItem")
                                sessionStorage.removeItem("CreateSalePrice")
                                sessionStorage.removeItem("CreateSaleDescription")
                                sessionStorage.removeItem("CreateSaleQuantity")
                            }).catch((res) => {
                                console.log(res)
                                setErrors([res.error])
                            }).finally(() => {
                                setRequest(false)
                            })
                        }} 
                        currentBalance={currentBalance} 
                        saleItems={stocks !== "loading" ? stocks.filter(s => s.stock.type === "Stock") : []}
                        tags={context.tags}
                        errors={errors} 
                        />
                </FocusModal>
                <FocusModal is_loading={stocks === "loading" || request} is_visible={modal === "Contact"} onOutsideClick={()=>{if (!request) {
                    setModal("")
                    setErrors([])
                    }}
                }>
                    <CreateContacts
                        contacts={stocks !== "loading" ? stocks.filter(s => s.stock.type === "StockContacts") : []}
                        currentBalance={currentBalance}
                        errors={errors}
                        tags={context.tags}
                        createTransaction={(amount, quantity, description, contacts, stockPrice, left_power, right_power, left_base_curve, right_base_curve, left_diameter, right_diameter)=>{
                            setErrors([])
                            setRequest(true)
                            async_authorised_request("POST", "transaction/", {
                                patient: context.patient._id,
                                type: "TransactionContacts",
                                amount,
                                description,
                                contacts,
                                quantity,
                                stockPrice,
                                left_power,
                                right_power,
                                left_base_curve,
                                right_base_curve,
                                left_diameter,
                                right_diameter
                            }).then((res) => {
                                setTracs([res.transaction, ...tracs])
                                setModal("")
                                sessionStorage.removeItem("CreateContact")
                            }).catch((res) => {
                                console.log(res)
                                setErrors([res.error])
                            }).finally(() => {
                                setRequest(false)
                            })
                        }}
                    />
                </FocusModal>

                <FocusModal is_loading={stocks === "loading" || request} is_visible={modal === "Frame"} onOutsideClick={()=>{if (!request) {
                    setModal("")
                    setErrors([])
                    }
                }}>
                    <CreateSpectacles/>
                </FocusModal>
            </div>
        </div>
    );
}

export function PatientBalanceLoader({params}: {params:Params<string>}){
    const async_authorised_request = useLoginStore.getState().async_authorised_request;

    if (!async_authorised_request){
        console.log("Not logged in")
        return {status:401, error: "Not logged in"};
    }

    return Promise.all([
        async_authorised_request("GET", `patient/${params.id}`),
        async_authorised_request("GET", `patient/transaction/${params.id}`),
        async_authorised_request("GET", `stock/tag`)
    ]).then(([{patient}, {transactions}, {tags}]) => {
        return {patient, transactions, tags};
    })
}



// const contactItems = [
//     {name: "Dailies Aqua Comfort Plus", price: 3750},
//     {name: "Toric Dailies Aqua CP", price: 5000},
//     {name: "Aqua CP Multifocal", price: 6000},
//     {name: "Total 1 Daily", price: 5000},
//     {name: "Total 1 Astigmatism", price: 6000},
//     {name: "Total 1 Daily Multifocal", price: 6500},
//     {name: "Precision 1 Daily", price: 4000},
//     {name: "Precision 1 Daily Astig", price: 5000},
//     {name: "Air Optix Mutli 3 Months", price: 8385},
//     {name: "Air Optix N&D 3 Months", price: 8985},
//     {name: "Air Optix Colours 2 Pack", price: 2495},
//     {name: "Freshlook 1 Day Col (10 Pack)", price: 1800},
//     {name: "Biomedics UV55 3 Months", price:4785},
//     {name: "Biomedics Toric 3 Months", price: 5985},
//     {name: "1 Day Acuvue Moist J&J", price: 4000},
//     {name: "1 Day Acuvue Moist Astig", price: 5000},
//     {name: "1 Day Acuvue Moist Multifocal", price: 6000},
//     {name: "Acuvue Oasys 1 Day", price: 5000},
//     {name: "Acuvue Oasys 1 Day Astig", price: 6000},
//     {name: "Acuvue Oasys 1 Daymax Multifocal", price: 6000},
//     {name: "Clariti 1 Day", price: 4000},
//     {name: "Clariti 1 Day Toric", price: 5000},
//     {name: "Pro Clear 3 Months", price:5385},
//     {name: "Pro Clear Toric 3 Months", price: 5985},
//     {name: "Pro Clear Toric XR 3 Months", price: 7485},
//     {name: "My Day Daily", price: 4500},
//     {name: "My Day Daily Toric", price: 5500}, 
//     {name: "Diomedics 1 Day", price: 3000},
//     {name: "Diomedics 1 Day Toric", price: 5000},
//     {name: "Purevision 2 (6 Pack)", price:8995},
//     {name: "Biotrue 1 Day", price: 4500},
//     {name: "Biotrue 1 Day Astigmatism", price: 6000},
//     {name: "Biotrue 1 Day Presbyopia", price: 6500},
//     {name: "Pure Vision 2 Astig (6 Pack)", price:10900},
//     {name: "GP Lenses", price: 18500},
//     {name: "GP Toric - Made to Order", price: 30000},
//     {name: "Durable Soft Lenses", price: 12000}
//   ]
    
// const saleItems = [
//     {name: "Eye Examination", price: 3000},
//     {name: "Tint + UV", price: 1500},
//     {name: "NHS Sight Test", price:2353},
//     {name: "Hardcoat (Antiscratch)", price: 1500},
//     {name: "Contact Lens Consultation", price: 3000},
//     {name: "MAR Coating", price: 3000},
//     {name: "Mirror Tint", price: 3000},
//     {name: "cut, Edge, Fit", price:1500},
//     {name: "Repair", price: 0},
//     {name: "Transition", price: 3000},
//     {name: "Dispensing Fee", price: 1552},
//     {name: "Solder", price: 1000},
//     {name: "UV Filter", price: 1500},
//     {name: "DVLA Field Test", price: 4000},
//     {name: "Repair - No Lab Order", price:0},
//     {name: "Eyecare Voucher", price:1995},
//     {name: "Domiciliary Vist", price: 3904}
// ]

// const AccessoriesItems = [
//     {name: "Hilco Holders - Cord", price:199},
//     {name: "Hilco Holders - Chain", price: 299},
//     {name: "Hilco Holders - Snake", price: 399},
//     {name: "Hilco Holders - Chainette", price: 599},
//     {name: "Cleaner", price: 299},
//     {name: "Magnifier (65mm)", price: 495},
//     {name: "Magnifier (75mm)", price: 695},
//     {name: "Magnifier (90mm)", price: 895},
//     {name: "Clear", price: 499},
//     {name: "I Caps", price: 1200}
// ]