import mongoose from "mongoose";
import { ObjectId } from ".."

export const isValidIdLike = (id: any): string|null => {
    if (!id) return null

    if (id instanceof mongoose.Types.ObjectId) {
        return id.toHexString();
    }

    if (typeof id === "string" && isValidId(id)) {
        return id;
    }

    if (typeof id === "object" && id._id && isValidId(id._id)) {
        return id._id;
    }

    return null;
}

export const isValidId = (id: any ) => {
    return mongoose.Types.ObjectId.isValid(id);
}

export const getValidId = (id: any) : mongoose.Types.ObjectId | null => {
    const validId = isValidIdLike(id);
    if (!validId) return null;
    return ObjectId(validId);
}

export const isGreaterThanToday = (timestamp: number) => {
    const d = new Date(timestamp)
    if (isNaN(d.getTime())) return false;

    const today = new Date();
    today.setHours(0, 0, 0, 0) - 1
    return d >= today;
}

export const isGreaterThanNow = (timestamp: number) => {
    const d = new Date(timestamp)
    if (isNaN(d.getTime())) return false;

    return d >= new Date();
}

export const getEndOfDay = (timestamp: number) => {
    const d = new Date(timestamp)
    if (isNaN(d.getTime())) return null;

    d.setHours(23, 59, 59, 999);
    return d.getTime();
}

export const getStartOfDay = (timestamp: number) => {
    const d = new Date(timestamp)
    if (isNaN(d.getTime())) return null;

    d.setHours(0, 0, 0, 0);
    return d.getTime();
}

export type Dirty<T> = {
    [P in keyof T]?: T[P] | null;
  };