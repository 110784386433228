import { useLoaderData, useNavigate } from "react-router-dom";
import { Calendar, dateFnsLocalizer, ResourceHeaderProps } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { useEffect, useMemo, useState, } from "react";
import EventModal, { IModalEvent } from "./EventEdit";
import { enGB } from "date-fns/locale/en-GB";
import { IResponseData, useLoginStore } from "../context/LoginContext";
import { add, addDays, format, getDay, parse, startOfWeek } from "date-fns";
import "./Diary.css"
import noDefault from "../shared/noDefault";
import { useNavbarStore } from "../context/NavbarContext";
import { PencilSquareIcon } from "@heroicons/react/16/solid";
import { FocusModal } from "../shared/Modals";
import { DayEdit, IDayEditProps } from "./DayEdit";
import { EventSessionDayView } from "va_shared/src/schema/eventSessionDay";
import { EventSessionTypeView } from "va_shared/src/schema/eventSessionType";
import { EventBookView } from "va_shared/src/schema/eventBook";
import { EventView } from "va_shared/src/schema/event";
import { EventReasonView } from "va_shared/src/schema/eventReason";
import { getStartOfDay } from "va_shared/src/utilities/Validation";

const DEFAULT_MODAL_JUMP = {date:"", visible:false};

const locales = {
  "en-GB": enGB,
};

const time_step = 5;

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

interface IDiaryEvent extends EventView{
  patient_id: string;
  resourceId: string;
}

export default function Diary() {
  const context = useLoaderData() as {events: EventView[], date: Date, session_days: EventSessionDayView[], types: EventSessionTypeView[], books:EventBookView[], reasons: EventReasonView[]};
  const setContent = useNavbarStore((state) => state.setContent);
  const [getState, setState] = useState<{events: EventView[], date: Date, session_days: EventSessionDayView[]}>({events: context.events, date: context.date, session_days: context.session_days});
  const [modalEvent, setModalEvent] = useState<(IModalEvent & {is_loading:boolean}) | null>(null);
  const [modalDayEdit, setModalDayEdit] = useState<Partial<IDayEditProps & {is_loading:boolean}> | null>(null);
  const [modalJump, setModalJump] = useState(DEFAULT_MODAL_JUMP);
  const [getErrors, setErrors] = useState([] as string[])
  const [selectedEvent, setSelectedEvent] = useState<string>("");
  const [lastEventClick, setLastEventClick] = useState<number>(0);
  const authorised_request = useLoginStore((state) => state.authorised_request);
  const async_authorised_request = useLoginStore((state) => state.async_authorised_request);

  const navigate = useNavigate();
  
  const activeEventBooks = useMemo(() =>context.books.filter(b => b.is_active), [ context.books ])

  const events: IDiaryEvent[] = (getState && getState.events)? getState.events.map((e:EventView)=> {return {...e, start:new Date(e.start), end:new Date(e.end),patient:e.patient, patient_ref: (typeof e.patient === "object" && "patient_id" in e.patient)?(e.patient?.patient_id):"", resourceId:e.book}}):[] as any

  const maxTime = getState.session_days.reduce((max, s) => s.sessions.reduce((max, s) => s.end_at > max ? s.end_at : max, max), 0);
  const minTime = getState.session_days.reduce((min, s) => s.sessions.reduce((min, s) => s.start_at < min ? s.start_at : min, min), maxTime);

  const updateEventAudit = (event_id: string, button: "arrived" | "seen" | "departed" ) => {
    authorised_request("POST", "event/time/"+event_id, {[button]: true}, (response: IResponseData) => {
      if (response.status === 200) {
        setState({
          ...getState, 
          events: getState.events.map((ev:EventView) => {
            if (ev._id === response.event._id) {
              return {...ev, arrived_at:response.event.arrived_at, seen_at:response.event.seen_at, departed_at:response.event.departed_at}
            } 
            return ev
          }) as any})
      } else {
        console.error("Error", response.error);
      }
    });
  }

  useEffect(() => {
    setContent([
      <button onClick={noDefault(() => {
        getEvents(add(getState.date, {weeks:-1})).then(setState).catch(console.error);
      })} className="text-xl m-1 bg-stone-600 p-1 px-2 rounded-sm whitespace-nowrap">Previous Week</button>,

      <button onClick={noDefault(() => {
        getEvents(add(getState.date, {days:-1})).then(setState).catch(console.error);
      })} className="text-xl m-1 bg-stone-600 p-1 px-2 rounded-sm whitespace-nowrap">Previous</button>, 

      <button onClick={noDefault(() => {
        getEvents(new Date()).then(setState).catch(console.error);
      })} className="text-xl m-1 bg-stone-600 p-1 px-2 rounded-sm whitespace-nowrap">Today</button>,
      
      <button onClick={noDefault(() => {
        getEvents(add(getState.date, {days:1})).then(setState).catch(console.error);
      })} className="text-xl m-1 bg-stone-600 p-1 px-2 rounded-sm whitespace-nowrap">Next</button>

      ,<button onClick={noDefault(() => {
        getEvents(add(getState.date, {weeks:1})).then(setState).catch(console.error);
      })} className="text-xl m-1 bg-stone-600 p-1 px-2 rounded-sm whitespace-nowrap">Next Week</button>,

      <h1 className="mx-auto text-2xl whitespace-nowrap hover:cursor-pointer" onClick={noDefault(() => {
        setModalJump({date:format(getState.date, "yyyy-MM-dd"), visible:true});
      })}>{format(getState.date, "(EEEE) MMMM do yyyy")}</h1>,

      <div className="flex">
        <button onClick={noDefault(() => {
          getEvents(add(getState.date, {months:3})).then(setState).catch(console.error);
        })} className="text-xl m-1 order-1 bg-stone-700 p-1 px-2 rounded-sm float-end whitespace-nowrap">3 Month</button>
                  
        <button onClick={noDefault(() => {
          getEvents(add(getState.date, {months:6})).then(setState).catch(console.error);
        })} className="text-xl m-1 order-2 bg-stone-700 p-1 px-2 rounded-sm float-end whitespace-nowrap">6 Month</button>

        <button onClick={noDefault(() => {
        getEvents(add(getState.date, {months:12})).then(setState).catch(console.error);
        })} className="text-xl m-1 order-3 bg-stone-700 p-1 px-2 rounded-sm whitespace-nowrap">12 Month</button>
      </div>
  ]);
  return () => setContent([])
  }, [getState.date])


  const handleNavigate = (d:Date) => {
    getEvents(d).then(setState)
  }

  const handleModelEvent = (e:any) => { // e: IModalEvent|null

    if (e === null){
      setModalEvent(null);
      return
    }
    e.event_reasons.map((er: any) => er._id);
    setModalEvent({...e, is_loading:true})

    if (e._id){
      if (e.delete){
        authorised_request( "DELETE", "event/"+e._id, undefined, (response: IResponseData) => {
          if (response.status === 200) {
            setModalEvent(null);
            getEvents(new Date(e.start)).then(setState);
          } else if (response.status === 400) {
            setErrors(response.error.split("; "));
          } else {
            setErrors(["Error deleting event"]);
          }
        });
        return
      }

      const update: any= {}
      const events = getState.events.filter((ev:EventView) => ev._id === e._id);
      if (events.length === 0) {
        return
      }

      (Object.keys(events[0]) as (keyof EventView)[]).forEach((key) => {
        if (events[0][key] !== e[key]) {
          update[key] = e[key];
        }
      });

      if (Object.keys(update).length === 0) {
        setModalEvent(null);
        return
      }
      setErrors([])
      authorised_request( "PUT", "event/"+e._id,update, (response: IResponseData) => {
        if (response.status === 200) {
          setModalEvent(null);
          getEvents(new Date(e.start)).then(setState);
        } else if (response.status === 400) {
          setModalEvent({...e, is_loading:false});
          setErrors(response.error.split("; "));
        } else {
          setModalEvent({...e, is_loading:false});
          setErrors(["Error updating event"]);
        }
      });
    }else{
      const errors = [];
      if (!e.title.trim()){
        errors.push("Title is required");
      }

      if (!e.event_reasons.length){
        errors.push("At least one Reason is required");
      }

      if (errors.length > 0){
        setModalEvent({...e, is_loading:false});
        setErrors(errors);
        return
      }

      authorised_request( "POST","event/create", e, (response: IResponseData) => {
        if (response.status === 200) {
          setModalEvent(null);
          getEvents(new Date(e.start)).then(setState);
        } else if (response.status === 400) {
          setErrors(response.error.split("; "));
        } else {
          setErrors(["Error creating event"]);
        }
      });
    }
  }

  const handleDragEvent = (e: any) => {
    const cutOffDate = add(new Date(), {hours: -1});
    if (e.end < e.start || e.start < cutOffDate || e.event.start < cutOffDate || e.end.getDate() !== e.start.getDate()) {
      return
    } 
    
    authorised_request("PUT", "event/"+e.event._id, {start:e.start.getTime(), end:e.end.getTime(), book:e.resourceId}, (response: IResponseData) => {
      if (response.status!== 200) {
        console.error("Error updating event");
      }
    });

    setState({...getState, events: getState.events.map((ev:any) => {
      if (ev._id === e.event._id) {
        if (!e.start || !e.end || typeof e.resourceId !== "string") {
          console.error("Error updating event", e);
          // e.start/end is not number, its a date
        }
        return {...ev, start:e.start, end:e.end, book:e.resourceId}
      }
      return ev;
    })});   
  }
  
  function customEvent({event}:any){
    event.start = new Date(event.start);
    return (
      <div className={"h-auto " + (event._id === selectedEvent ? "selected" : "")}>
        <h3 onClick={noDefault(() => {
          if (event.patient._id) {
            navigate("/patient/"+event.patient._id);
          } 
        })} className="text-2xl italic hover:underline w-fit"> {event.patient.forename} {event.patient.surname} ({event.patient_ref})</h3>
        <h3 className="text-xl font-semibold p-0">{event.title} - {event.departed_at?"Departed":event.seen_at?"Seen":event.arrived_at?"Waiting":"Booked"}</h3>
        <hr className="m-1"/>

        {!event.text && <div>{event.text}</div>}

        <div className="h-full">
          <h3> {event.event_reasons.length===1?"Reason:":"Reasons:"} </h3>
          <ul className="list-disc list-inside"> {event.event_reasons.map( (rsn: EventReasonView) => <li key={rsn._id} className="list-item" title={rsn.description}>{rsn.name}</li> )} </ul>
        </div>

        <div hidden={new Date(event.start).getDay() !== new Date().getDay()}>
          <div className="w-full flex-row group-hover:flex hidden absolute bottom-0 text-xl space-x-1 left-0">
            <button title="Arrived" disabled={event.arrived_at || event.seen_at || event.departed_at} onClick={noDefault(() => updateEventAudit(event._id, "arrived"))} className="btn-primary w-full mx-0.5"> {event.arrived_at?format(new Date(event.arrived_at), "HH:mm:ss"):"Arrived"} </button>
            <button title="Seen" disabled={event.seen_at || event.departed_at} onClick={noDefault(() => updateEventAudit(event._id, "seen"))} className="btn-primary w-full"> {event.seen_at?format(new Date(event.seen_at), "HH:mm:ss"):"Seen"} </button>
            <button title="Departed" disabled={event.departed_at} onClick={noDefault(() => updateEventAudit(event._id, "departed"))} className=" btn-primary w-full mx-0.5"> {event.departed_at?format(new Date(event.departed_at), "HH:mm:ss"):"Departed"} </button>
          </div>
        </div>
        <h4 className="text-md italic absolute bottom-0.5 right-0.5 group-hover:bottom-8" >{format(event.start, "HH:mm")} - {format(event.end,"HH:mm")}</h4>

      </div>
    )
  }

  function customToolbar() {
    return (null)
  }

  function customHeader({label, resource, index }:ResourceHeaderProps<object>) {
    return (
      <div id={index.toString()} className="relative">
        <button onClick={noDefault(()=>{
          const session_day = getState.session_days.find((s: any) => (s.book) === (resource as EventBookView)._id);
          if (session_day) {
            setModalDayEdit({book:resource as any, date:getState.date, session_day, })
          }else{
            setModalDayEdit({book:resource as any, date:getState.date, session_day:{_id:"", date:getState.date.getTime(), book:resource as any, sessions:[]}})
          }
          })
        } className="absolute top-0.5 left-0 center"><PencilSquareIcon className="size-6 text-stone-600 center"/></button>
        <h2 className="mx-auto">{label}</h2>
      </div>
    )
  }


  function customEventColour(event: any){
    if (event.event_reasons.length > 0){
      return {style: {backgroundColor: "#"+event.event_reasons[0].colour}, className: "relative group"}
    }
    return {}
  }

  function customSlotGetter(date: Date, resourceId?: number | string) : React.HTMLAttributes<HTMLDivElement> {
    if (resourceId === undefined) { // Time Gutter Row Uses this function
      return ({style: {
        backgroundColor: "#efefef",
      }, onClick: () => {setModalEvent({
        start:date.getTime(), 
        reminders_sms:[], 
        end:(new Date(date.getTime() + 1200000)).getTime(), 
        patient:null, 
        patient_ref:null, 
        book_id:"", 
        title: "", 
        text: "", 
        event_reasons: [], 
        is_loading:false})
      }})
    }

    let colour = "grey";
    if (resourceId) {
      const session_day = getState.session_days.find((b) => b.book === resourceId);
      if (session_day) {
        const session = session_day.sessions.find((s) => s.start_at <= date.getTime() && s.end_at >= date.getTime());
        if (session) {
          colour = "#"+session.type.colour;
        }
      }
    }

    return ({style: {
      backgroundColor: colour,
    }})
  }

  const DndCalendar = withDragAndDrop(Calendar);

  return (
    <div>
     <DndCalendar
     defaultView="day"
      min={new Date(minTime)}
      max={new Date(maxTime)}
      views={{day:true}}
      date={getState.date}
      localizer={localizer}
      events={events}
      onNavigate={handleNavigate}
      selectable={true}
      dayLayoutAlgorithm={"no-overlap"}
      enableAutoScroll={true}
      slotPropGetter={customSlotGetter}
      formats={{timeGutterFormat: "HH:mm"}}
      eventPropGetter={customEventColour}
      resizable={getState.date >= add(new Date(), {days: -1})}
      draggableAccessor={(e: any) => !e.seen_at && !e.departed_at && e.start >= add(new Date(), {hours: -1})}
      // onDoubleClickEvent={(e:any) => {if (e.patient._id) {navigate("/patient/"+e.patient._id)} else {navigate("/patient/"+e.patient)}}}
      components={{event: customEvent, toolbar:customToolbar, resourceHeader:customHeader }}
      step={time_step}
      resourceAccessor={(e: any) => e.resourceId}
      resourceTitleAccessor={(e:any) => e.name}
      resources={activeEventBooks.map((b) => {return {_id:b._id, name:b.name}})}
      resourceIdAccessor={(b: any) => b._id}
      onEventDrop={handleDragEvent}
      onEventResize={handleDragEvent}
      onSelectEvent={(e: any) =>{
        setLastEventClick(Date.now());
        if (lastEventClick + 400 > Date.now()) {
          setModalEvent({...e, is_loading:false});
          setSelectedEvent(e._id);
          return
        }
        selectedEvent === e.id ? setSelectedEvent("") : setSelectedEvent(e._id)
      }}//setTimeout(() => setModalEvent({...e}), 300)}}
      onSelectSlot={e => {
        (new Date(e.start).getTime() >= (getStartOfDay(Date.now()) || 0)) && 
        setModalEvent({
          start:e.start.getTime(), 
          reminders_sms:[], 
          end:(new Date(e.end).getTime() - new Date(e.start).getTime() <= 500000)?(new Date(e.start).getTime() + 1200000 ):new Date(e.end).getTime(), 
          patient:null, 
          patient_ref:null, 
          book_id:e.resourceId + "", 
          title: "", 
          text: "", 
          event_reasons: [], 
          is_loading:false
        })}}
     />
      <FocusModal is_loading={modalEvent?.is_loading} is_visible={modalEvent !== null} onOutsideClick={() => setModalEvent(null)}>
        <EventModal getErrors={getErrors} event={modalEvent as any} setEvent={handleModelEvent} eventBooks={activeEventBooks} eventReasons={context.reasons} authorised_request={authorised_request}/>
      </FocusModal>
      <FocusModal is_loading={modalDayEdit?.is_loading} is_visible={modalDayEdit !== null} onOutsideClick={() => setModalDayEdit(null)}><DayEdit date={modalDayEdit?.date as any} sessionTypes={context.types}
      onSave={(newDaySession, repeat) => {
        if (!newDaySession || !newDaySession.book || !newDaySession.date || !Array.isArray(newDaySession.sessions)) setModalDayEdit(null);
        else {
          setModalDayEdit({ ...modalDayEdit, is_loading: true });

          let uri = "event/session/new";
          if (repeat) {
            uri += `?repeat=${SessionDayRepeatString(repeat.skip).repeat(repeat.cycle)}`;
            if (repeat.override) {
              uri += "&override=1";
            }
          }

          async_authorised_request("POST", uri, {
            date: newDaySession.date,
            book: newDaySession.book,
            sessions: newDaySession.sessions.map((s: any) => { return { start_at: s.start_at, end_at: s.end_at, type: s.type._id }; })
          }).then((response: IResponseData) => {
            if (response.status === 200) {
              setModalDayEdit(null);
              getEvents(getState.date).then(setState);
            } else {
              setModalDayEdit({ ...modalDayEdit, is_loading: false, session_day: newDaySession, errors: [response.error] });
            }
          }).catch((e) => {
            console.error(e);
            setModalDayEdit({ ...modalDayEdit, is_loading: false, session_day: newDaySession, errors: [e.error] });
          });
        }

      } } book={modalDayEdit?.book as any} session_day={modalDayEdit?.session_day as any}/></FocusModal>
      <FocusModal is_loading={false} is_visible={modalJump.visible} onOutsideClick={() => setModalJump(DEFAULT_MODAL_JUMP)}>
        <div className="border-2 border-stone-500 p-5 text-4xl mb-5 bg-stone-200 rounded-md" onClick={noDefault()}>
          <input type="date" className="bg-stone-200" value={modalJump.date} onChange={(e) => {
            setModalJump({date:e.target.value, visible:true});
            const new_date = new Date(e.target.value);
            if (isNaN(new_date.getTime()) || new Date("2000-01-01") > new_date || new Date("2100-01-01") < new_date) return;
            getEvents(new Date(e.target.value)).then(setState).catch(console.error);
            // setModalJump(false);
          }}/>
        </div>
      </FocusModal>
    </div>
  )
}


export function getEvents(date: Date) {
  const {async_authorised_request} = useLoginStore.getState();
  if (!async_authorised_request) return Promise.reject({status:402, error:"Not logged in"});

  date.setUTCHours(0, 0, 0, 0);
  let lower = date.getTime();
  let upper = addDays(date, 1).getTime();
  return Promise.all([
    async_authorised_request( "GET",`event?upper=${upper}&lower=${lower}`), 
    async_authorised_request( "GET",`event/session/day/${lower}`)]).then(([{events},{sessions}]) => {
      return ({events, date:date, session_days:sessions});
    }).catch((e) => {
      console.error(e);
      return {events:[], date:date, session_days:[], errors:true};
    });
}

export async function DiaryLoader({request}: any) {
  const params = new URL(request.url).searchParams;
  const query_date = parseInt(params.get('date') || "",10); 
  let date = !isNaN(query_date) ? new Date(query_date) : new Date();
  date = !isNaN(date.getTime()) ? date : new Date();

  const {async_authorised_request} = useLoginStore.getState();
  if (!async_authorised_request) return {events:[], date:date, session_days:[], errors:true, types:[]};

  let req;
  await Promise.all([
    getEvents(date), 
    async_authorised_request( "GET","event/book"),
    async_authorised_request( "GET", "event/reason"),
    async_authorised_request("GET", "event/session/types")
  ]).then(([{events, date, session_days}, {books}, {reasons}, {types}]) => {
    req = {events, date, session_days, types, books, reasons};
  }).catch((e) => {
    console.error(e);
    req = {events:[], date:date, session_days:[], errors:true, types:[]};
  });

  return req;
}

const RepeatDictionary: { [key: number]: string } = {
  0: "D",
  1: "2r",
  2: "3r",
  3: "4r",
  4: "5r",
  5: "6r",
  6: "W",
  7: "8r",
  8: "9r",
  9: "55r",
}
function SessionDayRepeatString(skip: number): string{
  if (skip < 10) return RepeatDictionary[skip] || "";
  return "55r"+SessionDayRepeatString(skip-10)
}