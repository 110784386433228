import { z } from "zod";
import { docSchema, docViewSchema } from "./document/document";

export const patientStatusSchema = z.object({
    name: z.string().trim().min(1, "Name is required").max(64, "Name is too long"),
}).merge(docSchema);

export type PatientStatus = z.infer<typeof patientStatusSchema>;

export const patientStatusViewSchema = patientStatusSchema.merge(docViewSchema);
export type PatientStatusView = z.infer<typeof patientStatusViewSchema>;