import { z } from "zod";
import { creatableSchema, creatableViewSchema } from "./document/logging/creatable";
import { deletableSchema } from "./document/logging/deletable";
import { docSchema, docViewSchema } from "./document/document";

export const AddressTypes = ["Patient"] as const;

const baseAddressSchema = z.object({
  type: z.enum(AddressTypes).default("Patient"),
  streetOne: z.string().trim().min(1, "Street address is required"),
  streetTwo: z.string().trim().optional(),
  city: z.string().trim().min(1, "City is required"),
  postcode: z.string().trim().min(1, "Postcode is required"),
  is_primary: z.boolean().default(false),
});

export const addressSchema = baseAddressSchema
.merge(docSchema)
.merge(creatableSchema)
.merge(deletableSchema)
export type Address = z.infer<typeof addressSchema>;

export const addressViewSchema = baseAddressSchema
.merge(docViewSchema)
.merge(creatableViewSchema)
.merge(deletableSchema);
export type AddressView = z.infer<typeof addressViewSchema>;

export const addressFormSchema = baseAddressSchema
.merge(docViewSchema.partial())
export type AddressForm = z.infer<typeof addressFormSchema>;
