import { useState } from "react";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import noDefault from "../shared/noDefault";

export type IEditReminder = any & {
    action?: "Complete" | "Delete";
}

export interface IReminderEditProps {
    reminder: IEditReminder;
    saveReminder: (reminder: IEditReminder) => void;
}

export default function ReminderEdit ({reminder, saveReminder}: IReminderEditProps) {
    const [rem, setRem] = useState<IEditReminder>(reminder);

    return <form >
        
        <h1 className="text-2xl">Reminder Edit</h1>

        <div className="grid grid-cols-2">
            <div className="flex flex-col m-1">
                <h2 className="text-xl">Reminder</h2> <hr/>

                <label> Patient </label>
                <Link className="hover:font-semibold" to={"/patient/"+rem.patient._id}><input type="text" className="w-full outline-none cursor-pointer p-1 rounded-sm m-0.5" value={rem.patient.forename + " " + rem.patient.surname} readOnly/></Link>
              
                <label>Due At</label>
                {ReadOnlyInput(format(rem.due_at, "dd/MM/yy HH:mm"))}   

                <label> Status</label>
                {ReadOnlyInput((rem.completed_at?`Completed(${format(rem.completed_at, " dd/MM/yy HH:mm")})`:rem.status) + (rem.deleted_at?" (Deleted)":"") )}

                <label> Kind </label>
                {ReadOnlyInput(rem.kind[0].toUpperCase()+rem.kind.slice(1))}

                <label> Created </label>
                {ReadOnlyInput(rem.created_by?.forename + " " + rem.created_by?.surname)}
                {ReadOnlyInput(format(rem.created_at, "dd/MM/yy HH:mm"))}
            </div>
        
            <div className="flex flex-col m-1 w-64">
                {MixedToInput(rem)}
            </div>


            {!rem.completed_at && !rem.deleted_at && <div className="col-span-2">
                <div className="flex flex-row">
                    <button onClick={noDefault(() => {saveReminder({...rem, action:"Delete"}); setRem({...rem,action:"Delete"})})} disabled={rem.action !== undefined} className="bg-red-500 font-semibold text-lgp-1 py-0.5 w-full m-0.5 rounded-sm hover:bg-red-600 hover:text-white disabled:bg-red-300 disabled:text-white duration-75">Delete</button>
                    <button onClick={noDefault(() => {saveReminder({...rem, action:"Complete"}); setRem({...rem,action:"Delete"})})} disabled={rem.action !== undefined} className="bg-green-500 font-semibold text-lg p-1 py-0.5 w-full m-0.5 rounded-sm hover:bg-green-600 hover:text-white disabled:bg-green-300 disabled:text-white duration-75">Complete</button>
                </div>
            </div>}
        </div>

    </form>
}

function ReadOnlyInput(value: string){
    return <input readOnly className="outline-none p-1 rounded-sm m-0.5 cursor-default w-full" value={value} />
}

function MixedToInput(rem: any){
    if (rem.kind === "appointment"){
        rem = rem as any;
        return <>
            <h2 className="group-hover:font-semibold text-xl">Details (Appointment)</h2> <hr/>
            <label> Title </label>
            {ReadOnlyInput(rem.appointment.title)}
            <label> Start </label>
            {ReadOnlyInput(format(rem.appointment.start, "dd/MM/yy HH:mm"))}
            <label> End </label>
            {ReadOnlyInput(format(rem.appointment.end, "dd/MM/yy HH:mm"))}

            <label> Title </label>
            {ReadOnlyInput(rem.appointment.title)}

            <Link to={"/diary?date="+new Date(rem.appointment.start).getTime()}><button className="bg-stone-500 font-semibold text-lg text-white p-1 py-0.5 w-full m-0.5 rounded-sm">Goto</button></Link>
        </>
    }
    if (rem.kind === "message"){
        rem = rem as any;
        return <>
            <h2 className="text-xl">Details (Message)</h2> <hr/>
            <label> Title </label>
            {ReadOnlyInput(rem.title)}
            <label> Message </label>
            <textarea readOnly rows={6} className="outline-none p-1 rounded-sm m-0.5 cursor-default w-full" value={rem.message} />
        </>
    }

    return <></>
}