import { z } from "zod";
import { docSchema, docViewSchema, zViewOID } from "./document/document";

export const stockFrameManufacturerSchema = z.object({
    name: z.string().trim().min(1, "Name is required").max(64, "Name is too long"),
    description: z.string().trim().max(256, "Description is too long"),
    is_active: z.boolean().default(true)
}).merge(docSchema)
export type StockFrameManufacturer = z.infer<typeof stockFrameManufacturerSchema>;

export const stockFrameManufacturerViewSchema = stockFrameManufacturerSchema.merge(docViewSchema)
export type StockFrameManufacturerView = z.infer<typeof stockFrameManufacturerViewSchema>;

export const stockFrameManufacturerFormSchema = stockFrameManufacturerSchema.merge(z.object({
    _id: zViewOID.optional(),
}))
export type StockFrameManufacturerForm = z.infer<typeof stockFrameManufacturerFormSchema>;