import { useEffect, useState } from "react";
import noDefault from "../shared/noDefault";
import ChartRender from "./ChartRender";
import { ChartPlot } from "va_shared/src/schema/examination";

export interface ChartProps {
    getCharting: ChartPlot[];
    setCharting: (data: ChartPlot[]) => void;
    save?: () => void;
}

function distance(x1: number, x2: number, y1: number, y2: number){
    return (x1-x2)*(x1-x2) + (y1-y2)*(y1-y2);
}

const getNextPOI = (charting: ChartPlot[]) => {
    const POIs = charting.filter((plot) => plot.kind === "ChartPOI").map((plot) => plot.count as number)
    if (POIs.length === 0) return 1;

    const maxNo = POIs.reduce((max, c) => Math.max(max, c), 0);  
    
    if (POIs.length === maxNo) return maxNo+1;

    for (let i = 1; i <= maxNo; i++) {
        if (!POIs.find((c) => c === i)) return i;
    }

    return Math.max(maxNo, POIs.length)+1;
}

export default function Chart({getCharting, setCharting, save}: ChartProps) {
    const [brush, setBrush] = useState<string>("");
    const [brushSize, setBrushSize] = useState<number>(5);
    const [lastDraw, setLastDraw] = useState<[number, number]>([0,0]);
    const [smooth, setSmooth] = useState<boolean>(false);
    const [clear, setClear] = useState<boolean>(false);
    

    useEffect(() => {
        if (brush === "ChartPOI" && brushSize < 3) setBrushSize(3);
    }, [brush])

    return (
        <div className="select-none">
            
        <ChartRender className="lg:w-3/5 mx-auto" charting={getCharting} click={(x,y) => {
            setClear(false)
            if (brush === "") {
                setCharting([...getCharting.filter((plot) => distance(plot.x, x, plot.y, y) > brushSize*plot.size)])
            }else if (brush === "ChartPOI") {
                setCharting([...getCharting, {x, y, kind: brush as any, size: brushSize, count: getNextPOI(getCharting)}]);
            }
            else{            
                setCharting([...getCharting, {x, y, kind: brush as any, size: brushSize}]);
            }
        }} 
            mouseMove={(x,y) => {
                setClear(false);
                if (brush === "") {
                    setCharting([...getCharting.filter((plot) => distance(plot.x, x, plot.y, y) > brushSize*plot.size)])
                }else if (brush === "ChartPOI") {
                    return;
                }
                else{      
                    if (smooth || distance(lastDraw[0], x, lastDraw[1], y) > brushSize*brushSize*3) {
                        setLastDraw([x,y]);
                        setCharting([...getCharting, {x, y, kind: brush as any, size: brushSize}]);
                    };
                }
            }}
        />  

        <div className="border-4 p-1 border-stone-2 mx-auto lg:w-1/2">
        <div className="flex flex-row w-full mx-auto">
            <svg className="w-1/5 h-24 mx-auto" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet"  onClick={noDefault(()=>setBrush(""))}>
                {brush === "" && <circle cx="50" cy="50" r="45" fill="#ffffff00" stroke="#090" strokeWidth="5" />}
                <rect x="30" y="20" transform="rotate(8 50 50)" width="40" height="60" fill="#aaa" />
                <rect x="30" y="20" transform="rotate(8 50 50)" width="40" height="14" fill="#ddd" />
            </svg>
            <svg className="w-1/5 h-24 mx-auto" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet"  onClick={noDefault(()=>setBrush("ChartDeep"))}>
                {brush === "ChartDeep" && <circle cx="50" cy="50" r="45" fill="#ffffff00" stroke="#090" strokeWidth="5" />}
                <rect x="30" y="20" transform="rotate(8 50 50)" width="30" height="8" fill="#222" />
                <rect x="40" y="40" transform="rotate(16 50 50)" width="30" height="8" fill="#222" />
                <rect x="20" y="52" transform="rotate(-16 50 50)" width="30" height="8" fill="#222" />
                <rect x="40" y="64" transform="rotate(-28 60 44)" width="30" height="8" fill="#222" />
            </svg>
            <svg className="w-1/5 h-24 mx-auto" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet"  onClick={noDefault(()=>setBrush("ChartHemorrhage"))}>
                {brush === "ChartHemorrhage" && <circle cx="50" cy="50" r="45" fill="#ffffff00" stroke="#090" strokeWidth="5" />}
                <circle cx="42" cy="43" r="15" fill="#f00" />
                <circle cx="58" cy="38" r="15" fill="#f00" />
                <circle cx="50" cy="40" r="15" fill="#f00" />
                <circle cx="60" cy="60" r="15" fill="#f00" />
            </svg>
            <svg className="w-1/5 h-24 mx-auto" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet"  onClick={noDefault(()=>setBrush("ChartSuperficial"))}>
                {brush === "ChartSuperficial" && <circle cx="50" cy="50" r="45" fill="#ffffff00" stroke="#090" strokeWidth="5" />}
                <circle cx="52" cy="35" r="8" fill="#222" />
                <circle cx="66" cy="62" r="8" fill="#222" />
                <circle cx="36" cy="65" r="8" fill="#222" />
                {/* <circle cx="50" cy="40" r="8" fill="#222" /> */}
            </svg>
            <svg className="w-1/5 h-24 mx-auto" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid meet"  onClick={noDefault(()=>setBrush("ChartPOI"))}>
                {brush === "ChartPOI" && <circle cx="50" cy="50" r="45" fill="#ffffff00" stroke="#090" strokeWidth="5" />}
                <circle cx="50" cy="50" r="30" fill="#2220" stroke="#aaa" strokeWidth="5" />
                <text x="50" y="55" fontSize="16" textAnchor="middle" alignmentBaseline="middle">Chart</text>
            </svg>
        </div>
        <div className="flex flex-row mx-auto ">
            <button className="w-1/5 h-8 mx-auto" onClick={noDefault(()=>setBrush(""))}>Remove</button>
            <button className="w-1/5 h-8 mx-auto" onClick={noDefault(()=>setBrush("ChartDeep"))}>Deep</button>
            <button className="w-1/5 h-8 mx-auto" onClick={noDefault(()=>setBrush("ChartHemorrhage"))}>Hemorrhage</button>
            <button className="w-1/5 h-8 mx-auto" onClick={noDefault(()=>setBrush("ChartSuperficial"))}>Superficial</button>
            <button className="w-1/5 h-8 mx-auto" onClick={noDefault(()=>setBrush("ChartPOI"))}>Point</button>
        </div>
        <div className="flex flex-row mx-auto justify-evenly mt-4">
            {save&&<button className="h-8 mx-1 w-1/6 bg-teal-700 px-2 hover:bg-teal-800 pb-1 text-xl rounded-sm text-white" onClick={save?noDefault(save):undefined}> Save </button>}
            <button className="h-8 mx-1 w-1/6 bg-red-500 hover:bg-red-600 px-2 pb-1 text-xl rounded-sm text-white" onClick={noDefault(()=>{setClear(!clear);if (clear) {setCharting([])}else{setBrush("")}})}>{clear?"Confirm":"Clear"}</button>
            <span className="flex-row flex">
                <label className="h-8">Size</label>
                <input type="range" min={brush==="ChartPOI"?3:1} max="9" step="1" onChange={(e) => setBrushSize(parseInt(e.target.value,10))} value={brushSize.toString()} className="-translate-y-1 w-1/2 h-8 mx-auto scale-150"/>
                <p className="h-8">{brushSize}</p>
            </span>
           
            <span className="h-8 flex-row">
                <label className="">Smooth</label>
                <input type="checkbox" onChange={(e) => setSmooth(e.target.checked)} className="mt-2 m-1"/>
            </span>
          </div>
        </div>
        </div>
  );
} 