import { addMinutes, subMinutes } from "date-fns";
import { format } from "date-fns/format";

export interface ITimeInputProps {
    value: Date;
    onChange: (value: Date) => void;
    className?: string;
    max?: Date;
    min?: Date;
}

var last_minute = -1;
export function TimeInput({ value, onChange, className, max, min }: ITimeInputProps) {
    return <input className={className} type="time" value={format(value, "HH:mm")} onChange={e => {
        e.preventDefault();
        let [hours,minutes] = e.target.value.split(":").map((v) => parseInt(v,10));
        if (isNaN(hours) || isNaN(minutes)) {
            return;
        } 
        if (last_minute == 59 && minutes == 0) {
            hours += 1;
        }else if (last_minute == 0 && minutes == 59) {
            hours -= 1;
        }else{
            last_minute = minutes;
        }
        const newValue = new Date(value);
        newValue.setHours(hours, minutes);
        if (max && newValue >= max) {
            onChange(subMinutes(max, 1));
            return;
        }
        if (min && newValue < min) {
            onChange(addMinutes(min, 1));
            return;
        }
        onChange(newValue);
    }}/>;
}