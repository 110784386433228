import { useLoaderData } from "react-router-dom";
import { useLoginStore } from "../../../context/LoginContext";
import { auth_error } from "../../../shared/api";
import { StockFrameManufacturerForm, stockFrameManufacturerFormSchema, StockFrameManufacturerView } from "va_shared/src/schema/stockFrameManufacturer";
import { SchemaIssue } from "va_shared/src/schema/error";
import { useState } from "react";
import { LoadingSpinner } from "../../../shared/Loading";
import { boolPlus } from "../../../shared/className";
import { hasError } from "../../../shared/Validation";

const DEFAULT_MANUFACTURER = { name: "", description: "", is_active: true, errors: [], formErrors: [] }

export function AdminStockManufacturer() {
    const async_authorised_request = useLoginStore(state => state.async_authorised_request)
    const data = useLoaderData() as { manufacturers: StockFrameManufacturerView[] }

    const [request, setRequest] = useState(false)
    const [manufacturers, setManufacturers] = useState(data.manufacturers)
    const [manufacturerForm, setManufacturerForm] = useState<Partial<StockFrameManufacturerForm> & { errors: string[], formErrors: SchemaIssue[] }>(DEFAULT_MANUFACTURER)

    const manufacturerSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        manufacturerForm._id = manufacturerForm._id || undefined

        const { error, data } = stockFrameManufacturerFormSchema.safeParse(manufacturerForm)

        if (error) {
            console.error(error)
            setManufacturerForm({ ...manufacturerForm, formErrors: error.errors })
            return
        }

        setRequest(true)
        if (manufacturerForm._id) {
            async_authorised_request("PATCH", "stock/frameManufacturer", data).then((res) => {
                setManufacturerForm(DEFAULT_MANUFACTURER)
                setManufacturers(manufacturers.map(manufacturer => (manufacturer._id === res.manufacturer._id) ? res.manufacturer : manufacturer))
            }).catch((res) => {
                console.error(res)
                setManufacturerForm({ ...manufacturerForm, errors: [res.error], formErrors: [] })
            }).finally(() => {
                setRequest(false)
            })
        } else {
            async_authorised_request("POST", "stock/frameManufacturer", data).then((res) => {
                setManufacturerForm(DEFAULT_MANUFACTURER)
                setManufacturers([...manufacturers, res.manufacturer])
            }).catch((res) => {
                console.error(res)
                setManufacturerForm({ ...manufacturerForm, errors: [res.error], formErrors: [] })
            }).finally(() => {
                setRequest(false)
            })
        }
    }
    
    if (request) {
        return <LoadingSpinner />
    }                    

    return (
        <div className="m-4 space-y-2">
            <h1 className="text-3xl">Frame Manufacturers</h1>
            <div className="bg-stone-200 rounded-lg p-4 space-y-4 relative">
            <form className="p-4 mx-auto w-full md:w-1/2 rounded-md space-y-1 border-4 border-stone-400 shadow-md text-lg" onSubmit={manufacturerSubmit}>
                    <h2 className="text-2xl text-center"> {manufacturerForm._id ? "Edit" : "Create"} Manufacturer</h2>
                    <div>
                        {manufacturerForm.errors.map((error, i) => (
                            <div key={i} className="bg-red-300 text-red-800 p-1 text-center rounded-sm">{error}</div>
                        ))}
                        {manufacturerForm.formErrors.map((error, i) => (
                            <div key={i} className="bg-red-300 text-red-800 p-1 text-center rounded-sm">{error.message}</div>
                        ))}
                    </div>
                    <div className="grid grid-cols-2">
                        <label htmlFor="name">Name</label>
                        <input className={boolPlus("px-1", "bg-red-300", !hasError("name", manufacturerForm.formErrors))} type="text" id="name" value={manufacturerForm.name} onChange={e => setManufacturerForm({ ...manufacturerForm, name: e.target.value })} />
                    </div>
                    <div className="grid grid-cols-2">
                        <label htmlFor="description">Description</label>
                        <textarea className={boolPlus("px-1", "bg-red-300", !hasError("description", manufacturerForm.formErrors))} id="description" value={manufacturerForm.description} onChange={e => setManufacturerForm({ ...manufacturerForm, description: e.target.value })} />
                    </div>
                    {manufacturerForm._id &&
                        <div className="grid grid-cols-2">
                            <label>Active:</label>
                            <input className="accent-teal-600" type="checkbox" checked={manufacturerForm.is_active} onChange={e => setManufacturerForm({ ...manufacturerForm, is_active: e.target.checked })} />
                        </div>
                    }
                    <button type="submit" className="btn-accent w-full text-xl py-0.5 rounded-sm"> {manufacturerForm._id ? "Save" : "Create"} Manufacturer</button>
                    {manufacturerForm._id &&
                        <button className="btn-dark w-full text-xl py-0.5 rounded-sm" onClick={() => {
                            setManufacturerForm(DEFAULT_MANUFACTURER)
                        }}>Cancel</button>
                    }
                </form>
                <div>
                    <table className="w-full">
                        <thead className="bg-stone-500 text-lg">
                            <tr> 
                                <th>Name</th>
                                <th>Description</th>
                                <th>Active</th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {manufacturers.sort((a,b)=>a.name>b.name?1:-1).map(manufacturer => (
                                <tr key={manufacturer._id} className="bg-stone-300 hover:bg-stone-400 p-2 text-center hover:cursor-pointer" onClick={() => {
                                    setManufacturerForm({...manufacturer, errors: [], formErrors: []})
                                }}>
                                    <td>{manufacturer.name}</td>
                                    <td>{manufacturer.description? manufacturer.description : <i className="text-stone-400 font-semibold">No Description</i>}</td>
                                    <td>{manufacturer.is_active ? "Active" : "Disabled"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export function AdminStockManufacturerLoader() {
    const async_authorised_request = useLoginStore.getState().async_authorised_request
    if (!async_authorised_request) return auth_error;

    return async_authorised_request("GET", "stock/frameManufacturer", undefined)
}