import { useLoaderData } from "react-router-dom";
import { useLoginStore } from "../../../context/LoginContext";
import { StockLocationForm, stockLocationFormSchema, StockLocationView } from "va_shared/src/schema/stockLocation";
import { useState } from "react";
import { stockLocationGroupFormSchema, StockLocationGroupForm, StockLocationGroupView } from "va_shared/src/schema/stockLocationGroup";
import {  boolStr } from "../../../shared/className";
import { hasError } from "../../../shared/Validation";
import { SchemaIssue} from "va_shared/src/schema/error";
import { LoadingSpinner } from "../../../shared/Loading";
import noDefault from "../../../shared/noDefault";

export function AdminStockLocation() {
    const async_authorised_request = useLoginStore(state => state.async_authorised_request);
    const data = useLoaderData() as { locations: StockLocationView[], groups: StockLocationGroupView[] };

    const [request, setRequest] = useState(false);
    const [locations, setLocations] = useState(data.locations || []);
    const [groups, setGroups] = useState(data.groups || []);

    const [locationForm, setLocationForm] = useState<Partial<StockLocationForm> & {formErrors:SchemaIssue[], errors:string[]}>({
        _id: "",
        name: "",
        description: "",
        group: "",
        is_active: true,
        errors: [],
        formErrors: []
    });

    const [groupForm, setGroupForm] = useState<Partial<StockLocationGroupForm> & {formErrors:SchemaIssue[], errors:string[]}>({
        _id: "",
        name: "",
        description: "",
        is_active: true,
        formErrors: [],
        errors: []
    });

    const locationSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        locationForm._id = locationForm._id || undefined;

        const {error, data} = stockLocationFormSchema.safeParse(locationForm)

        if (error) {
            console.log(error);
            setLocationForm({...locationForm, formErrors: error.errors});
            return;
        }
        
        setRequest(true);
        if (locationForm._id) {
            async_authorised_request("PATCH", "stock/location", data).then(res => {
                if (res.status === 200) {
                    setLocations(locations.map(location => location._id === res.location._id ? res.location : location));
                    setLocationForm({name: "", description: "", group: "", is_active: true, errors: [], formErrors: []});
                }
            }).catch(err=>{
                console.log(err)
                setLocationForm({...locationForm, errors: [err.error]});
            }).finally(() => {
                setRequest(false);
            });
        } else {
            async_authorised_request("POST", "stock/location", data).then(res => {
                if (res.status === 200) {
                    setLocations([...locations, res.location]);
                    setLocationForm({name: "", description: "", group: "", is_active: true, errors: [], formErrors: []});
                }
            }).catch(err=>{
                console.log(err)
                setLocationForm({...locationForm, errors: [err.error]});
            }).finally(() => {
                setRequest(false);
            });
        }
    }

    const groupSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        groupForm._id = groupForm._id || undefined;

        const {error, data} = stockLocationGroupFormSchema.safeParse(groupForm)

        if (error) {
            console.log(error);
            setGroupForm({...groupForm, formErrors: error.errors});
            return;
        }
        
        setRequest(true);
        if (groupForm._id) {
            async_authorised_request("PATCH", "stock/location/group", data).then(res => {
                setRequest(false);
                setGroups(groups.map(group => group._id === res.group._id ? res.group : group));
                setGroupForm({name: "", description: "", is_active: true, errors: [], formErrors: []});
                setLocations(locations.map(l => (l.group?._id === res.group._id) ? {...l, group: res.group, is_active:!res.group.is_active?false:l.is_active} : l));
            }).catch(res=>{
                console.log(res.error);
                setGroupForm({...groupForm, errors: [res.error]});
            }).finally(() => {
                setRequest(false);
            });
        } else {
            async_authorised_request("POST", "stock/location/group", data).then(res => {
                setGroups([...groups, res.group]);
                setGroupForm({name: "", description: "", is_active: true, errors: [], formErrors: []});
            }).catch(res=>{
                console.log(res.error);
                setGroupForm({...groupForm, errors: [res.error]});
            }).finally(() => {
                setRequest(false);  
            })
        }
    }

    if (request) {
        return <LoadingSpinner/>
    }
    

    return (
        <div className="m-4 space-y-2">
            <h1 className="text-3xl">Stock Locations</h1>
            <div className="bg-stone-200 rounded-lg p-4 space-y-4 relative">
                <h1 className="text-2xl text-stone-400 font-semibold absolute top-1 left-4">Location</h1>
                <form className="p-4 mx-auto w-full md:w-1/2 rounded-md space-y-1 border-4 border-stone-400 shadow-md text-lg" onSubmit={locationSubmit}>
                    <h1 className="text-2xl text-center"> { locationForm._id ? "Edit" : "Create"} Location</h1>
                    <div>
                        {locationForm.errors.map((error, i) => (
                            <div key={i} className="bg-red-300 text-red-800 p-1 text-center rounded-sm">{error}</div>
                        ))}
                        {locationForm.formErrors.map((error, i) => (
                            <div key={i} className="bg-red-300 text-red-800 p-1 text-center rounded-sm">{error.message}</div>
                        ))}
                    </div>
                    <div className="grid grid-cols-2">
                        <label> Name: </label>
                        <input className={boolStr("px-1 rounded-sm", "bg-red-300 px-1 rounded-sm", hasError("name", locationForm.formErrors))} type="text" value={locationForm.name} onChange={e => setLocationForm({...locationForm, name: e.target.value})}/>
                    </div>
                    <div className="grid grid-cols-2">
                        <label> Group: </label>
                        <select className={boolStr("bg-white px-1 rounded-sm", "bg-red-300 px-1 rounded-sm", hasError("group", locationForm.formErrors))} value={locationForm.group || ""} onChange={e => setLocationForm({...locationForm, group:e.target.value })}>
                            <option hidden value="">Select a group</option>
                            {groups && groups.map(group => (
                                <option key={group._id} hidden={!group.is_active} value={group._id}>{group.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="grid grid-cols-2">
                        <label> Description: </label>
                        <textarea className="px-1 rounded-sm" value={locationForm.description} onChange={e => setLocationForm({...locationForm, description: e.target.value})}/>
                    </div>
                    { locationForm._id &&
                    <div className="grid grid-cols-2">
                        <label> Active: </label>
                        <input className="accent-teal-600" type="checkbox" checked={locationForm.is_active} onChange={e => setLocationForm({...locationForm, is_active: e.target.checked})}/>
                    </div>
                    }
                    <button type="submit" className="btn-accent w-full text-xl py-0.5 rounded-sm !mt-4"> {locationForm._id? "Save":"Create"} Location</button>
                    { locationForm._id &&
                        <button className="btn-dark w-full text-xl py-0.5 rounded-sm !mt-2" onClick={noDefault(()=>{
                            setLocationForm({name: "", description: "", group: "", is_active: true, errors: [], formErrors: []});
                        })}> Cancel</button>
                    }
                </form>
                <div>
                    <table className="w-full">
                        <thead className="bg-stone-500 text-lg">
                            <tr className="">
                                <th>Name</th>
                                <th>Description</th>
                                <th>Group</th>
                                <th>Active</th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {locations.sort((a, b) => (a.group._id === b.group._id ? a.name > b.name ? 1: -1 : a.group.name > b.group.name ? 1 : -1)).map(location => (
                                <tr key={location._id} className="bg-stone-300 hover:bg-stone-400 p-2 text-center hover:cursor-pointer" onClick={noDefault(()=>{
                                    setLocationForm({...location, formErrors: [], errors: [], group: location.group._id});
                                })}>
                                    <td>{location.name}</td>
                                    <td>{location.description ? location.description : <i className="text-stone-400 font-semibold">No Description</i>}</td> 
                                    <td title={location.group?.description}>{location.group?.name}</td>
                                    <td>{location.is_active ? "Active" : "Disabled"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <hr className=""/>
            <div className="bg-stone-200 rounded-lg p-4 space-y-4 relative">
                <h1 className="text-2xl text-stone-400 font-semibold absolute top-1 left-4">Location Group</h1>
                <form className="p-4 mx-auto w-full md:w-1/2 rounded-md space-y-1 border-4 border-stone-400 shadow-md text-lg" onSubmit={groupSubmit}>
                    <h1 className="text-2xl text-center"> { groupForm._id ? "Edit" : "Create"} Location Group</h1>
                    <div>
                        {groupForm.errors.map((error, i) => (
                            <div key={i} className="bg-red-300 text-red-800 p-1 text-center rounded-sm">{error}</div>
                        ))}
                        {groupForm.formErrors.map((error, i) => (
                            <div key={i} className="bg-red-300 text-red-800 p-1 text-center rounded-sm">{error.message}</div>
                        ))}
                    </div>
                    <div className="grid grid-cols-2">
                        <label> Name: </label>
                        <input className={boolStr("px-1 rounded-sm", "bg-red-300 px-1 rounded-sm", hasError("name", groupForm.formErrors))} type="text" value={groupForm.name} onChange={e => setGroupForm({...groupForm, name: e.target.value})}/>
                    </div>
                    <div className="grid grid-cols-2">
                        <label> Description: </label>
                        <textarea className="px-1 rounded-sm" value={groupForm.description} onChange={e => setGroupForm({...groupForm, description: e.target.value})}/>
                    </div>
                    {   groupForm._id &&
                        <div className="grid grid-cols-2">
                        <label> Active: </label>
                        <input type="checkbox" checked={groupForm.is_active} onChange={e => setGroupForm({...groupForm, is_active: e.target.checked})}/>
                    </div>}
                    <button type="submit" className="btn-accent w-full text-xl py-0.5 rounded-sm !mt-1">{groupForm._id?"Save":"Create"} Group</button>
                    { groupForm._id &&
                        <button className="btn-dark w-full text-xl py-0.5 rounded-sm !mt-2" onClick={noDefault(()=>{
                            setGroupForm({name: "", description: "", is_active: true, errors: [], formErrors: []});
                        })}> Cancel</button>
                    }
                </form>
                <div>
                    <table className="w-full">
                        <thead className="bg-stone-500 text-lg">
                            <tr>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Active</th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {groups.sort((a,b)=>a.name>b.name?1:-1).map(group => (
                                <tr key={group._id} className="bg-stone-300 hover:bg-stone-400 p-2 text-center hover:cursor-pointer" onClick={noDefault(()=>{
                                    setGroupForm({...group, formErrors: [], errors: []});
                                })}>
                                    <td>{group.name}</td>
                                    <td>{group.description ? group.description : <i className="text-stone-400 font-semibold">No Description</i>}</td>
                                    <td>{group.is_active ? "Active" : "Disabled"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>  
        </div>
    );
}

export function AdminStockLocationLoader() {
    const async_authorised_request = useLoginStore.getState().async_authorised_request;

    if (async_authorised_request === undefined) {
       return { error: "Not logged in", status: 401 };
    }
    
    return async_authorised_request("GET", "/stock/location", undefined);
}