import Nysiis from "nysiis";

// https://en.wikipedia.org/wiki/New_York_State_Identification_and_Intelligence_System
const encoder = new Nysiis();
export const matchFuzzy = (text_1: string, text_2: string): boolean => {
  return encoder.encode(text_1) === encoder.encode(text_2);
};

export const makeFuzzy = (text: string): string => {
  return encoder.encode(text);
}