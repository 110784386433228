
export const isValidPence = (pence: number): boolean => {
    return Number.isInteger(pence) && pence >= 0;
}

export function PrettyPence(amount: number, sign: boolean = false){
    if (amount === 0) return "0.00";
    return `${(sign && amount>0 && "-") || ""}${ThousandSeparator(Math.trunc(Math.abs(amount)/100))}.${(Math.abs(amount%100)).toString().padStart(2,"0")}`
}

export function ThousandSeparator(amount: number){
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}