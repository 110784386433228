import { useLoaderData } from "react-router-dom";
import { useLoginStore } from "../../../context/LoginContext";
import { auth_error } from "../../../shared/api";
import { StockContactsBrandForm, stockContactsBrandFormSchema, StockContactsBrandView } from "va_shared/src/schema/stockContactsBrand";
import { useState } from "react";
import { SchemaIssue } from "va_shared/src/schema/error";
import { LoadingSpinner } from "../../../shared/Loading";
import { boolStr } from "../../../shared/className";
import { hasError } from "../../../shared/Validation";

export function AdminStockBrand(){
    const async_authorised_request = useLoginStore(state => state.async_authorised_request) 
    const context = useLoaderData() as { brands: StockContactsBrandView[] }

    const [brands, setBrands] = useState(context.brands)
    const [brandForm, setBrandForm] = useState<Partial<StockContactsBrandForm> & { errors: string[], formErrors: SchemaIssue[] }>({ name: "", description: "", errors: [], formErrors: [] })
    const [request, setRequest] = useState(false)

    const brandSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        brandForm._id = brandForm._id || undefined

        const { error, data } = stockContactsBrandFormSchema.safeParse(brandForm)

        if (error) {
            console.error(error)
            setBrandForm({ ...brandForm, formErrors: error.errors })
            return
        }

        setRequest(true)
        if (brandForm._id) {
            async_authorised_request("PATCH", "stock/contactsBrand", data).then((res) => {
                setBrandForm({ name: "", errors: [], formErrors: [], is_active: true, description: "" })
                setBrands(brands.map(brand => (brand._id === res.brand._id) ? res.brand : brand))
            }).catch((res) => {
                console.error(res.error)
                setBrandForm({ ...brandForm, errors: [res.error] })
            }).finally(() => {
                setRequest(false)
            })
        } else {
            async_authorised_request("POST", "stock/contactsBrand", data).then((res) => {
                setBrandForm({ name: "", errors: [], formErrors: [], is_active: true, description: "" })
                setBrands([...brands, res.brand])
            }).catch((res) => {
                console.error(res.error)
                setBrandForm({ ...brandForm, errors: [res.error] })
            }).finally(() => {
                setRequest(false)
            })
        }
    }

    if (request) return <LoadingSpinner/>

    return (
        <div className="m-4">
            <h1 className="text-3xl">Contacts Brands</h1>
            <div className="bg-stone-200 rounded-lg p-4 space-y-4 relative">
                <form className="p-4 mx-auto w-full md:w-1/2 rounded-md space-y-1 border-4 border-stone-400 shadow-md text-lg" onSubmit={brandSubmit}>
                    <h1 className="text-2xl text-center">{brandForm._id ? "Edit" : "Create"} Brand</h1>
                    <div>
                        {brandForm.errors.map((error) => (
                            <div key={error} className="bg-red-300 text-red-800 p-1 text-center rounded-sm">{error}</div>
                        ))}
                        {brandForm.formErrors.map((error) => (
                            <div key={error.code+error.path} className="bg-red-300 text-red-800 p-1 text-center rounded-sm">{error.message}</div>
                        ))}
                    </div>
                    <div className="flex flex-row justify-between">
                        <label className="block" htmlFor="name">Name</label>
                        <input className={boolStr("px-1 w-1/2", "px-1 bg-red-300 w-1/2", hasError("name", brandForm.formErrors))} type="text" id="name" value={brandForm.name} onChange={(e) => setBrandForm({ ...brandForm, name: e.target.value })} />
                    </div>
                    <div className="flex flex-row justify-between">
                        <label className="block" htmlFor="description">Description</label>
                        <textarea className={boolStr("px-1 w-1/2", "px-1 bg-red-300 w-1/2", hasError("description", brandForm.formErrors))} id="description" value={brandForm.description} onChange={(e) => setBrandForm({ ...brandForm, description: e.target.value })} />
                    </div>
                    { brandForm._id &&
                        <div className="flex flex-row justify-between">
                            <label className="block" htmlFor="is_active">Active</label>
                            <input className="accent-teal-800 w-1/2" type="checkbox" checked={brandForm.is_active} onChange={(e) => setBrandForm({ ...brandForm, is_active: e.target.checked })} />
                        </div>                        
                    }
                    <button type="submit" className="btn-accent w-full text-xl py-0.5 rounded-sm">{brandForm._id ? "Save" : "Create"} Brand</button>
                    { brandForm._id &&
                        <button className="btn-dark w-full text-xl py-0.5 rounded-sm" onClick={() => { setBrandForm({ name: "", errors: [], formErrors: [], is_active: true, description: "" })}}>Cancel</button>
                    }
                </form>
                <div>
                    <table className="w-full">
                        <thead className="bg-stone-500 text-lg">
                            <tr>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Active</th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {brands.map(brand => (
                                <tr key={brand._id} className="bg-stone-300 hover:bg-stone-400 p-2 text-center hover:cursor-pointer" onClick={() => {
                                    setBrandForm({ ...brand, errors: [], formErrors: [] })
                                }}>
                                    <td>{brand.name}</td>
                                    <td>{brand.description}</td>
                                    <td>{brand.is_active ? "Active" : "Disabled"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
        </div>
    </div>
    )    
}

export function AdminStockBrandLoader(){
    const async_authorised_request = useLoginStore.getState().async_authorised_request

    if (!async_authorised_request) return auth_error

    return async_authorised_request("GET", "stock/contactsBrand")
        
}