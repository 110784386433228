import { Params, useLoaderData } from "react-router-dom";
import { useLoginStore } from "../../context/LoginContext";
import { useEffect, useMemo, useState } from "react";

import { useNavbarStore } from "../../context/NavbarContext";
import Editor, { serialize } from "../../shared/Editor";
import { Node } from 'slate';
import { format } from "date-fns";
import { GetPatientNav } from "../homepage/patientNav";
import { PatientNoteView } from "va_shared/src/schema/patientNote";
import { auth_error } from "../../shared/api";
import { PatientView } from "va_shared/src/schema/patient";
import noDefault from "../../shared/noDefault";

function PatientNotes () {
  const data = useLoaderData() as {notes: PatientNoteView[], patient:PatientView};
  const async_authorised_request = useLoginStore(state => state.async_authorised_request);
  const [notes, setNotes] = useState(data.notes) 
  const [errors, setErrors] = useState([] as string[]);
  const [transmitting, setTransmitting] = useState(false);
  const setContent = useNavbarStore(state => state.setContent);
  const [search, setSearch] = useState("")
  const [value, setValue] = useState([
    {
      type: "root",
      children: [{ text: "" }]
    }
  ]) as unknown as [Node[], (change:Node[]) => void];

  useEffect(() => {
    setContent(GetPatientNav(data.patient._id, "Notes"))

    return () => {
      setContent([]);
    }
  }, [])

  const UndoNote = (n:PatientNoteView) => {
    if (transmitting) {
      return;
    }
    setTransmitting(true);
    async_authorised_request("DELETE", "patient/note/"+n._id).then((_) => {
      setNotes(notes.filter((note) => note._id !== n._id));
      setValue(JSON.parse(n.note_json)||[
        {
          type: "root",
          children: [{ text: n.note_plain||"" }]
        }
      ]);
    }).catch((error) => {
      setErrors(error.message.split("; "));
    }).finally(() => {
      setTransmitting(false);
    })
  }

  const SaveNote = () => {
    if (transmitting) {
      return;
    }
    setTransmitting(true);
    async_authorised_request("POST", "patient/note/"+data.patient._id, {note_json: JSON.stringify(value), note_plain:serialize(value)}).then((res) => {
      setValue([
        {
          type: "root",
          children: [{ text: "" }]
        }
      ] as any);
      setNotes([res.note, ...notes]);
    }).catch((error) => {
      setErrors(error.message.split("; "));
    }).finally(() => {
      setTransmitting(false);
    })
  }

  const renderedNotes = useMemo(() => {
    return notes.filter(n => search.trim().length <= 1 || n.note_plain.toLowerCase().includes(search.toLowerCase())).flatMap((n, i) => <div key={n._id} className="mx-5 m-1 bg-stone-300 rounded-md ">
      <Editor key={n._id} value={JSON.parse(n.note_json || "{}")} className="px-2 pb-2 font-semibold text-xl"/> 
      <p className="italic pb-1 px-2 relative"> {format(new Date(n.created_at), "dd/MM/yy HH:mm:ss")} - {n.created_by?`${n.created_by.forename} ${n.created_by.surname}` :"SYSTEM"} {(i===0 && n.created_at>Date.now()-60*600*600) && <button className="text-red-500 m-1 absolute bottom-0 right-1 font-bold" onClick={e=>{e.preventDefault();UndoNote(n)}}>Delete</button>} </p>
    </div>)
  }, [notes, search])

  return (
    <div className="w-full">
      <h1 className="text-2xl m-2"> Notes - {data.patient.forename}, {data.patient.surname} </h1>
      <Editor key={transmitting.toString()} value={value} setValue={setValue} className="border-2 rounded-md border-gray-500 m-2 px-2 pb-2 text-xl min-h-32" />
      <div className="px-4 mx-2">
        {errors && <div> {errors.map((e,i) => <p key={i}> {e} </p>)} </div>}
      <button disabled={transmitting || serialize(value).trim().length === 0} className="btn h-10 !w-full mx-auto rounded-md justify-center m-2 text-red-500 text-xl" onClick={noDefault(SaveNote)}> Save </button>
      </div>
      <div className="m-3 w-full flex flex-row">
        <input type="text" className="rounded-md border-2 border-stone-500 w-1/2 mx-auto h-12 text-lg p-2" value={search} onChange={e => setSearch(e.target.value)} placeholder="Search Notes" />
      </div>
      <div> {renderedNotes} </div> 
    </div>
  );
}

export default PatientNotes;
export async function PatientNotesLoader({params}: {params: Params<string>}){
  const {async_authorised_request} = useLoginStore.getState();
  if (!async_authorised_request) {
    return auth_error;
  }

  if (!params.id) {
    return({status: 500, error: "No patient id provided"});
  }

  return Promise.all([
    async_authorised_request("GET", "patient/"+params.id, undefined),
    async_authorised_request("GET", "patient/note/byPatient/"+params.id, undefined),
  ]).then((values) => {
    return {patient: values[0].patient, notes: values[1].notes};
  }); 
}

