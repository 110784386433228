import mongoose, { ObjectId, Document } from "mongoose";

export type Doc<T> = T & Document<ObjectId, T, T>

export const creatable = {
    created_at: { type: Number, required: true, immutable: true },
    created_by: { type: mongoose.Schema.Types.ObjectId, ref: "Account", immutable: true }, // null for system
}

export const deletable = {
    deleted_at: { type: Number },
    deleted_by: { type: mongoose.Schema.Types.ObjectId, ref: "Account" },
}

export const creatableViewPopulate = [
    { path: "created_by", select: "user forename surname" }
  ];
  export const updatableViewPopulate = [
    { path: "updates.updated_by", select: "user forename surname" }
  ];
  export const deletableViewPopulate = [
    { path: "deleted_by", select: "user forename surname" }
  ];
  