import { useLoaderData } from "react-router-dom"
import { useLoginStore } from "../../../context/LoginContext"
import { auth_error } from "../../../shared/api"
import { useState } from "react"
import { StockTagForm, stockTagFormSchema, StockTagView } from "va_shared/src/schema/stockTag"
import { SchemaIssue } from "va_shared/src/schema/error"
import { LoadingSpinner } from "../../../shared/Loading"
import { boolStr } from "../../../shared/className"
import { hasError } from "../../../shared/Validation"

export function AdminStockTag(){
    const data = useLoaderData() as {tags: StockTagView[]}
    const async_authorised_request = useLoginStore(state => state.async_authorised_request)

    const [tags, setTags] = useState(data.tags)
    const [tagForm, setTagForm] = useState<Partial<StockTagForm> & {errors:string[], formErrors:SchemaIssue[]} >({name: "", description:"", errors:[], formErrors:[]})
    const [request, setRequest] = useState(false)

    const tagSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        
        tagForm._id = tagForm._id || undefined
        
        const {error, data} = stockTagFormSchema.safeParse(tagForm)

        if (error){
            console.error(error)
            setTagForm({...tagForm, formErrors: error.errors})
            return
        }

        setRequest(true)
        if (tagForm._id){
            async_authorised_request("PATCH", "stock/tag", data).then((res) => {
                setTagForm({name: "", errors: [], formErrors: [], is_active: true, is_distinct: false, description: ""})
                setTags(tags.map(tag => (tag._id === res.tag._id) ? res.tag : tag))
            }).catch((res) => {
                console.error(res.error)
                setTagForm({...tagForm, errors: [res.error]})
            }).finally(() => {
                setRequest(false)
            })
        }else{
            async_authorised_request("POST", "stock/tag", data).then((res) => {
                setTagForm({name: "", errors: [], formErrors: [], is_active: true, is_distinct: false, description: ""})
                setTags([...tags, res.tag])
            }).catch((res) => {
                console.error(res.error)
                setTagForm({...tagForm, errors: [res.error]})
            }).finally(() => {
                setRequest(false)
            })
        }
    }

    if (request){
        return <LoadingSpinner/>
    }

    return (
        <div className="m-4 space-y-2">
            <h1 className="text-3xl">Stock Tags</h1>
            <div className="bg-stone-200 rounded-lg p-4 space-y-4 relative">
                <form className="p-4 mx-auto w-full md:w-1/2 rounded-md space-y-1 border-4 border-stone-400 shadow-md text-lg" onSubmit={tagSubmit}>
                    <h2 className="text-2xl text-center"> {tagForm._id? "Edit":"Create"} Tag</h2>
                    <div>
                        {tagForm.errors.map((error, i) => (
                            <div key={i} className="bg-red-300 text-red-800 p-1 text-center rounded-sm">{error}</div>
                        ))}
                        {tagForm.formErrors.map((error, i) => (
                            <div key={i} className="bg-red-300 text-red-800 p-1 text-center rounded-sm">{error.message}</div>
                        ))}
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        <label htmlFor="name">Name</label>
                        <input className={boolStr("w-full px-1", "w-full bg-red-300 px-1", hasError("name", tagForm.formErrors))} type="text" id="name" value={tagForm.name} onChange={(e) => setTagForm({...tagForm, name: e.target.value})}/>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        <label htmlFor="description">Description</label>
                        <textarea className={boolStr("w-full px-1", "w-full bg-red-300 px-1", hasError("description", tagForm.formErrors))} id="description" value={tagForm.description} onChange={(e) => setTagForm({...tagForm, description: e.target.value})}/>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        <label htmlFor="is_distinct" >Group</label>
                        <input className="accent-teal-800" type="checkbox" id="is_distinct" checked={tagForm.is_distinct} onChange={(e) => setTagForm({...tagForm, is_distinct: e.target.checked})}/>
                    </div>
                    {tagForm._id &&
                        <div className="grid grid-cols-2 gap-2">
                            <label htmlFor="is_active">Active</label>
                            <input className="accent-teal-800" type="checkbox" id="is_active" checked={tagForm.is_active} onChange={(e) => setTagForm({...tagForm, is_active: e.target.checked})}/>
                        </div>
                    }
                    <button type="submit" className="btn-accent w-full text-xl py-0.5 rounded-sm"> {tagForm._id? "Save":"Create"} Tag</button>
                    { tagForm._id &&
                        <button className="btn-dark w-full text-xl py-0.5 rounded-sm" onClick={() => {
                            setTagForm({name: "", description:"", errors: [], formErrors: []})
                        }}> Cancel</button>
                    }                    
                </form>
                <div>
                    <table className="w-full">
                        <thead className="bg-stone-500 text-lg">
                            <tr>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Group</th>
                                <th>Active</th>
                            </tr>
                        </thead>
                        <tbody className="text-center">
                            {tags.sort((a,b)=>a.name>b.name?1:-1).map(tag => (
                                <tr key={tag._id} className="bg-stone-300 hover:bg-stone-400 cursor-pointer" onClick={() => {
                                    setTagForm({...tag, errors: [], formErrors: []})
                                }}>
                                    <td>{tag.name}</td>
                                    <td>{tag.description? tag.description: <i className="text-stone-400 font-semibold">No Description</i>}</td>
                                    <td>{tag.is_distinct? "Yes":"No"}</td>
                                    <td>{tag.is_active? "Active":"Disabled"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export function AdminStockTagLoader(){
    const async_authorised_request = useLoginStore.getState().async_authorised_request
    if (!async_authorised_request) return auth_error

    return async_authorised_request("GET", "stock/tag", undefined)
}