import { z } from "zod";
import { docSchema, docViewSchema, zOID } from "./document/document";
import { creatableSchema, creatableViewSchema } from "./document/logging/creatable";
import { deletableSchema, deletableViewSchema } from "./document/logging/deletable";
import { accountViewSchema } from "./account";

export const eventSMS = z.object({
    sms: zOID,
    send_at: z.number().int("Invalid send_at timestamp"),
    // sent_at: z.number().int("Invalid sent_at timestamp").optional(),
    is_processed: z.boolean().default(false),
})
export type EventSMS = z.infer<typeof eventSMS>;

export const eventSchema = z.object({
    title: z.string().trim().min(1, "Title is required").max(64, "Title is too long"),
    start: z.number().int("Invalid start"),
    end: z.number().int("Invalid end"),
    book: zOID,
    patient: zOID,
    text: z.string().trim().optional(),
    event_reasons: z.array(zOID).min(1, "At least one event reason is required"),
    reminders_sms: z.array(eventSMS),
    arrived_at: z.number().int().optional(),
    arrived_by: zOID.optional(),
    seen_at: z.number().int().optional(),
    seen_by: zOID.optional(),
    departed_at: z.number().int().optional(),
    departed_by: zOID.optional(),

    reminders: z.array(zOID),
})
.merge(docSchema)
.merge(creatableSchema)
.merge(deletableSchema)
export type Event = z.infer<typeof eventSchema>;

export const eventViewSchema = eventSchema
.merge(z.object({
    arrived_by: accountViewSchema.nullable(),
    seen_by: accountViewSchema.nullable(),
    departed_by: accountViewSchema.nullable(),
}))
.merge(docViewSchema)
.merge(creatableViewSchema)
.merge(deletableViewSchema)
export type EventView = z.infer<typeof eventViewSchema>;
