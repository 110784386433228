export interface ICheckBoxProps {
    getState: any[];
    setState: (newState: any[]) => void;
    editable?: boolean
    options: {value: any, key: string}[];
}

export default function CheckBox ({getState, setState, editable, options}: ICheckBoxProps) {
    getState = getState? getState : [];
    return (
        <div>
            {options.map((option, index) => {
                return (
                    <label key={index} className='block' onClick={editable?(e) => {
                        e.preventDefault();
                        if (getState.includes(option.value)) {
                            setState(getState.filter((id) => id !== option.value))
                        } else {
                            setState([...getState, option.value])
                        }
                    }:undefined}>
                         <input readOnly className='pointer-events-none ' checked={getState.includes(option.value)} type='checkbox'/> 
                         {option.key} 
                    </label>
                )
            })}
        
            
        </div>
    );
}