import { Timestamp, zOID, zViewOID } from "../document";
import { z } from "zod";
import { logAccountSchemaView } from "./logAccount";

export const creatableSchema = z.object({
    created_by: zOID.nullable(),
    created_at: Timestamp.default(Date.now)
})

export const creatableViewSchema = z.object({
    created_by: logAccountSchemaView.partial().nullable(),
    created_at: Timestamp,
})

export const creatableSimpleSchema = z.object({
    created_by: zViewOID.nullable(),
    created_at: Timestamp
})