import { ReactNode } from "react";
import { LoadingSpinner } from "./Loading";

export interface IFocusModalProps {
    children: ReactNode;
    onOutsideClick: () => void;
    is_visible: boolean;
    is_loading?: boolean;
}

export const FocusModal = ({children, onOutsideClick, is_visible, is_loading}: IFocusModalProps) => {
    if (!is_visible) return null;

    if (is_loading) return ( 
        <div className="fixed z-20 top-0 left-0 bg-white bg-opacity-40 w-full h-screen overflow-clip flex items-center justify-center shadow-inner overscroll-contain">
            <LoadingSpinner/>
        </div>
    )

    return(
        <div onClick={onOutsideClick} className="fixed z-20 top-0 left-0 bg-white bg-opacity-40 w-full h-screen overflow-clip flex items-center justify-center shadow-inner overscroll-contain"> 
            {children}
        </div>
    )
}