// import { useEffect } from "react";
// import { useLoginStore } from "./LoginContext";
// var loop: NodeJS.Timeout | null = null;

export default function ContextLoader () {
    // let loginState = useLoginStore(state => state);
    
    // useEffect(() => {
    //     if (loginState.users.length === 0) {
    //         return;
    //     }

    //     if (loop !== null) {
    //         clearInterval(loop);
    //     }

    //     if (loginState.users.length === 0) return;
    //     loop = setInterval(() => {
    //         const now = Date.now();
    //         for (const u of loginState.users) {
    //             return //TODO fix
    //             if (u.access_expire - 6*60*1000 < now) {
    //                 console.log("Refreshing Token", u.username, new Date(u.access_expire).toLocaleString(), new Date(now).toLocaleString());
    //                 loginState.refresh_token(u.refresh).then((res) => {
    //                     console.log("Refreshed Token", res.username);
    //                 }).catch((err) => {
    //                     console.error("Failed to refresh token", err);
    //                 });
    //             }
    //         }
    //     }, 300000);
    // }, [loginState.users])
    return null
}
