import {z} from "zod";
import { docSchema, docViewSchema, zOID } from "./document/document";

const baseAccountSchema = z.object({
    username: z.string().trim().min(1, "Username is required").max(64, "Username is too long"),
    forename: z.string().trim().min(1, "Forename is required").max(64, "Forename is too long"),
    surname: z.string().trim().min(1, "Surname is required").max(64, "Surname is too long"),
    email: z.string().trim().email("Invalid email address").max(128, "Email is too long"),

    is_active: z.boolean().default(true),

    created_at: z.number().int(),
    created_by: zOID,
}).merge(docSchema)

// Generated/Modified by mongoose
export const accountSchema = baseAccountSchema.merge(z.object({
    oauth_google_id: z.string().nullable(),
    password_hash: z.string(),
    salt: z.string(),
    permission_group: z.number().int(),
}))
export type Account = z.infer<typeof accountSchema>;

export const accountViewSchema = baseAccountSchema.merge(docViewSchema).merge(z.object({
    created_by: z.object({
        username: z.string(),
        forename: z.string(),
        surname: z.string(),
        email: z.string(),
    }),
}))
export type AccountView = z.infer<typeof accountViewSchema>;