import { z } from "zod";
import { docSchema, docViewSchema, zOID, zViewOIDGenerator } from "./document/document";
import { creatableSchema, creatableViewSchema } from "./document/logging/creatable";
import { deletableSchema, deletableViewSchema } from "./document/logging/deletable";
import { patientViewSchema } from "./patient";

export const historySchema = z.object({
    details: z.string().trim(),
    driver: z.boolean().default(false),
    occupation: z.string().trim().default("N/A"),
})
export type History = z.infer<typeof historySchema>;

export const chartSchema = z.object({
    left_sphere: z.string().trim(),
    left_cylinder: z.string().trim(),
    left_axis: z.string().trim(),
    left_add: z.string().trim(),
    left_prism: z.string().trim(),
    left_base: z.string().trim(),
    left_va: z.string().trim(),

    right_sphere: z.string().trim(),
    right_cylinder: z.string().trim(),
    right_axis: z.string().trim(),
    right_add: z.string().trim(),
    right_prism: z.string().trim(),
    right_base: z.string().trim(),
    right_va: z.string().trim(),

    distance: z.string().trim(),
})
export type Chart = z.infer<typeof chartSchema>;

export const chartPlotSchema = z.object({
    x: z.number(),
    y: z.number(),
    kind: z.enum(["ChartSuperficial", "ChartHemorrhage", "ChartDeep", "ChartPOI"]),
    size: z.number(),
    count: z.number().optional(),
})
export type ChartPlot = z.infer<typeof chartPlotSchema>;

export const ophthalmoscopySchema = z.object({
    charting: z.array(chartPlotSchema),
    notes: z.string().trim(),
})
export type Ophthalmoscopy = z.infer<typeof ophthalmoscopySchema>;

export const additionalInvestigationsSchema = z.object({
    notes: z.string().trim(),
})
export type AdditionalInvestigations = z.infer<typeof additionalInvestigationsSchema>;

export const patientManagementSchema = z.object({
    notes: z.string().trim(),
})
export type PatientManagement = z.infer<typeof patientManagementSchema>;

export const visitReasonSchema = z.object({
    notes: z.string().trim(),
})
export type VisitReason = z.infer<typeof visitReasonSchema>;

export const currentSpectaclesSchema = z.object({
    charting: z.array(chartSchema),
    notes: z.string().trim(),
})
export type CurrentSpectacles = z.infer<typeof currentSpectaclesSchema>;

export const pupilsSchema = z.object({
    right: z.string().trim(),
    left: z.string().trim(),
    notes: z.string().trim(),
})
export type Pupils = z.infer<typeof pupilsSchema>;

export const ocularMotilitySchema = z.object({
    notes: z.string().trim(),
})
export type OcularMotility = z.infer<typeof ocularMotilitySchema>;

export const unaidedVisualAcuitySchema = z.object({
    left_distance: z.string().trim(),
    right_distance: z.string().trim(),

    left_near: z.string().trim(),
    right_near: z.string().trim(),

    distance_cover_test: z.string().trim(),
    near_cover_test: z.string().trim(),

    pd: z.string().trim(),
})
export type UnaidedVisualAcuity = z.infer<typeof unaidedVisualAcuitySchema>;

export const tonometrySchema = z.object({
    right: z.string().trim(),
    left: z.string().trim(),
    notes: z.string().trim(),
})
export type Tonometry = z.infer<typeof tonometrySchema>;

export const visualFieldsSchema = z.object({
    notes: z.string().trim(),
})
export type VisualFields = z.infer<typeof visualFieldsSchema>;

export const drugsSchema = z.object({
    drugs: z.array(z.string().trim()),
    notes: z.string().trim(),
})
export type Drugs = z.infer<typeof drugsSchema>;

export const ocularExaminationSchema = z.object({
    lids_lashes: z.string().trim(),
    conjunctiva: z.string().trim(),
    cornea: z.string().trim(),
    anterior_chamber: z.string().trim(),
    lens: z.string().trim(),
    vitreous: z.string().trim(),
    disc: z.string().trim(),
    vessels: z.string().trim(),
    periphery: z.string().trim(),
    macula: z.string().trim(),
    notes: z.string().trim(),
})
export type OcularExamination = z.infer<typeof ocularExaminationSchema>;

export const retinoscopySchema = z.object({
    left_sphere: z.string().trim(),
    left_cylinder: z.string().trim(),
    left_axis: z.string().trim(),
    left_va: z.string().trim(),

    right_sphere: z.string().trim(),
    right_cylinder: z.string().trim(),
    right_axis: z.string().trim(),
    right_va: z.string().trim(),

    notes: z.string().trim(),
})
export type Retinoscopy = z.infer<typeof retinoscopySchema>;

export const refractionSchema = z.object({
    left_sphere: z.string().trim(),
    left_cylinder: z.string().trim(),
    left_axis: z.string().trim(),
    left_va: z.string().trim(),

    right_sphere: z.string().trim(),
    right_cylinder: z.string().trim(),
    right_axis: z.string().trim(),
    right_va: z.string().trim(),

    notes: z.string().trim(),
})
export type Refraction = z.infer<typeof refractionSchema>;

export const subjectiveRefractionSchema = z.object({
    left_sphere: z.string().trim(),
    left_cylinder: z.string().trim(),
    left_axis: z.string().trim(),
    left_va: z.string().trim(),

    right_sphere: z.string().trim(),
    right_cylinder: z.string().trim(),
    right_axis: z.string().trim(),
    right_va: z.string().trim(),

    notes: z.string().trim(),
})
export type SubjectiveRefraction = z.infer<typeof subjectiveRefractionSchema>;

export const accommodationSchema = z.object({
    notes: z.string().trim(),
})
export type Accommodation = z.infer<typeof accommodationSchema>;

export const nearAdditionSchema = z.object({
    left_add: z.string().trim(),
    right_add: z.string().trim(),

    left_nva: z.string().trim(),
    right_nva: z.string().trim(),

    range: z.string().trim(),

    notes: z.string().trim(),
})
export type NearAddition = z.infer<typeof nearAdditionSchema>;

export const muscleBalanceSchema = z.object({
    distance: z.string().trim(),
    near: z.string().trim(),
})
export type MuscleBalance = z.infer<typeof muscleBalanceSchema>;

export const fixationDisparitySchema = z.object({
    distance: z.string().trim(),
    near: z.string().trim(),
})
export type FixationDisparity = z.infer<typeof fixationDisparitySchema>;

export const finalPrescriptionSchema = z.object({
    charting: z.array(chartSchema),
    notes: z.string().trim(),
})
export type FinalPrescription = z.infer<typeof finalPrescriptionSchema>;

const baseExaminationSchema = z.object({
    patient: zOID,
    visual_fields: visualFieldsSchema.optional(),
    tonometry: tonometrySchema.optional(),
    drugs: drugsSchema.optional(),
    ocular_examination: ocularExaminationSchema.optional(),
    ophthalmoscopy: ophthalmoscopySchema.optional(),
    additional_investigations: additionalInvestigationsSchema.optional(),
    patient_management: patientManagementSchema.optional(),
    visit_reason: visitReasonSchema.optional(),
    subjective_refraction: subjectiveRefractionSchema.optional(),
    history: historySchema.optional(),
    accommodation: accommodationSchema.optional(),
    near_addition: nearAdditionSchema.optional(),
    muscle_balance: muscleBalanceSchema.optional(),
    fixation_disparity: fixationDisparitySchema.optional(),
    final_prescription: finalPrescriptionSchema.optional(),
    pupils: pupilsSchema.optional(),
    ocular_motility: ocularMotilitySchema.optional(),
    unaided_visual_acuity: unaidedVisualAcuitySchema.optional(),
    retinoscopy: retinoscopySchema.optional(),
    refraction: refractionSchema.optional(),
    current_spectacles: currentSpectaclesSchema.optional(),
})



export const examinationSchema = baseExaminationSchema
.merge(docSchema)
.merge(creatableSchema)
.merge(deletableSchema);
export type Examination = z.infer<typeof examinationSchema>;

export const examinationViewSchema = baseExaminationSchema
.merge(z.object({
    patient: patientViewSchema.optional(),
}))
.merge(docViewSchema)
.merge(creatableViewSchema)
.merge(deletableViewSchema);
export type ExaminationView = z.infer<typeof examinationViewSchema>;

export const examinationFormSchema = baseExaminationSchema
.merge(z.object({
    patient: zViewOIDGenerator("Invalid Patient"),
}));
export type ExaminationForm = z.infer<typeof examinationFormSchema>;