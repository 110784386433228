import { UserIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export function AdminIndex(){
    return (
        <div className="bg-stone-200">
            <h1 className="text-3xl mx-2 pt-2">Admin</h1>
            <div>
                <h1 className="text-2xl underline mx-2">System</h1>
                <div className="p-2 flex-wrap flex w-full gap-4">
                    <Link to="/admin/system/user" className="min-w-32">
                        <button disabled className="min-w-fit px-3 justify-center h-11 py-2 items-center text-center flex flex-row w-full space-x-2 btn-primary text-xl rounded-sm">
                            <UserIcon className="h-8 pr-2"/> Users
                        </button>
                    </Link>
                </div>
            </div>
            <div>
                <h1 className="text-2xl underline mx-2">Stock</h1>
                <div className="p-2 flex-wrap flex w-full gap-4">
                    {/* <h1 className="text-2xl col-span-full underline">Stock</h1> */}
                    <Link to="/admin/stock/item"className="min-w-32">
                        <button className="min-w-fit px-3 justify-center h-11 py-2 items-center text-center flex flex-row w-full space-x-2 btn-primary text-xl rounded-sm">
                            Items   
                        </button>
                    </Link>
                    <Link to="/admin/stock/quantity"className="min-w-32">
                        <button className="min-w-fit px-3 justify-center h-11 py-2 items-center text-center flex flex-row w-full space-x-2 btn-primary text-xl rounded-sm">
                            Quantity
                        </button>
                    </Link>
                    <Link to="/admin/stock/price"className="min-w-32">
                        <button className="min-w-fit px-3 justify-center h-11 py-2 items-center text-center flex flex-row w-full space-x-2 btn-primary text-xl rounded-sm">
                            Prices
                        </button>
                    </Link>
                    <Link to="/admin/stock/location"className="min-w-32">
                        <button className="min-w-fit px-3 justify-center h-11 py-2 items-center text-center flex flex-row w-full space-x-2 btn-primary text-xl rounded-sm">
                            Locations
                        </button>
                    </Link>
                    <Link to="/admin/stock/tag"className="min-w-32">
                        <button className="min-w-fit px-3 justify-center h-11 py-2 items-center text-center flex flex-row w-full space-x-2 btn-primary text-xl rounded-sm">
                            Tags
                        </button>
                    </Link>
                    <Link to="/admin/stock/manufacturer"className="min-w-32">
                        <button className="min-w-fit px-3 justify-center h-11 py-2 items-center text-center flex flex-row w-full space-x-2 btn-primary text-xl rounded-sm">
                            Frame Manufacturers
                        </button>
                    </Link>
                    <Link to="/admin/stock/brand" className="min-w-32">
                        <button className="min-w-fit px-3 justify-center h-11 py-2 items-center text-center flex flex-row w-full space-x-2 btn-primary text-xl rounded-sm">
                            Contacts Brands
                        </button>
                    </Link>

                </div>
            </div>
        </div>
    )
}